/*eslint eqeqeq: "off"*/
import {
  ADD_SELL_PRIMARY_ORDER_DETAILS,
  ADD_SELL_ORDER_PRODUCT_LIST,
  GET_PRODUCTLIST_SALE_ORDERWISE,
  UPDATE_SELL_PRIMARY_ORDER_DETAILS,
  CLEAR_PRODUCT_IN_STOCK,
  FETCH_ORDERBOOK_DETAILS_LIST,
  EDIT_ORDERBOOK_DETAILS,
  DELETE_ORDERBOOK_DETAILS,
  PASS_ORDER_DETAILS_TO_PRODUCTLIST,
  IS_PRODUCT_IN_STOCK,
} from "./_orderBook_Type";

const initialState = {
  _commanEditedDetails: "",
  _allDetails: [],
  _id: "",
  _InStockProduct: [],
  _get_PltformOrder_Details: {},
  _productListOrderWise: [],
  storeDetails: "",
  firstFormId: "",
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SELL_PRIMARY_ORDER_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
        _get_PltformOrder_Details: {
          id: action.payload.id,
          platformOrderNo: action.payload.platformOrderNo,
          accountId: action.payload.platformAccountEmailId,
        },
        _commanEditedDetails: "",
        _productListOrderWise: [],
        storeDetails: action.storeDetails,
        firstFormId: action.payload,
      };
    case ADD_SELL_ORDER_PRODUCT_LIST:
      return {
        ...state,
        // _allDetails: [action.payload, ...state._allDetails],
      };
    case EDIT_ORDERBOOK_DETAILS:
      return {
        ...state,
        _commanEditedDetails: action.payload,
        _productListOrderWise: [],
        _id: action.id,
      };
    case UPDATE_SELL_PRIMARY_ORDER_DETAILS:
      return {
        ...state,
        _commanEditedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
        storeDetails: action.storeDetails,
        firstFormId: action.payload,
      };
    case FETCH_ORDERBOOK_DETAILS_LIST:
      return {
        ...state,
        _commanEditedDetails: "",
        _allDetails: action.listview,
        _InStockProduct: [],
        _get_PltformOrder_Details: {},
        _productListOrderWise: [],
        storeDetails: "",
        firstFormId: "",
      };
    case GET_PRODUCTLIST_SALE_ORDERWISE:
      return {
        ...state,
        _productListOrderWise: action.listview,
      };
    case IS_PRODUCT_IN_STOCK:
      return {
        ...state,
        _InStockProduct: action.listview,
      };
    case CLEAR_PRODUCT_IN_STOCK:
      return {
        ...state,
        _InStockProduct: [],
      };

    case PASS_ORDER_DETAILS_TO_PRODUCTLIST:
      return {
        ...state,
        _get_PltformOrder_Details: action.payload,
      };

    case DELETE_ORDERBOOK_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
        _id: action.payload,
      };

    // case CLEAR_SUPPLIER_EDITED_DATA: return {
    //     ...state,
    //     _commanEditedDetails: '',
    // }
    default:
      return state;
  }
};

export default OrderReducer;
