/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Grid,
  TextField,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPlatformAcc } from "../../redux";
import { config } from "../../Constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  createManufacturerFinanceDetails,
  updateFinanceManufacturerDetails,
} from "./_finance_Action";

const REACT_APP_PATH = config.url.PRODUCT_IMG_PATH;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
})); /*  */

const SellOrderManufacturerFinanceDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let _editData =
    useSelector((state) => state.orderFinance._editedDetails) || "";
  let _productListOrderWise =
    useSelector((state) => state.orderFinance._productListOrderWise) || [];
  let _financeDetailsProductWise =
    useSelector(
      (state) => state.orderFinance._manufacturerFinanceDetailsEdit
    ) || [];
  let _platfromAccountList =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];

  useEffect(() => {
    dispatch(fetchAllPlatformAcc());
    // eslint-disable-next-line
  }, []);

  let defultPlatform = (
    <option key="" value="000e0">
      Select Platform
    </option>
  );
  let platformAccountRender = [];
  if (_platfromAccountList) {
    platformAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName}
        </option>
      );
    });
  }

  let defultEmail = (
    <option key="" value="000e0">
      Select Id
    </option>
  );
  let platformEmailAccountRender = [];
  if (_platfromAccountList) {
    platformEmailAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.accountEmailId}
        </option>
      );
    });
  }

  var buttonText = "Save";
  if (_financeDetailsProductWise.length > 0) {
    buttonText = "Update";
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var singleProductOrder = {
    //USER TEMPLETE
    subOrderid: "",
    productImage: null,
    categoryname: "",
    designo: "",
    isNewDesign: false,
    quantity: 1,
    note: "",
    designerName: "",
    waxmakername: "",
    dyemakername: "",
    makername: "",
    manufacturerid: 0,
    designercost: 0,
    waxcost: 0,
    dyecost: 0,
    diamondcost: 0,
    grossweight: 0,
    netweight: 0,
    labor_cost: 0,
    matel_cost: 0,
    shipingAndPackingCost: 0,
    additionalCost: 0,
    totalcost: 0,
  };

  let productList = [singleProductOrder];
  if (_productListOrderWise.length > 0) {
    /*eslint array-callback-return: "off"*/
    _productListOrderWise.map((obj, i) => {
      productList[i] = {
        subOrderid: obj.id,
        productImage: obj.productImage,
        categoryname: obj.categoryName,
        designo: obj.productDesignNo,
        isNewDesign: false,
        quantity: obj.quntity,
        note: "",
        designerName: "",
        waxmakername: "",
        dyemakername: "",
        makername: obj.manufacturerName,
        manufacturerid: obj.makerId,
        makersku: obj.makerSku,
        designercost: 0,
        waxcost: 0,
        dyecost: 0,
        diamondcost: 0,
        grossweight: 0,
        netweight: 0,
        labor_cost: 0,
        matel_cost: 0,
        shipingAndPackingCost: 0,
        additionalCost: 0,
        totalcost: 0,
      };

      _financeDetailsProductWise.map((financeObj, jj) => {
        if (obj.id == financeObj.subOrderid) {
          productList[i].tbluid = financeObj.id;
          productList[i].designerName = financeObj.designerName;
          productList[i].waxmakername = financeObj.waxMaker;
          productList[i].dyemakername = financeObj.dyeMaker;
          productList[i].manufacturerid = obj.makerId;
          productList[i].designercost = financeObj.designerCost;
          productList[i].waxcost = financeObj.waxCost;
          productList[i].dyecost = financeObj.dyeCost;

          productList[i].diamondcost = financeObj.dimondCost;
          productList[i].grossweight = financeObj.grossWeight;
          productList[i].netweight = financeObj.netWeight;
          productList[i].labor_cost = financeObj.laborCost;
          productList[i].matel_cost = financeObj.metalCost;
          productList[i].shipingAndPackingCost = financeObj.shipingAndPackingCost;
          productList[i].additionalCost = financeObj.additionalCost;
          productList[i].totalcost = financeObj.totalCost;
          productList[i].note = financeObj.note;
        }
      });
    });
  }

  const [inputList, setInputList] = useState(productList || []); // [singleProductOrder]|| );

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value || "";
    setInputList(list);
  };
  const backBtnClick = () => {
    navigate("/app/order_finance", { replace: false });
  };
  const onCostChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    let totalsum =
      Number(list[index]["designercost"]) +
      Number(list[index]["waxcost"]) +
      Number(list[index]["dyecost"]) +
      Number(list[index]["diamondcost"]) +
      Number(list[index]["grossweight"]) +
      Number(list[index]["netweight"]) +
      Number(list[index]["labor_cost"]) +
      Number(list[index]["matel_cost"]) +
      Number(list[index]["shipingAndPackingCost"]) +
      Number(list[index]["additionalCost"]) -
      Number(list[index][name]);

    list[index]["totalcost"] = totalsum + Number(value);

    list[index][name] = value || "";
    setInputList(list);
  };

  let newProductBoxRender = "";
  newProductBoxRender = inputList.map((x, i) => {
    return (
      <div key={i}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Product Image :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="icon-button-photo" size="small">
              <Avatar
                className={classes.avatar}
                src={REACT_APP_PATH + x.productImage}
              />
            </InputLabel>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Category Name :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Category Name"
              name="categoryname"
              fullWidth
              value={x.categoryname || ""}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Design Details :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Design No"
              name="designo"
              fullWidth
              value={x.designo || ""}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Designer Name"
              name="designerName"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.designerName || ""}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Quantity :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Quantity"
              name="quantity"
              fullWidth
              readOnly
              variant="outlined"
              disabled
              value={x.quantity || 1}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Maker Details :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Maker Sku"
              name="makersku"
              fullWidth
              readOnly
              value={x.makersku || ""}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Maker Name"
              name="makername"
              fullWidth
              readOnly
              value={x.makername}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}></Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Wax Maker Name"
              name="waxmakername"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.waxmakername}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Dye Maker Name"
              name="dyemakername"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.dyemakername}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Weight Details :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Gross Weight"
              name="grossweight"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.grossweight}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Net Weight"
              name="netweight"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.netweight}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Cost Details :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Designer Cost"
              name="designercost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.designercost}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Wax Cost"
              name="waxcost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.waxcost}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}></Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Dye Cost"
              name="dyecost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.dyecost}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Diamond Cost"
              name="diamondcost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.diamondcost}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
          </Grid>
          <Grid item md={3} xs={12}>
          <TextField
              label="labor-cost"
              name="labor_cost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.labor_cost}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Matel-cost"
              name="matel_cost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.matel_cost}
              variant="outlined"
            />
          </Grid>
          
        </Grid>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>
        </Grid>
        <Grid item md={3} xs={12}>
            <TextField
              label="Shipping And Packing-cost"
              name="shipingAndPackingCost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.shipingAndPackingCost}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="additional-cost"
              name="additionalCost"
              fullWidth
              onChange={(e) => onCostChange(e, i)}
              value={x.additionalCost}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}></Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Total Cost"
              name="totalcost"
              fullWidth
              value={x.totalcost || 0}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Notes :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Notes"
              name="note"
              fullWidth
              multiline
              rows={5}
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
        </Grid>
        &ensp;&ensp;
        {inputList.length !== 1 && inputList.length - 1 !== i && <Divider />}
      </div>
    );
  });

  const validate = () => {
    let isError = false;
    // const errorList = { ...formErrors }

    // if (values.platformName == '' || values.platformName == 0) {
    //     isError = true;
    //     errorList["platformName"] = "Please select platform name"
    // }

    return isError;
  };

  return (
    <>
      <Container maxWidth="xl">
        <Formik
          initialValues=""
          validationSchema={Yup.object().shape({
            // qty: Yup.string().required('Quantity name is required!'),
            // price: Yup.string().required('price name is required!'),
          })}
          onSubmit={(values, { resetForm }) => {
            let err = validate();
            if (!err) {
              if (buttonText == "Update") {
                dispatch(
                  updateFinanceManufacturerDetails(
                    _editData.id,
                    inputList,
                    navigate
                  )
                );
              } else {
                dispatch(
                  createManufacturerFinanceDetails(
                    _editData.id,
                    inputList,
                    navigate
                  )
                );
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Manufacturer Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Order Date :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <KeyboardDatePicker
                            disableToolbar
                            disabled
                            name="orderDate"
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="Order date"
                            value={_editData.orderDate || new Date()}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Platform Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformAccountId"
                        fullWidth
                        label="Platform Name"
                        required
                        disabled
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={_editData.accountId}
                      >
                        {defultPlatform}
                        {platformAccountRender}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformAccountId"
                        fullWidth
                        label="Platform Name"
                        required
                        disabled
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={_editData.accountId}
                      >
                        {defultEmail}
                        {platformEmailAccountRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="platform Order No"
                        name="platformOrderNoDropDown"
                        fullWidth
                        value={_editData.platformOrderNo}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  {newProductBoxRender}
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backBtnClick}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.buttonMargin}
                      type="submit"
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default SellOrderManufacturerFinanceDetails;
