/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  TableHead,
  Table,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const OrderList = ({ className, TableData, filterFn, ...rest }) => {
  let totalProfit;
  const classes = useStyles();
  const {
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData,'', filterFn);

  totalProfit = recordsAfterPagingAndSorting().reduce((partialSum, ele) => partialSum + ele.profit,0);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
          <caption style={{textAlign:"right",color:'black'}}> totalProfit : {totalProfit} </caption>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                Order Date
                </TableCell>
                <TableCell align="center">
                Customer Name
                </TableCell>
                <TableCell align="center">
                Platform AccName
                </TableCell>
                <TableCell align="center">
                Platform orderNo
                </TableCell>
                <TableCell align="center">
                Platform AccEmail
                </TableCell>
                <TableCell align="center">
                Selling NetPrice
                </TableCell>
                <TableCell align="center">
                ManuCost
                </TableCell>
                <TableCell align="center">
                Net Profit
                </TableCell>
              </TableRow>
            </TableHead>
            {TableData.length > 0 ? (
              <TableBody>
                {            
                recordsAfterPagingAndSorting().map((row,i) => (
                  <TableRow 
                  hover key={i}
                  >
                    <TableCell align="center">{row.orderDate}</TableCell>
                    <TableCell align="center">{row.customerName}</TableCell>
                    <TableCell align="center">{row.platformAccName}</TableCell>
                    <TableCell align="center">{row.platformOrderNo}</TableCell>
                    <TableCell align="center">{row.platformAccEmail}</TableCell>
                    <TableCell align="center">{row.netPrice}</TableCell>
                    <TableCell align="center">{row.totalCost}</TableCell>
                    <TableCell align="center">{row.profit}</TableCell>
                  </TableRow>
                ))
                }

              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

OrderList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};
export default OrderList;
