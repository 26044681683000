import React, { useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import {
  createPlatformAccount,
  updateAccount,
  fetchPltformNameList,
} from "./_platformAccount_Action";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const PlatformAccountCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.platfromAccount._editedDetails);

  useEffect(() => {
    dispatch(fetchPltformNameList());
    // eslint-disable-next-line
  }, []);

  let platformList = useSelector(
    (state) => state.platfromAccount._allPlatfromNames
  );

  let defultOption = (
    <option key="" value="00">
      Select Platform
    </option>
  );
  let dropdownRender = [];
  if (platformList) {
    dropdownRender = platformList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName}
        </option>
      );
    });
  }

  var initialdata = {
    accountEmail: "",
    platformName: "",
    phone: "",
    otherDetails: "",
    accountGstNo: "",
    accountName: "",
    ownerName: "",
    location: "",
    bankname: "",
    ifsc: "",
    bankAccountno: "",
  };
  /*eslint no-redeclare: "off"*/
  var initialdata,
    buttonText = "Create",
    /* eslint no-unused-vars : "off" */
    dropdownlable = "Select Roll";
  if (_editData) {
    initialdata = {
      accountEmail: _editData.accountEmailId,
      platformName: "" + _editData.platformid,
      phone: _editData.accountMobileNo,
      accountGstNo: _editData.accountGstNo,
      otherDetails: _editData.otherDetails,
      accountName: _editData.accountName,
      ownerName: _editData.accountOwner,
      location: _editData.accountLocation,
      bankname: _editData.accountBankName,
      ifsc: _editData.accountIfscNo,
      bankAccountno: _editData.accountAccountNo,
    };
    buttonText = "Update";
    dropdownlable = "";
  } else {
    initialdata = {
      accountEmail: "",
      platformName: "",
      phone: "",
      otherDetails: "",
      accountGstNo: "",
      accountName: "",
      ownerName: "",
      location: "",
      bankname: "",
      ifsc: "",
      bankAccountno: "",
    };
    buttonText = "Create";
  }
  const AddPlatformNavigate = () => {
    navigate("../platformAccount/createname", { replace: false });
  };

  const backBtnClick = () => {
    navigate("/app/platformAccount", { replace: false });
  };
  return (
    <Page
      //className={classes.root}
      title="Platform Account"
    >
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            accountEmail: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            platformName: Yup.string().required("Platform is required!"),
            phone: Yup.number("Must be a valid phone no.").max(999999999999),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (buttonText === "Update" && _editData) {
              await dispatch(updateAccount(values, _editData.id, navigate));
            } else {
              await dispatch(createPlatformAccount(values, navigate));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Platform Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Platform Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.platformName && errors.platformName
                        )}
                        fullWidth
                        helperText={touched.platformName && errors.platformName}
                        label="Select Platform"
                        name="platformName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={values.platformName}
                      >
                        {defultOption}
                        {dropdownRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Account Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.accountEmail && errors.accountEmail
                        )}
                        fullWidth
                        helperText={touched.accountEmail && errors.accountEmail}
                        label="Account Email Id"
                        name="accountEmail"
                        onBlur={handleBlur}
                        type="email"
                        required
                        onChange={handleChange}
                        value={values.accountEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.accountName && errors.accountName
                        )}
                        fullWidth
                        helperText={touched.accountName && errors.accountName}
                        label="Account Name"
                        name="accountName"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.accountName}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(touched.ownerName && errors.ownerName)}
                        fullWidth
                        helperText={touched.ownerName && errors.ownerName}
                        label="Account owner Name"
                        name="ownerName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ownerName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(touched.location && errors.location)}
                        fullWidth
                        helperText={touched.location && errors.location}
                        label="Account location"
                        name="location"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.location}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        GST No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="GST Number"
                        name="accountGstNo"
                        onChange={handleChange}
                        value={values.accountGstNo}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label="Phone No"
                        name="phone"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bank Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Bank Name"
                        //helperText="Please specify the supplier Bank Details"
                        name="bankname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bankname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        name="ifsc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ifsc}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Account No"
                        name="bankAccountno"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bankAccountno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Other Details :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        helperText="Please specify the account other details"
                        label="Other Details"
                        name="otherDetails"
                        onChange={handleChange}
                        value={values.otherDetails}
                        variant="outlined"
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent display="flex" p={2}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={backBtnClick}
                        className="marginRight"
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={AddPlatformNavigate}
                      >
                        Add Platform Name
                      </Button>
                    </Grid>
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {buttonText}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

PlatformAccountCreate.propTypes = {
  className: PropTypes.string,
};

export default PlatformAccountCreate;
