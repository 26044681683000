/*eslint eqeqeq: "off"*/
import * as ACTION from "./_issue_Returns_Type";
import {
  createNewDetailsPost,
  fetchDataMethod,
} from "../../utils/actionMethod";

export const issueMakerDispatcher = (data, navigate, reloadData, tab) => {
  if (tab == undefined) {
    tab = "INISSUE";
  }
  console.log("-tab-",tab);
  return async (dispatch) => {
    if (reloadData) {
      let result = await createNewDetailsPost("/api/issue-to-maker", data);
      dispatch({
        type: ACTION.ISSUE_MAKER,
        payload: result.data,
      });
      let result_orders = await fetchDataMethod(
        "/api/get-pendding-issue-orders?status=" + tab
      );
      dispatch({
        type: ACTION.GET_PENDING_ORDERS,
        payload: result_orders.data,
      });
    } else {
      let result = await fetchDataMethod(
        "/api/get-pendding-issue-orders?status=" + tab
      );
      dispatch({
        type: ACTION.GET_PENDING_ORDERS,
        payload: result.data,
      });
    }
  };
};
export const departCourier = (data) => {
  return async (dispatch) => {
    let result = createNewDetailsPost("/api/manufa-to-courier-depart", data);
    dispatch({
      type: ACTION.DEPART_COURIER,
    });
  };
};
