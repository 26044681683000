import React, { useEffect, useState } from "react";
import {
  Container,
  makeStyles,
  Box,
  AppBar,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import OrderBookList from "./FinanceOrderBookList";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllcustomerUnfilledfinanceOrderlist,
  getAllManufacturerUnfilledfinanceOrderlist,
} from "./_finance_Action";
import { fetchAllOrderDetails } from "../orderBook/_orderBook_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
}));

////////////////////////////////// NEW ////////////////////////////

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OrderFinance() {
  const classes = useStyles();

  let id = Number(new URLSearchParams(window.location.search).get("id")) || 0;

  const [value, setValue] = React.useState(id || 0);

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [showAllData, setShowAllData] = useState(false);

  const dispatch = useDispatch();
  let _allUnfilledCustomerData =
    useSelector((state) => state.orderFinance._allDetails) || [];
  let _allUnfilledManufacturerData =
    useSelector((state) => state.orderFinance._allUnfilledManucaturer) || [];
  let _allDetails = useSelector((state) => state.orderBook._allDetails) || [];

  useEffect(() => {
    dispatch(getAllcustomerUnfilledfinanceOrderlist());
    dispatch(getAllManufacturerUnfilledfinanceOrderlist());
    dispatch(fetchAllOrderDetails());
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let viewCustomer, viewManufacturer;

  if (showAllData) {
    viewCustomer = (
      <OrderBookList
        TableData={_allDetails}
        ismanufacturer={0}
        filterFn={filterFn}
      />
    );
    viewManufacturer = (
      <OrderBookList
        TableData={_allDetails}
        ismanufacturer={1}
        filterFn={filterFn}
      />
    );
  } else {
    viewCustomer = (
      <OrderBookList
        TableData={_allUnfilledCustomerData}
        ismanufacturer={0}
        filterFn={filterFn}
      />
    );
    viewManufacturer = (
      <OrderBookList
        TableData={_allUnfilledManufacturerData}
        ismanufacturer={1}
        filterFn={filterFn}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Page className={classes.root} title="Finance Details">
        <Container maxWidth={false}>
          <Toolbar setParentFilterFn={setParentFilterFn} />

          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Finance Details" {...a11yProps(0)} />
              <Tab label="Manufacturer Details" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <FormControlLabel
            control={
              <Checkbox
                checked={showAllData}
                onChange={() => setShowAllData(showAllData ? false : true)}
                name="alldata"
                color="primary"
              />
            }
            label="Show all data"
          />
          <TabPanel value={value} index={0}>
            <Box mt={3}>{viewCustomer}</Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box mt={3}>{viewManufacturer}</Box>
          </TabPanel>
        </Container>
      </Page>
    </div>
  );
}
