
import {
  FETCH_CUSTOMER_DETAILS_LIST,
} from "./_customer_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");
export const fetchAllDetails = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-cust-pending-tracker-num",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data;
          dispatch(getAllDetails(result));
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
        toast.error(err.response.data.message);
      });
  };
};

export const getAllDetails = (data) => {
  return {
    type: FETCH_CUSTOMER_DETAILS_LIST,
    listview: data,
  };
};