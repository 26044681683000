import { TOTALPROFIT,PENDINGISSUSTONEORDERS,RETURNCUSTFINANCERATIO,TOTALTURNOVER } from './_dashboard_type'
import { config } from "../../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

// total profit
export const fetchAllDetails = (dateRange) => {

    return function (dispatch) {
        var OPTIONS = {
            url: dateRange ? REACT_APP_URL + `/api/get-total-profit?startDate=${dateRange[0]}&endDate=${dateRange[1]}` : REACT_APP_URL + `/api/get-total-profit`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        axios(OPTIONS)
            .then((res) => {
                if (res.status == 200) {
                    let result = res.data.data;
                    dispatch(getAllDetails(result));
                } else {
                    toast("Soming Wrong.....!");
                }
            })
            .catch((err) => {
                dispatch(getAllDetails([]));
                toast.error(err.response.data.message);
            });
    };
};
export const getAllDetails = (data) => {
    return {
        type: TOTALPROFIT,
        listview: data,
    };
};

// sub orders which have pending issue stone

export const pendingIssueStoneOrders = () => {

    return function (dispatch) {
        var OPTIONS = {
            url: REACT_APP_URL + `/api/get-stone-issue-orders?status=INSTONEISSUEMAKER`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        axios(OPTIONS)
            .then((res) => {
                if (res.status == 200) {
                    let result = res.data.data;
                    dispatch(getPendingIssueStoneDetails(result));
                } else {
                    toast("Soming Wrong.....!");
                }
            })
            .catch((err) => {
                dispatch(getPendingIssueStoneDetails([]));
                toast.error(err.response.data.message);
            });
    };

}

export const getPendingIssueStoneDetails = (data) => {
    return {
        type: PENDINGISSUSTONEORDERS,
        listview: data,
    };
};

//total return ratio

export const ReturnRatioOfCustFinance = () => {

    return function (dispatch) {
        var OPTIONS = {
            url: REACT_APP_URL + `/api/get-return-ratio-of-cust-finance`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        axios(OPTIONS)
            .then((res) => {
                if (res.status == 200) {
                    let result = res.data.data;
                    dispatch(getReturnRatio(result));
                } else {
                    toast("Soming Wrong.....!");
                }
            })
            .catch((err) => {
                dispatch(getReturnRatio([]));
                toast.error(err.response.data.message);
            });
    };

}

export const getReturnRatio = (data) => {
    return {
        type: RETURNCUSTFINANCERATIO,
        listview: data,
    };
};

//total turn over

export const getTotalTurnOver = () => {

    return function (dispatch) {
        var OPTIONS = {
            url: REACT_APP_URL + `/api/get-total-turn-over`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        axios(OPTIONS)
            .then((res) => {
                if (res.status == 200) {
                    let result = res.data.data;
                    dispatch(getTurnOver(result));
                } else {
                    toast("Soming Wrong.....!");
                }
            })
            .catch((err) => {
                dispatch(getTurnOver([]));
                toast.error(err.response.data.message);
            });
    };

}

export const getTurnOver = (data) => {
    return {
        type: TOTALTURNOVER,
        listview: data,
    };
};

