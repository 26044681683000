import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
// import AdapterDateFns from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_firm_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const FirmCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.firm._editedDetails);
  const [selectedDate, setSelectedDate] = React.useState(
    _editData.firmStartDate || new Date()
  ); //new Date('2014-08-18T21:11:54'));
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  var initialdata = {
    firmname: "",
    ownername: "",
    registerdate: "",
    address: "",
    mobile: "",
    gstno: "",
    bankname: "",
    ifsc: "",
    accountno: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      firmname: _editData.firmName,
      ownername: _editData.firmOwnerName,
      registerdate: _editData.firmStartDate,
      address: _editData.firmAddress,
      mobile: _editData.firmMobileNo,
      gstno: _editData.firmGstno,
      bankname: _editData.firmBankName,
      ifsc: _editData.ifscNo,
      accountno: _editData.accountNo,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      firmname: "",
      ownername: "",
      registerdate: "",
      address: "",
      mobile: "",
      gstno: "",
      bankname: "",
      ifsc: "",
      accountno: "",
    };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/firm", { replace: false });
  };
  return (
    <Page
      //className={classes.root}
      title="Firm"
    >
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            firmname: Yup.string().required("Name is required!"),
            mobile: Yup.number()
              .integer("Must be a valid phone number")
              .max(999999999999, "Too long!!"),
          })}
          onSubmit={async (values, { resetForm }) => {
            values.registerdate = Date.parse(selectedDate);
            if (buttonText === "Update" && _editData) {
              await dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              await dispatch(createNewDetails(values, navigate));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Firm Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Firm Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.firmname && errors.firmname)}
                        fullWidth
                        helperText={touched.firmname && errors.firmname}
                        label="Firm Name"
                        name="firmname"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.firmname}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Owner Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ownername && errors.ownername)}
                        fullWidth
                        helperText={touched.ownername && errors.ownername}
                        label="Owner Name"
                        name="ownername"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ownername}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Firm date :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date picker inline"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.mobile && errors.mobile)}
                        fullWidth
                        helperText={touched.mobile && errors.mobile}
                        label="Phone No"
                        name="mobile"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mobile}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        GST No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="GST NO"
                        name="gstno"
                        multiline
                        onChange={handleChange}
                        value={values.gstno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Address :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="address"
                        multiline
                        onChange={handleChange}
                        value={values.address}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bank Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Bank Name"
                        name="bankname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bankname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        name="ifsc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ifsc}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Account No"
                        name="accountno"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

FirmCreate.propTypes = {
  className: PropTypes.string,
};

export default FirmCreate;
