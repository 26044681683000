/*eslint eqeqeq: "off"*/
import {
FETCH_ORDER_DETAILS_LIST,
FETCH_ID_WISE_RETURN_ORDER
  } from "./_order_Type";
  const initialState = {
    _msg: "",
    _allDetails: [],
    _returnDetails: [],
  };
  
  const orderReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ORDER_DETAILS_LIST:
        return {
          ...state,
          _msg: action.message,
          _allDetails: action.listview,
        };
      case FETCH_ID_WISE_RETURN_ORDER:
        return {
          ...state,
          _msg: action.message,
          _returnDetails: action.listview,
        };
      default:
        return state;
    }
  };
  
  export default orderReducer;