import React, { useEffect, useState } from "react";
import { Container, makeStyles, Box, Grid } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import StoneShapeList from "./StoneShapeList";
import StoneTypeList from "./StoneTypeList";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllStoneShape } from "./_stoneShape_Action";
import { fetchAllStoneType } from "./_stoneType_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

const StoneShape = () => {
  const classes = useStyles();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [filterTypeFn, setParentFilterTypeFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const dispatch = useDispatch();
  let allStoneShape =
    useSelector((state) => state.stoneShape._allDetails) || [];

  let allStoneTypes = useSelector((state) => state.stoneType._allDetails) || [];

  useEffect(() => {
    dispatch(fetchAllStoneShape());
    dispatch(fetchAllStoneType());
    // eslint-disable-next-line
  }, []);

  let viewStoneShape = (
    <StoneShapeList TableData={allStoneShape} filterFn={filterFn} />
  );

  let viewStoneType = (
    <StoneTypeList TableData={allStoneTypes} filterFn={filterTypeFn} />
  );

  return (
    <Page className={classes.root} title="Stone Details">
      <Container maxWidth="xl">
        <Toolbar
          setParentFilterFn={setParentFilterFn}
          setParentFilterTypeFn={setParentFilterTypeFn}
        />
        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <Box mt={3}>{viewStoneShape}</Box>
          </Grid>

          {/* <Toolbar setParentFilterFn={setParentFilterFn} /> */}
          <Grid item md={6} xs={6}>
            <Box mt={3}>{viewStoneType}</Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default StoneShape;
