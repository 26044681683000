/* eslint no-unused-vars : "off" */
import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import IssueRerurnsList from "./issueReturnsList";
import InManufacturing from "./inManufacturing";
import InDepart from "./inDepart";
import InCourier from "./inReadyToCourier";
import InCompleted from "./inCompleted";
import { useSelector, useDispatch } from "react-redux";
import { issueMakerDispatcher } from "./_issue_Returns_Action";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function IssueRerurns() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allFetchData, setAllFetchData] = React.useState("");
  const [value, setValue] = React.useState(0);
  let viewComponent;
  let viewComponentManufactur;
  let viewComponentCourier;
  let viewComponentDepart;
  let viewComponentCompleted;
  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    dispatch(issueMakerDispatcher([], navigate, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let _allProductList = useSelector((state) => state.issueReturns._allDetails);
  viewComponent = (
    <IssueRerurnsList TableData={_allProductList} filterFn={filterFn} />
  );
  viewComponentManufactur = (
    <InManufacturing TableData={_allProductList} filterFn={filterFn} />
  );
  viewComponentCourier = (
    <InCourier TableData={_allProductList} filterFn={filterFn} />
  );
  viewComponentDepart = (
    <InDepart TableData={_allProductList} filterFn={filterFn} />
  );
  viewComponentCompleted = (
    <InCompleted TableData={_allProductList} filterFn={filterFn} />
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const getTabName = (tab) => {
  //   dispatch(issueMakerDispatcher([], navigate, false, tab));
  // };
  return (
    <Page className={classes.root} title="Issue Returns Details">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="order tabs">
            <Tab
              label="In Issue"
              {...a11yProps(0)}
              onClick={() => dispatch(issueMakerDispatcher([], navigate, false, "INISSUE")) }
            />
            <Tab
              label="In Manufacturing"
              {...a11yProps(1)}
              onClick={() => dispatch(issueMakerDispatcher([], navigate, false, "INMANUFACTURING"))}
            />
            <Tab
              label="Ready to Ship"
              {...a11yProps(2)}
              onClick={() => dispatch(issueMakerDispatcher([], navigate, false, "READYTOCOURIER"))}
            />
            <Tab
              label="Shipped"
              {...a11yProps(3)}
              onClick={() => dispatch(issueMakerDispatcher([], navigate, false, "DEPART"))}
            />
            <Tab
              label="Completed"
              {...a11yProps(4)}
              onClick={() => dispatch(issueMakerDispatcher([], navigate, false, "COMPLETED"))}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {_allProductList.listData  ? <Box mt={3}>{viewComponent}</Box> : ""}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {_allProductList.listData ? (
            <Box mt={3}>{viewComponentManufactur}</Box>
          ) : (
            ""
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {_allProductList !== "" ? (
            <Box mt={3}>{viewComponentCourier}</Box>
          ) : (
            ""
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {_allProductList !== "" ? (
            <Box mt={3}>{viewComponentDepart}</Box>
          ) : (
           ""
          )}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {_allProductList !== "" ? (
            <Box mt={3}>{viewComponentCompleted}</Box>
          ) : (
            ""
          )}
        </TabPanel>
      </Container>
    </Page>
  );
}
