/*eslint eqeqeq: "off"*/
import {
  GET_PENDING_ORDERS,
  DEPART_COURIER,
} from "../../views/issuesReturns/_issue_Returns_Type";
const initialState = {
  _editedDetails: "",
  _id: "",
  _allDetails: "",
};

const issueReturnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_ORDERS:
      return {
        _allDetails: action.payload,
      };
    case DEPART_COURIER:
      return {
        _allDetails: action.payload,
      };
    default:
      return state;
  }
};

export default issueReturnsReducer;
