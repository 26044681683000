/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";

const Toolbar = ({ className, setParentFilterFn, ...rest }) => {

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else if(items[0].productList){
          let data=[]
          for(let x of items)
          {
             for(let y of x.productList)
             {
               if(y.makerSku.toLowerCase().includes(target.value.toLowerCase()))
               data.push(x)
             }
          }
           if(data.length>=1)
           return data
           else return []
        }else 
        return items.filter((x) =>
            x.makerSku.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
    setParentFilterFn(filterFn);
  };

  return (
    <div {...rest}>
      <Box>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search by SKU"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
