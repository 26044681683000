/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  makeStyles,
  Card,
  Typography,
  Divider,
  CardContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
} from "@material-ui/core";
import Page from "src/components/Page";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";
import {
  createNewIncomeExpenseDetails,
  updateDetails,
} from "./_inc_Exp_Action";
import { fetchAllFirmsDetails } from "../firm/_firm_Action";

const categories = [
  {
    value: "",
    label: "Select Category",
  },
  {
    value: "assets",
    label: "Assets",
  },
  {
    value: "food",
    label: "Food",
  },
  {
    value: "office",
    label: "Office",
  },
  {
    value: "jewelry",
    label: "Jewellary Supplies",
  },
  {
    value: "packing ",
    label: "Packing Material",
  },
  {
    value: "stationary",
    label: "Stationary",
  },
  {
    value: "rent",
    label: "Rent",
  },
  {
    value: "petrol",
    label: "Petrol",
  },
  {
    value: "salary",
    label: "Salary",
  },
  {
    value: "lightbill ",
    label: "Light Bill",
  },
  {
    value: "internet",
    label: "Internet",
  },
  {
    value: "repair",
    label: "Rapair",
  },
];
const transactionMethod = [
  {
    value: "",
    label: "Select Transaction Method",
  },
  {
    value: "cash",
    label: "Cash",
  },
  {
    value: "bank",
    label: "Bank",
  },
  {
    value: "mobile app",
    label: "Mobile App",
  },
  {
    value: "other",
    label: "Other",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    // borderBottom:"1px solid rgb(224 224 224)",
    color: "rgb(84 110 122)",
    marginTop: "20px",
    marginBottom: "20px",
  },
  gridRadio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  // cardHeader: {
  //   textAlign: "center",
  //   marginTop: "20px",
  //   marginBottom: "20px",
  //   color: "rgb(84 110 122)",
  // }
}));

const IncomeExpenseCreate = ({ className, ...rest }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.incomeExpense._editedDetails);
  let allFetchData = useSelector((state) => state.firm._allDetails) || [];
  const [showRadio, setShowRadio] = useState(_editData ? false : true);
  const [selectedDate, setSelectedDate] = React.useState(
    _editData ? _editData.transactionDate.split("T")[0] || new Date() : ""
  );
  const handleDateChange = (date) => {
    setSelectedDate(date.target.value);
  };

  var initialdata = {
    transaction_type: "",
    date: "",
    category: "",
    transaction_done_by: "",
    transaction_ref: "",
    amount: "",
    note: "",
    firmid: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      transaction_type: _editData.transactionType.toString(),
      date: _editData.transactionDate,
      category: _editData.transactionCategory,
      transaction_done_by: _editData.transactionDoneBy,
      transaction_ref: _editData.tarnsactionReference,
      amount: _editData.transactionAmount,
      note: _editData.incomeExpenceNode,
      firmid: _editData.firmid,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      transaction_type: "",
      date: "",
      category: "",
      transaction_done_by: "",
      transaction_ref: "",
      amount: "",
      note: "",
      firmid: "",
    };
    buttonText = "Create";
  }
  const changeRadioStatus = () => {
    setShowRadio(false);
  };

  useEffect(() => {
    if (allFetchData.length == 0) {
      dispatch(fetchAllFirmsDetails());
    }
    // eslint-disable-next-line
  }, []);
  const backBtnClick = () => {
    navigate("/app/inc_exp", { replace: false });
  };
  const backClick = () => {
    navigate("/app/inc_exp", { replace: false });
  };
  const validate = () => {
    let isError = false;

    var data = parseInt(cateID);
    if (data <= 0 || isNaN(data)) {
      isError = true;
      setCateIDError("Please select Firm Value");
    }
    return isError;
  };
  const [cateID, setCateID] = useState(_editData.firmid);
  const [cateIDError, setCateIDError] = useState("");
  const onCategoryChange = (e) => {
    setCateID(e.target.value);
  };

  let defultOption = (
    <option key="" value="000e0">
      Select Firm value
    </option>
  );
  let dropdownRender = [];

  if (allFetchData) {
    dropdownRender = allFetchData.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.firmName}
        </option>
      );
    });
  }
  return (
    <Page title="Income & Expense Details">
      <ToastNotify />
      <Container maxWidth="md">
        <Formik
          initialValues={initialdata}
          onSubmit={(values, { resetForm }) => {
            const err = validate();
            if (!err) {
              values.firmid = cateID;
              values.transaction_type = parseInt(values.transaction_type);
              values.date = Date.parse(selectedDate);
              if (buttonText === "Update" && _editData) {
                dispatch(updateDetails(values, _editData.id, navigate));
              } else {
                dispatch(createNewIncomeExpenseDetails(values, navigate));
              }
              setCateID("000e0");
              resetForm({ values: "" });
            }
          }}
          onReset={() => {
            setShowRadio(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={classes.root}
              onReset={handleReset}
            >
              {showRadio || values.transaction_type === "" ? (
                <Card>
                  <Typography variant="h4" className={classes.cardHeader}>
                    Income & Expense
                  </Typography>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Is Income or Expense? :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <RadioGroup
                          row
                          label="Is Income"
                          aria-label="Income"
                          name="transaction_type"
                          value={values.transaction_type}
                          onChange={handleChange}
                          required
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Income"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Expense"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backBtnClick}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={changeRadioStatus}
                      disabled={values.transaction_type === ""}
                    >
                      Next
                    </Button>
                  </Box>
                </Card>
              ) : (
                <Card>
                  <Typography variant="h4" className={classes.cardHeader}>
                    {values.transaction_type == 1
                      ? "Income Details"
                      : "Expense Details"}
                  </Typography>
                  <Divider />
                  <CardContent>
                    <Grid item container spacing={3} alignItems="center">
                      {_editData ? (
                        <>
                          <Grid item xs={6} sm={3}>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Is Income or Expense? :
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <RadioGroup
                              row
                              label="Is Income"
                              aria-label="Income"
                              name="transaction_type"
                              value={values.transaction_type}
                              onChange={handleChange}
                              required
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Income"
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Expense"
                              />
                            </RadioGroup>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Transaction date :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          id="date"
                          label="Transaction Date"
                          type="date"
                          name="date"
                          value={selectedDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleDateChange}
                          required
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Category :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-select-currency-native"
                          fullWidth
                          select
                          label={values.category !== "" ? "Category" : ""}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.category}
                          name="category"
                          SelectProps={{
                            native: true,
                          }}
                          required
                        >
                          {categories.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Firm :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(cateIDError)}
                          helperText={cateIDError}
                          id="outlined-select-currency-native"
                          fullWidth
                          select
                          label="Firm"
                          onChange={(e) => {
                            onCategoryChange(e);
                            setCateIDError("");
                          }}
                          onBlur={handleBlur}
                          variant="outlined"
                          value={cateID}
                          name="firmid"
                          SelectProps={{
                            native: true,
                          }}
                          required
                        >
                          {/* {allFetchData.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.firmOwnerName}
                            </option>
                          ))} */}
                          {defultOption}
                          {dropdownRender}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Transaction Details :
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          id="outlined-select-currency-native"
                          fullWidth
                          select
                          label={
                            values.transaction_done_by !== ""
                              ? "Transaction Done by"
                              : ""
                          }
                          onChange={handleChange}
                          variant="outlined"
                          value={values.transaction_done_by}
                          SelectProps={{
                            native: true,
                          }}
                          name="transaction_done_by"
                          required
                        >
                          {transactionMethod.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                          label="Amount"
                          name="amount"
                          fullWidth
                          value={values.amount}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}></Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          id="outlined-select-currency-native"
                          fullWidth
                          name="transaction_ref"
                          label="Transaction Referenace"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.transaction_ref}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Notes :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          name="note"
                          id="outlined-multiline-static"
                          label="Note"
                          multiline
                          rows={5}
                          defaultValue={values.note}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    {_editData ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={backClick}
                      >
                        Back
                      </Button>
                    ) : (
                      <Button color="primary" variant="contained" type="reset">
                        Back
                      </Button>
                    )}
                    <Button color="primary" variant="contained" type="submit">
                      {buttonText}
                    </Button>
                  </Box>
                </Card>
              )}
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

IncomeExpenseCreate.propTypes = {
  className: PropTypes.string,
};

export default IncomeExpenseCreate;
