import React,{useEffect} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Icon } from '@iconify/react';
import { pendingIssueStoneOrders } from "./_dashboard_action";
import { useSelector, useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.green[900],
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
}));

const PendingIssueStoneOrder = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let allFetchData = useSelector((state) => state.dashboard._pendingStonIssueOrders) || [];

  useEffect(() => {
    dispatch(pendingIssueStoneOrders());
  },[])
  
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              PENDING ISSUE STONE ORDERS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {allFetchData.penddingIssueStoneOrders}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
            <Icon icon="ic:outline-pending-actions" style={{fontSize:'30px'}} rotate={2} hFlip={true} vFlip={true} />
            </Avatar>
          </Grid>
        </Grid>
       <Box mt={2} display="flex" alignItems="center">
        {/*    <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            16%
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Since last month
          </Typography> */}
         we have total {allFetchData.penddingIssueStoneOrders} pending stone orders
        </Box>
      </CardContent>
    </Card>
  );
};

PendingIssueStoneOrder.propTypes = {
  className: PropTypes.string,
};

export default PendingIssueStoneOrder;
