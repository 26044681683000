import {
  ADD_SELL_PRIMARY_ORDER_DETAILS,
  GET_PRODUCTLIST_SALE_ORDERWISE,
  ADD_SELL_ORDER_PRODUCT_LIST,
  UPDATE_SELL_PRIMARY_ORDER_DETAILS,
  CLEAR_PRODUCT_IN_STOCK,
  FETCH_ORDERBOOK_DETAILS_LIST,
  EDIT_ORDERBOOK_DETAILS,
  DELETE_ORDERBOOK_DETAILS,
  PASS_ORDER_DETAILS_TO_PRODUCTLIST,
  IS_PRODUCT_IN_STOCK,
} from "./_orderBook_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const insertSellOrderPrimaryDetails = (values, navigate) => {
  values.customerDetails = {
    custName: values.custName || "",
    custEmail: values.custEmail || "",
    custPhone: values.custPhone || "",
    custAddress: values.custAddress || "",
  };
  values.deliveryDay = values.deliveryDay || 0;

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-sale-order-primary-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_SELL_PRIMARY_ORDER_DETAILS,
            payload: result,
            storeDetails: values,
          });
          navigate("../order_book/add_sell_order", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const createSellProductListDetails = (
  platformAccountId,
  orderNo,
  productDetailsList,
  attributeValues,
  navigate
) => {
  let formData = {
    platformAccountId: platformAccountId,
    platformOrderNo: orderNo,
    productOrderList: productDetailsList,
    attributeValues: attributeValues,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-sale-order-productlist",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_SELL_ORDER_PRODUCT_LIST,
            payload: result,
          });
          navigate("../order_book", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const updateSellProductListDetails = (
  platformAccountId,
  orderNo,
  productDetailsList,
  attributeValues,
  navigate
) => {
  let formData = {
    platformAccountId: platformAccountId,
    platformOrderNo: orderNo,
    productOrderList: productDetailsList,
    attributeValues: attributeValues,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-order-productlist",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: ADD_SELL_ORDER_PRODUCT_LIST,
            payload: result,
          });
          navigate("../order_book", { replace: false });
        } else {
          toast("Somthing Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const fetchAllOrderDetails = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-sale-order-book-list",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_ORDERBOOK_DETAILS_LIST,
            listview: result,
          });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ORDERBOOK_DETAILS_LIST,
          listview: [],
        });
        toast.error(err.response.data.message);
      });
  };
};

export const getProductListOrderwiseeeeee = (id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL + "/api/get-productlist-sale-orderwise?saleOrderId=" + id,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: GET_PRODUCTLIST_SALE_ORDERWISE,
            listview: result,
          });
          navigate("./order_book", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ORDERBOOK_DETAILS_LIST,
          listview: [],
        });
        toast.error(err.response.data.message);
      });
  };
};

export const getProductListOrderwise = (id, navigate, navigationPath) => {
  let OPTIONS = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return async (dispatch) => {
    function onSuccess(res) {
      if (res.data.status === 200) {
        let result = res.data.data;
        dispatch({ type: GET_PRODUCTLIST_SALE_ORDERWISE, listview: result });
        if (navigate) navigate(navigationPath, { replace: false });
      } else {
        dispatch({ type: GET_PRODUCTLIST_SALE_ORDERWISE, listview: [] });
        return [];
      }
    }
    function onError(error) {
      dispatch({ type: GET_PRODUCTLIST_SALE_ORDERWISE, listview: [] });
      return [];
    }
    try {
      const success = await axios.get(
        REACT_APP_URL + "/api/get-productlist-sale-orderwise?saleOrderId=" + id,
        OPTIONS
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const checkProductInStock = (productDetails, attributeValue) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/check-product-instoack?productDetails=" +
        JSON.stringify(productDetails) +
        "&attributeValue=" +
        JSON.stringify(attributeValue),
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: IS_PRODUCT_IN_STOCK,
            listview: result,
          });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: IS_PRODUCT_IN_STOCK,
          listview: [],
        });
        toast.error(err.response.data.message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_ORDERBOOK_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const passOrderDetails = (id, data) => {
  return {
    type: PASS_ORDER_DETAILS_TO_PRODUCTLIST,
    payload: data,
    id: id,
  };
};

export const clearProductInStock = () => {
  return {
    type: CLEAR_PRODUCT_IN_STOCK,
  };
};

export const updateSellOrderPrimaryDetails = (values, navigate) => {
  values.customerDetails = {
    custName: values.custName || "",
    custEmail: values.custEmail || "",
    custPhone: values.custPhone || "",
    custAddress: values.custAddress || "",
  };
  values.deliveryDay = values.deliveryDay || 0;

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-order-primary-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: UPDATE_SELL_PRIMARY_ORDER_DETAILS,
            payload: "",
          });
          navigate("../order_book", { replace: false });
        } else {
          toast("somthing Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const updateSellOrderPrimaryDetailsBack = (values, navigate) => {
  values.customerDetails = {
    custName: values.custName || "",
    custEmail: values.custEmail || "",
    custPhone: values.custPhone || "",
    custAddress: values.custAddress || "",
  };
  values.deliveryDay = values.deliveryDay || 0;

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-order-primary-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_SELL_PRIMARY_ORDER_DETAILS,
            payload: result[0],
            storeDetails: values,
          });
          navigate("../order_book/add_sell_order", { replace: false });
        } else {
          toast("somthing Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/cancel-saleorder-details",
      method: "POST",
      data: { orderid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let message = res.data.message;
          dispatch({
            type: DELETE_ORDERBOOK_DETAILS,
            payload: id,
            msg: message,
          });
        } else {
          toast("somthing Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
