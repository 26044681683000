import * as ACTION from "./_inc_Exp_Type";
import {
  fetchDataMethod,
  createNewDetailsPost,
  updateDetailsPost,
  deleteSingleRecord,
} from "../../utils/actionMethod";

export const getAllincomeExpenseDatalist = () => {
  return async (dispatch) => {
    let result = await fetchDataMethod("/api/get-income-expense-details");
    dispatch({
      type: ACTION.GET_ALL_INCOME_EXPENSE_LIST,
      payload: result.data,
    });
  };
};

export const createNewIncomeExpenseDetails = (add_values, navigate) => {
  let formData = {
    amount: add_values.amount,
    category: add_values.category,
    date: add_values.date,
    transaction_type: add_values.transaction_type,
    note: add_values.note,
    transaction_done_by: add_values.transaction_done_by,
    transaction_ref: add_values.transaction_ref,
    firmid: add_values.firmid,
  };

  return async (dispatch) => {
    let result = await createNewDetailsPost(
      "/api/add-income-expense-details",
      formData
    );
    dispatch({
      type: ACTION.ADD_INCOME_EXPENSE_DETAILS,
      payload: result.data,
    });
    if (result.status) {
      navigate("../inc_exp", { replace: false });
    }
  };
};

export const updateDetails = (updateData, editid, navigate) => {
  let formData = {
    pid: editid,
    amount: updateData.amount,
    category: updateData.category,
    date: updateData.date,
    transaction_type: updateData.transaction_type,
    note: updateData.note,
    transaction_done_by: updateData.transaction_done_by,
    transaction_ref: updateData.transaction_ref,
    firmid: updateData.firmid,
    isdelete: false,
  };

  return async (dispatch) => {
    let result = await updateDetailsPost(
      "/api/update-delete-income-expense-details",
      formData
    );
    dispatch({
      type: ACTION.UPDATE_INCOME_EXPENSE_DETAILS,
      payload: result.data,
      id: editid,
    });
    if (result.status) {
      navigate("../inc_exp", { replace: false });
    }
  };
};

export const deleteSingleDetails = (id) => {
  let formData = {
    pid: id,
    isdelete: true,
  };
  return async (dispatch) => {
    let result = await deleteSingleRecord(
      "/api/update-delete-income-expense-details",
      formData
    );
    dispatch({
      type: ACTION.DELETE_INCOME_EXPENSE_DETAILS,
      payload: result.data[0],
    });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: ACTION.EDIT_INCOME_EXPENSE_DETAILS,
    payload: singleData,
    id: id,
  };
};
