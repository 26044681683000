/*eslint eqeqeq: "off"*/
import {
  ADD_FIRM_DETAILS,
  FETCH_FIRM_DETAILS_LIST,
  EDIT_FIRM_DETAILS,
  DELETE_FIRM_DETAILS,
} from "./_firm_Type";

const initialState = {
  _editedDetails: "",
  _allDetails: [],
  _id: "",
};

const firmReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FIRM_DETAILS:
      return {
        ...state,
        _id: action.id,
      };
    case EDIT_FIRM_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case FETCH_FIRM_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
      };
    case DELETE_FIRM_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
        _id: action.payload,
      };

    default:
      return state;
  }
};

export default firmReducer;
