import {
  FETCH_FIRM_DETAILS_LIST,
  EDIT_FIRM_DETAILS,
  DELETE_FIRM_DETAILS,
} from "./_firm_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const createNewDetails = (values, navigate) => {
  values.mobile = parseInt(values.mobile) || 0;
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-firm-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          navigate("../firm", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        //alert(message)
        toast.error(message);
      });
  };
};

export const fetchAllFirmsDetails = (supplierid) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-firms",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_FIRM_DETAILS_LIST,
            listview: result,
          });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FIRM_DETAILS_LIST,
          listview: [],
        });
        toast.error(err.response.data.message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_FIRM_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (values, r_id, navigate) => {
  values.mobile = parseInt(values.mobile) || 0;
  values.firmid = r_id;
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-firm-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("../firm", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-firm-details",
      method: "POST",
      data: { firmid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let message = res.data.message;

          dispatch({
            type: DELETE_FIRM_DETAILS,
            payload: id,
            msg: message,
          });
          //toast.error(message);
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
