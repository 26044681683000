import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Box,
    Card,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
    TableHead,
    Table,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2),
    },
}));

const IssueStoneDetails = ({ className, TableData, filterFn, ...rest }) => {

    const classes = useStyles();
    const {
        TblPagination,
        recordsAfterPagingAndSorting,
    } = commanTable(TableData, '', filterFn);

    return <>
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Stone Issued Date
                                </TableCell>
                                <TableCell align="center">
                                    Stone Type
                                </TableCell>
                                <TableCell align="center">
                                    Stone Shape
                                </TableCell>
                                <TableCell align="center">
                                    Stone Size
                                </TableCell>
                                <TableCell align="center">
                                    Required Stone
                                </TableCell>
                                <TableCell align="center">
                                    Product Id
                                </TableCell>
                                <TableCell align="center">
                                    Maker Sku
                                </TableCell>
                                <TableCell align="center">
                                    Manufacturer Email
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {TableData.length > 0 ? (
                            <TableBody>
                                {
                                    recordsAfterPagingAndSorting().map((row) => (
                                        <TableRow
                                            hover key={row.id}
                                        >
                                            <TableCell align="center">{moment(row.stone_issue_date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                            <TableCell align="center">{row.dimondType}</TableCell>
                                            <TableCell align="center">{row.dimondShape}</TableCell>
                                            <TableCell align="center">{row.dimondSize}</TableCell>
                                            <TableCell align="center">{row.require_stones}</TableCell>
                                            <TableCell align="center">{row.product_id}</TableCell>
                                            <TableCell align="center">{row.makerSku}</TableCell>
                                            <TableCell align="center">{row.makerEmail}</TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        ) : (
                            <caption>No Record Found...</caption>
                        )}
                    </Table>
                </Box>
            </PerfectScrollbar>
            {TableData.length != undefined && TableData.length > 0 ? (
                <TblPagination />
            ) : (
                ""
            )}
        </Card>
    </>
};

IssueStoneDetails.propTypes = {
    className: PropTypes.string,
};
export default IssueStoneDetails;