/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";

const Toolbar = ({ className, setParentFilterFn, ...rest }) => {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        console.log("==", target.value);
        if (target.value == "") return items;
        else
          return items.filter((x) => {
            console.log("----", x);
            return x.productDefinationId
              // .toLowerCase()
              .includes(target.value.toLowerCase())
          });
      },
    });
    setParentFilterFn(filterFn);
  };

  return (
    <div {...rest}>
      <Box>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search by Product name"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
