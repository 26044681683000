import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_courierCompany_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const CouierCompanyCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.couierCompany._editedDetails);

  var initialdata = {
    cname: "",
    cphone: "",
    caddress: "",
    cemail: "",
    trackinglink: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      cname: _editData.courierCompanyName,
      cphone: _editData.courierPhoneNo,
      caddress: _editData.officeAddress,
      cemail: _editData.registerEmail,
      trackingLink: _editData.courierTrackingLink,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      cname: "",
      cphone: "",
      caddress: "",
      cemail: "",
      trackinglink: "",
    };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/courier_company", { replace: false });
  };
  return (
    <Page
      //className={classes.root}
      title="Courier Company"
    >
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            cname: Yup.string().required("Name is required!"),
            cphone: Yup.number("Must be a valid phone no.").max(999999999999),
            registeremail: Yup.string().email("Invalid email"),
          })}
          onSubmit={(values, { resetForm }) => {
            values.cphone = parseInt(values.cphone) || 0;

            if (buttonText === "Update" && _editData) {
              dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              dispatch(createNewDetails(values, navigate));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Courier Company Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Courier Company Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.cname && errors.cname)}
                        fullWidth
                        helperText={touched.cname && errors.cname}
                        label="Courier Company Name"
                        name="cname"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.cname}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Email ID :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.cemail && errors.cemail)}
                        fullWidth
                        helperText={touched.cemail && errors.cemail}
                        label="Register Email"
                        name="cemail"
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cemail}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.cphone && errors.cphone)}
                        fullWidth
                        helperText={touched.cphone && errors.cphone}
                        label="Phone No"
                        name="cphone"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cphone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Office Address :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Office Address"
                        name="caddress"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        value={values.caddress}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Tracking Link :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Tracking Link"
                        name="trackingLink"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        value={values.trackingLink}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

CouierCompanyCreate.propTypes = {
  className: PropTypes.string,
};

export default CouierCompanyCreate;
