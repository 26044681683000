/*eslint eqeqeq: "off"*/
import {
  FETCH_CUSTOMER_DETAILS_LIST,
  ADD_CUSTOMER_DETAILS,
  EDIT_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
} from "./_customer_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const createNewDetails = (values, navigate, isPopup) => {
  let formData = {
    customername: values.cname,
    mobile: parseInt(values.cphone) || 0,
    address: values.caddress,
    email: values.cemail,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-customer-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status == 201) {
          let result = res.data.data;

          dispatch({
            type: ADD_CUSTOMER_DETAILS,
            payload: result,
          });

          if (!isPopup) navigate("../customer", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        //alert(message)
        toast.error(message);
      });
  };
};

export const fetchAllDetails = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-customer",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data;
          dispatch(getAllDetails(result));
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
        toast.error(err.response.data.message);
      });
  };
};

export const getAllDetails = (data) => {
  return {
    type: FETCH_CUSTOMER_DETAILS_LIST,
    listview: data,
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_CUSTOMER_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-customer-details",
      method: "POST",
      data: {
        customerid: r_id,
        customername: updateData.cname,
        address: updateData.caddress,
        mobile: updateData.cphone,
        email: updateData.cemail,
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status == 200) {
          navigate("../customer", { replace: false });
          //toast(message);
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-customer-details",
      method: "POST",
      data: { customerid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status == 200) {
          let message = res.data.message;
          // dispatch(fetchAllDetails())
          dispatch({
            type: DELETE_CUSTOMER_DETAILS,
            payload: id,
            msg: message,
          });
          //toast.error(message);
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
