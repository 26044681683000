/* eslint no-unused-vars : "off" */
/*eslint eqeqeq: "off"*/
import React, { useEffect,useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  Divider,
  Button,
  Dialog,
  Grid,
  TextField,
} from "@material-ui/core";
/*eslint no-useless-rename: "off"*/
import { ChevronDown, ChevronUp } from "react-feather";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createNewDetailsPost } from "../../utils/actionMethod";
import { fetchAllDetails } from "../courierCompany/_courierCompany_Action";
import { Formik } from "formik";
import * as Yup from "yup";
import { issueMakerDispatcher } from "./_issue_Returns_Action";

const useStyles = makeStyles((theme) => ({
  root: {},
  issueButton: {
    margin: "auto",
    display: "flex",
    marginTop: "20px",
  },
}));

const headCells = [
  { id: "expand", label: "" },
  { id: "orderDate", label: "Order Date" },
  { id: "daysFromOrder", label: "daysFromOrder" },
  { id: "makerSku", label: "Maker SKU" },
  { id: "orderId", label: "Order ID" },
  { id: "designNo", label: "Design No." },
  { id: "manufacturerName", label: "Manufacturer Name" },
  { id: "edit", label: "Depart Courier", disableSorting: true },
  { id: "delete", label: "Ready To Courier", disableSorting: true },
  { id: "returnToRepair", label: "Return To Repair" },
];
function DetailsArrayloop(props) {
  const { row } = props;
  return (
    <>
      <Box marginBottom="15px">
        <Typography component="h5" variant="h5" className="typography_margin">
          Requirements
        </Typography>
        {row.map((item, i) => {
          return item.dimondShape === undefined ? (
            "No Diamond Require"
          ) : (
            <div key={i}>
              <h5>{item.dimondType}</h5>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={500}
              >
                <span>Available:- {item.availableOnStockQty}</span>
                <span>Require:- {item.requireQty}</span>
                <span>Shape:- {item.dimondShape}</span>
                <span>Size:- {item.dimondSize}</span>
              </Box>
              <Divider className="divider_margin" />
            </div>
          );
        })}
      </Box>
    </>
  );
}
function DetailsArray(props) {
  const { row } = props;
  return (
    <Box marginBottom="15px">
      <Typography component="h5" variant="h5" className="typography_margin">
        No Diamond Require
      </Typography>
      {/* {row.map((item, i) => {
        return item.dimondShape === undefined ? (
          "No Diamond Require"
        ) : (
          <div key={i}>
            <h5>{item.dimondType}</h5>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              maxWidth={500}
            >
              <span>Available:- {item.availableOnStockQty}</span>
              <span>Require:- {item.requireQty}</span>
              <span>Shape:- {item.dimondShape}</span>
              <span>Size:- {item.dimondSize}</span>
            </Box>
            <Divider className="divider_margin" />
          </div>
        );
      })} */}
    </Box>
  );
}

function Row(props) {
  const { row, rowProductStockInfo } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRepairDialog, setOpenRepairDialog] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  let detailsArray = "";
  const array = [];
  /*eslint array-callback-return: "off"*/
  rowProductStockInfo.map((stock) => {
    if (stock.length >= 1) {
      stock.map((e) => {
        if (row.product_uid == e.sub_product_uid) {
          detailsArray = e;
          array.push(e);
        }
      });
    } else {
      if (row.product_uid == stock[0].sub_product_uid) {
        detailsArray = stock[0];
      }
    }
  });
  const readyForDepart = async (row, array) => {

    //  let flag = true;

    //  array.forEach((element) => {
    //       if (element.availableOnStockQty < element.requireQty) {
    //         setErrorMsg("Enough stock is require");
    //         flag = false
    //         setTimeout(() => {
    //           setErrorMsg("")
    //         }, 5000);
    //         return;
    //       }
    //     });

    let flag = true
    if (row.singleOrderStatus !== "INMANUFACTURING") {
      flag = false
      setErrorMsg("please, issue the stones for your product");
      setTimeout(() => {
        setErrorMsg("")
      }, 5000);
    }

    if (flag == true) {

      var data = {
        subProductUid: row.product_uid,
        order_uid: row.order_uid,
        orderStatus: "READYTOCOURIER",
      };
      let result = await createNewDetailsPost(
        "/api/manufa-to-courier-depart",
        data
      );
      dispatch(issueMakerDispatcher([], navigate, false, "INMANUFACTURING"));
      if (!result.status) {
        dispatch(issueMakerDispatcher([], navigate, false, "INMANUFACTURING"));
      }
    }
  };
  const openReturnToRepaire = () => {
    setOpenRepairDialog(true)
  }

  const handleRepairClose = () => {
    setOpenRepairDialog(false)
  };
  const openDepartCourier = (row) => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const orderDate = new Date(moment(row.orderDate))   // days from order
  const curDate = new Date()
  const diff = Math.abs(curDate - orderDate)
  const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))
  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell padding="checkbox">
          <div onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{moment(row.orderDate).format("DD-MM-YYYY")}</TableCell>
        <TableCell>{diffDays} days</TableCell>
        <TableCell>{row.makerSku}</TableCell>
        <TableCell>{row.platformOrderNo}</TableCell>
        <TableCell>
          {row.isNewDesign == 1 ? "New Design" : row.definition_id}
        </TableCell>
        <TableCell>{row.manufacturerName}</TableCell>
        <TableCell width="15%">
          <Button
            color="primary"
            variant="contained"
            onClick={() => openDepartCourier(row)}
          >
            depart Ship   {/* here is button */}
          </Button>
        </TableCell>
        <SimpleDialog
          key={1}
          open={openDialog}
          onClose={handleClose}
          row={row}
          array={array}
        />
        <TableCell width="15%">
          <Button
            color="primary"
            variant="contained"
            onClick={() => readyForDepart(row, array)}
          >
            REady for Ship
          </Button>
          <p style={{ color: "red" }}>{errorMsg}</p>
        </TableCell>
        <TableCell width="15%">
          <Button
            color={row.singleOrderStatus == "INMANUFACTURING" ? "primary" : "default"}
            variant="contained"
            onClick={() => openReturnToRepaire(row)}
            style={{ minWidth : '165px' }}
          >
           {row.repairNote !== "" ? "In Repairing" : "Return To Repair"}
          </Button>
        </TableCell>
        <ReturnToRepairing
          key={2}
          open={openRepairDialog}
          onClose={handleRepairClose}
          row={row}
        />
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                component="h5"
                variant="h5"
                className="typography_margin"
              >
                Details
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                maxWidth={500}
                justifyContent="space-between"
              >
                <p>
                  <span>
                    Design Name :{" "}
                    <span>
                      {row.isNewDesign == 1 ? "New Design" : row.prod_Name}
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    Product Qty : <span>{row.productQty}</span>
                  </span>
                </p>
              </Box>
            </Box>
            {array.length >= 1 ? (
              <DetailsArrayloop row={array} />
            ) : (
              <DetailsArray row={array} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function SimpleDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = React.useState("");
  const { onClose, open, row, array } = props;

  let allFetchData =
    useSelector((state) => state.couierCompany._allDetails) || [];

  useEffect(() => {
    dispatch(fetchAllDetails());
  }, []);
  var initialdata = {
    courierName: "",
    trackingNo: "",
  };

  let defultOption = (
    <option key="0" value="0">
      Select Courier
    </option>
  );
  let dropdownRender = [];
  if (allFetchData) {
    dropdownRender = allFetchData.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.courierCompanyName}
        </option>
      );
    });
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Formik
        initialValues={initialdata}
        validationSchema={Yup.object().shape({
          courierName: Yup.string().required("Name is required!"),
          trackingNo: Yup.number()
            .required("Tracking Number is required!")
            .integer("Must be a valid phone number"),
        })}
        onSubmit={async (values, { resetForm }) => {
          ///////////////////
          // array.forEach((element) => {
          //   if (element.availableOnStockQty < element.requireQty) {
          //     setErrorMsg("Enough stock is require");
          //     flag = false
          //     return;
          //   }
          // });

          let flag = true
          if (row.singleOrderStatus !== "INMANUFACTURING") {
            flag = false
            setErrorMsg("please, issue the stones for your product");
            setTimeout(() => {
              setErrorMsg("")
            }, 5000);
          }
          //////////////////

          if (flag == true) {
            let data = {
              subProductUid: row.product_uid,
              order_uid: row.order_uid,
              courierId: values.courierName,
              courierTrackingNo: values.trackingNo,
              orderStatus: "DEPART",
            };
            let result = await createNewDetailsPost(
              "/api/manufa-to-courier-depart",
              data
            );
            dispatch(issueMakerDispatcher([], navigate, false, "INMANUFACTURING"));
            if (result.data.msg) {
              setErrorMsg(result.data.msg);
              setTimeout(() => {
                setErrorMsg("")
              }, 5000);
            }
            if (!result.status) {
              dispatch(
                issueMakerDispatcher([], navigate, false, "INMANUFACTURING")
              );
            }else if(result.data.status){
              handleClose()
            }
          }
          resetForm({ values: "" });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ margin: 20 }} key={1}>
              <Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Courier Name :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      name="courierName"
                      fullWidth
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.courierName && errors.courierName)}
                      helperText={touched.courierName && errors.courierName}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      value={values.courierName}
                    >
                      {defultOption}
                      {dropdownRender}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Tracking No. :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.trackingNo && errors.trackingNo)}
                      fullWidth
                      helperText={touched.trackingNo && errors.trackingNo}
                      label="Tracking No"
                      name="trackingNo"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.trackingNo}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.issueButton}
                >
                  Depart Ship
                </Button>
                <p style={{ color: "red" }}>{errorMsg}</p>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

////////////////////////////////

function ReturnToRepairing(props) {

  const { onClose, open, row } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [note,setNote] = useState(row.repairNote ? row.repairNote : '')

  var initialdata = {
    textNote: "",
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm">
      <Formik
        initialValues={initialdata}
        validationSchema={Yup.object().shape({
          textNote: Yup.string()
            .required("Text Note is required!"),
        })}
        onSubmit={async (values, { resetForm }) => {

          setNote(values.textNote)

          let data = {
            subProductUid: row.product_uid,
            order_uid: row.order_uid,
            repairNote: values.textNote,
            RepairDate: moment().format("YYYY-MM-DD h:mm:ss"),
            orderStatus: "ReturnToRepairing",
          };
          const result = await createNewDetailsPost(
            "/api/return-to-repair",
            data
          );
          dispatch(issueMakerDispatcher([], navigate, false, "INMANUFACTURING"))
          if (!result.status) {
            dispatch(
              issueMakerDispatcher([], navigate, false, "INMANUFACTURING")
            );
          }
          handleClose();
          resetForm({ values: "" });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ margin: 20 }} key={1}>
              <Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Date Of Returning To Repair
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      name="returnDate"
                      fullWidth
                      variant="outlined"
                      value={moment().format("DD-MM-YYYY")}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Text Note:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.textNote && errors.textNote)}
                      multiline
                      minRows={4}
                      maxRows={8}
                      helperText={touched.textNote && errors.textNote}
                      label="Text Note"
                      name="textNote"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={row.repairNote !== "" ? note : values.textNote}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.issueButton}
                >
                  {row.repairNote !== "" ? "Update" : 'Return To Repair'}
                </Button>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );

}

const InManufacturing = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    selectedAllIds,
  } = commanTable(TableData.listData, headCells, filterFn);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />

            {Object.keys(TableData).length !== 0 &&
              TableData.listData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <Row
                    key={row.product_uid}
                    row={row}
                    rowProductStockInfo={TableData.productStockInfo}
                  />
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      {TableData.length != undefined && TableData.listData.length > 0 ? (
        <TblPagination />
      ) : (
        ""
      )}
    </Card>
  );
};

InManufacturing.propTypes = {
  className: PropTypes.string,
};

export default InManufacturing;
