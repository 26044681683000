export const ADD_PRODUCT_DETAILS = "ADD_PRODUCT_DETAILS";
export const FETCH_PRODUCT_DETAILS_LIST = "FETCH_PRODUCT_DETAILS_LIST";
export const EDIT_PRODUCT_DETAILS = "EDIT_PRODUCT_DETAILS";
export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS";
export const DELETE_PRODUCT_DETAILS = "DELETE_PRODUCT_DETAILS";
export const FETCH_SINGLE_PRODUCT_DETAILS = "FETCH_SINGLE_PRODUCT_DETAILS";
export const GET_PRODUCT_ATTRIBUTE_VALUES = "GET_PRODUCT_ATTRIBUTE_VALUES";
export const GET_SAVE_PRODUCT_VALUES = "GET_SAVE_PRODUCT_VALUES";
export const CLEAR_PRODUCT_EDIT_DETAILS = "CLEAR_PRODUCT_EDIT_DETAILS";
export const GET_PRODUCT_SAVE_STONE_DETAILS = "GET_PRODUCT_SAVE_STONE_DETAILS";
export const RESET_PRODUCT_STONE_DETAILS = "RESET_PRODUCT_STONE_DETAILS";
