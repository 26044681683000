import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

class PermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      list: [
        { id: 1, isChecked: false, name: "Product Defination" },
        { id: 2, isChecked: false, name: "Single Stock Entry" },
        { id: 3, isChecked: false, name: "Purchase Invoice" },
        { id: 4, isChecked: false, name: "Sells" },
        { id: 5, isChecked: false, name: "Platform Account" },
        { id: 6, isChecked: false, name: "Category & Attributes" },
        { id: 7, isChecked: false, name: "Manufacturer" },
        { id: 8, isChecked: false, name: "Customer" },
        { id: 9, isChecked: false, name: "Supplier" },
        { id: 10, isChecked: false, name: "Courier Company" },
        { id: 11, isChecked: false, name: "firm" },
        { id: 12, isChecked: false, name: "Stone Details" },
        { id: 13, isChecked: false, name: "Income & Expense" },
        { id: 14, isChecked: false, name: "Order Status" },
      ],
    };
    this.saveModule = this.saveModule.bind(this);
  }
  handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { list, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every((item) => item.isChecked);
      }
      return { list, allChecked };
    });
  };
  saveModule() {
    // dispatch(deleteUserDetails(deleteRecord));
  }
  renderList = () => {
    return this.state.list.map((item) => (
      <div key={item.id} className="checkbox_selection">
        <input
          key={item.id}
          type="checkbox"
          name={item.name}
          value={item.name}
          checked={item.isChecked}
          onChange={this.handleChange}
        />
        <label>{item.name}</label>
      </div>
    ));
  };
  render() {
    return (
      <Dialog fullWidth open={this.props.open} {...this.props.other}>
        <DialogTitle>Allow to access </DialogTitle>
        <DialogContent dividers>
          <div>
            <input
              className="select_input"
              type="checkbox"
              name="checkAll"
              checked={this.state.allChecked}
              onChange={this.handleChange}
            />
            <h3
              style={{
                display: "inline",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              Select all
            </h3>
            <br />
            <div className="childModule">{this.renderList()}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={this.saveModule}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default PermissionModal;
