import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_category_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const CategoryCreate = ({ isPopup, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.category._editedDetails);

  var initialdata = { categoryName: "", isrowmaterial: false },
    buttonText = "Create";
  if (_editData) {
    initialdata = {
      categoryName: _editData.categoryName,
      isrowmaterial: _editData.isRowMaterial,
    };
    buttonText = "Update";
  } else {
    initialdata = { categoryName: "", isrowmaterial: false };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/categorys", { replace: false });
  };

  return (
    <Page title="Product Category">
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            categoryName: Yup.string().required("Category name is required!"),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (buttonText === "Update" && _editData) {
              dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              dispatch(createNewDetails(values, navigate, isPopup));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Product category Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Category Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.categoryName && errors.categoryName
                        )}
                        fullWidth
                        helperText={touched.categoryName && errors.categoryName}
                        label="Product category Name"
                        name="categoryName"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        // onChange={e => setCategory(e.target.value)}
                        value={values.categoryName}
                        // defaultValue={categoryName}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Is it row material? :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isrowmaterial}
                            onChange={handleChange}
                            name="isrowmaterial"
                            color="primary"
                          />
                        }
                        label="Is it row material?"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

CategoryCreate.propTypes = {
  className: PropTypes.string,
};

export default CategoryCreate;
