import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_supplier_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const SupplierCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.supplier._editedDetails);

  var initialdata = {
    sname: "",
    sphone: "",
    saddress: "",
    semail: "",
    sgstno: "",
    sbankname: "",
    sifsc: "",
    saccountno: "",
    sprifix: "",
    sbillstratfrom: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      sname: _editData.supplierName,
      sphone: _editData.supplierMobileNo,
      saddress: _editData.supplierAddress,
      semail: _editData.supplierEmail,
      sbankname: _editData.supplierBankName,
      sifsc: _editData.supplierIfscNo,
      saccountno: _editData.supplierAccountNo,
      sgstno: _editData.supplierGstno,
      sprifix: _editData.billNumberPrifix,
      sbillstratfrom: _editData.billNumberStartFrom,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      sname: "",
      sphone: "",
      saddress: "",
      semail: "",
      sgstno: "",
      sbankname: "",
      sifsc: "",
      saccountno: "",
      sprifix: "",
      sbillstratfrom: "",
    };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/supplier", { replace: false });
  };
  return (
    <Page
      //className={classes.root}
      title="Supplier"
    >
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            sname: Yup.string().required("Name is required!"),
            sphone: Yup.number()
              .integer("Must be a valid phone number")
              .max(999999999999, "Too long!!"),
            sbillstratfrom: Yup.number()
              .integer("Enter only number")
              .max(999999999999, "Too long!!"),
            semail: Yup.string().email("Invalid email"),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (buttonText === "Update" && _editData) {
              await dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              await dispatch(createNewDetails(values, navigate));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Supplier Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Supplier Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.sname && errors.sname)}
                        fullWidth
                        helperText={touched.sname && errors.sname}
                        label="Supplier Name"
                        name="sname"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.sname}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Email ID :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.semail && errors.semail)}
                        fullWidth
                        helperText={touched.semail && errors.semail}
                        label="Email"
                        name="semail"
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.semail}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.sphone && errors.sphone)}
                        fullWidth
                        helperText={touched.sphone && errors.sphone}
                        label="Phone No"
                        name="sphone"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sphone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        GST No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="GST NO"
                        name="sgstno"
                        multiline
                        onChange={handleChange}
                        value={values.sgstno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Address :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="saddress"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        value={values.saddress}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bank Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Bank Name"
                        //helperText="Please specify the supplier Bank Details"
                        name="sbankname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sbankname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        name="sifsc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sifsc}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Account No"
                        name="saccountno"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.saccountno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bill Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Bill Prefix Character"
                        name="sprifix"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sprifix}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.sbillstratfrom && errors.sbillstratfrom
                        )}
                        fullWidth
                        helperText={
                          touched.sbillstratfrom && errors.sbillstratfrom
                        }
                        type="number"
                        label="Bill Number Strat From"
                        name="sbillstratfrom"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sbillstratfrom}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

SupplierCreate.propTypes = {
  className: PropTypes.string,
};

export default SupplierCreate;
