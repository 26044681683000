/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Avatar,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Input,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  fetchAllProducts,
  fetchAllAttributeValues,
  resetProductStoneDetails,
} from "../product/_product_Action"; /// For Dynamic Attr Value
import { fetchAllStoneType } from "../stoneDetails/_stoneType_Action";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllManufacturers } from "../manufacturer/_manufacturer_Action";
import {
  fetchAllOrderDetails,
  checkProductInStock,
  updateSellProductListDetails,
} from "./_orderBook_Action";
import { fetchAllPlatformAcc } from "../../redux";
import { fetchAllCategoryAttribute } from "../link_CategoryAttribute/_link_Cat_Att_Action";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Popup from "src/components/Popup";
import ProductCreate from "../product/ProductCreate";
import StockInList from "./StockInListPopup";
import { fetchAllCategory } from "../category/_category_Action";
import { config } from "../../Constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

let REACT_APP_PATH = config.url.PRODUCT_IMG_PATH;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
    paddingBottom: theme.spacing(7),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
})); /*  */

const SellOrderFinanceDetails = ({
  className,
  prevStep,
  nextStep,
  isCommanOrderSave,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let _editData = []; //= useSelector(state => state.productStock._editedDetails) || '';
  let _allProductList = useSelector((state) => state.product._allDetails) || [];
  let _allManufacturerData =
    useSelector((state) => state.manufacturer._allDetails) || [];
  let _allOrderList = useSelector((state) => state.orderBook._allDetails) || [];
  let _pltformOrder_Details =
    useSelector((state) => state.orderBook._get_PltformOrder_Details) || {};
  let _productListOrderWise =
    useSelector((state) => state.orderBook._productListOrderWise) || [];

  let _InStockProduct =
    useSelector((state) => state.orderBook._InStockProduct) || [];
  let _platfromAccountList =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];
  let _category = useSelector((state) => state.category._allDetails) || [];
  let _allStoneTypes =
    useSelector((state) => state.stoneType._allDetails) || [];
  let _productStoneDetails = useSelector(
    (state) => state.product._editProductStoneDetails
  ) || { _mainStoneList: [], _sideStoneList: [] };
  let _linkCat_Att =
    useSelector((state) => state.linkCat_Att._allDetails) || [];
  let _attributeValues =
    useSelector((state) => state.product._attributeValues) || [];
  let _savePurchaseAttributeValues =
    useSelector((state) => state.purchase._savePurchaseEntryValues) || [];

  const [productOpenPopup, setProductOpenPopup] = useState(false);
  const [stockInOpenPopup, setStockInOpenPopup] = useState(false);

  let plformOrderNo = { id: "", platformOrderNo: "" };
  if (Object.keys(_pltformOrder_Details).length > 0) {
    plformOrderNo = {
      id: _pltformOrder_Details.id,
      platformOrderNo: _pltformOrder_Details.platformOrderNo,
    };
  }

  const [commanValue, setCommanValue] = useState({
    platformAccountId: _pltformOrder_Details.accountId,
    platformOrderNoDropDown: plformOrderNo,
  });
  const [renderIndex, setRenderIndex] = useState(-1);
  /* eslint no-unused-vars : "off" */
  const [previewImage, setPreviewImage] = useState(
    REACT_APP_PATH + _editData.productImage || null
  );

  const fillAttributeValue = (stockId, qty, weight) => {
    handleInputChangeDiffComponent("stockId", stockId, renderIndex);
    if (qty <= 0 || weight <= 0) {
      handleInputChangeDiffComponent("sourceOrder", 1, renderIndex);
    } else {
      handleInputChangeDiffComponent("sourceOrder", 2, renderIndex);
    }
    handleInputChangeDiffComponent("isProductSelected", true, renderIndex);
  };

  useEffect(() => {
    let singleJson = [];
    /*eslint array-callback-return: "off"*/
    _savePurchaseAttributeValues.map((saveObj, count) => {
      let sss = saveObj[0];
      singleJson = { ...singleJson, ...sss };
    });

    let saveAttList = [...textboxValue];
    saveAttList[renderIndex] = singleJson;

    settextboxValue(saveAttList);
    // eslint-disable-next-line
  }, [_savePurchaseAttributeValues]);

  useEffect(() => {
    dispatch(fetchAllCategoryAttribute());

    if (_category.length <= 0) dispatch(fetchAllCategory());

    if (_allManufacturerData.length <= 0) dispatch(fetchAllManufacturers());
    if (_allProductList.length <= 0) dispatch(fetchAllProducts());

    if (_allOrderList.length <= 0) dispatch(fetchAllOrderDetails());

    // if (_linkCat_Att.length > 0)
    //     setCategoryAttriList(_linkCat_Att)
    if (_allStoneTypes.length <= 0) dispatch(fetchAllStoneType());

    //////
    dispatch(fetchAllAttributeValues());
    dispatch(fetchAllPlatformAcc());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (renderIndex >= 0)
      onDesignNameChangeGetStoneDetails(_productStoneDetails, renderIndex);
    // eslint-disable-next-line
  }, [_productStoneDetails]);
  /* eslint no-unused-vars : "off" */
  let supplierJson = {},
    /* eslint no-unused-vars : "off" */
    productdetails = {};
  if (_editData.supplier) {
    supplierJson = JSON.parse(_editData.supplier);
  }
  if (_editData.productdetails) {
    productdetails = JSON.parse(_editData.productdetails);
  }

  let defultPlatformEmail = (
    <option key="" value="000e0">
      Select Platform and Id
    </option>
  );
  let platformEmailAccountRender = [];
  if (_platfromAccountList) {
    platformEmailAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName + " ==>> " + option.accountEmailId}
        </option>
      );
    });
  }

  var buttonText = "Save";
  if (_productListOrderWise.length > 0) {
    buttonText = "Update";
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var singleProductOrder = {
    //USER TEMPLETE
    categoryId: "",
    isNewDesign: false,
    productName: { id: "", productDesignNo: "" },
    quantity: 1,
    sourceOrder: "",
    manufacturerName: { id: "", manufacturerName: "" },
    handlingDay: "",
    note: "",
    productCategoryAttribute: [],
    isRowMaterial: false,
    isProductSelected: false,
    stockId: 0,
  };

  let productList = [singleProductOrder],
    setStoneDetailsOrderWise = [{ stoneDetails: [] }],
    editAttributeList = [{}],
    selectedSaveAttrValue = [],
    attributeCompoArray = [];
  if (_productListOrderWise.length > 0) {
    _productListOrderWise.map((obj, i) => {
      productList[i] = {
        id: obj.id,
        categoryId: obj.categoryId,
        isNewDesign: Boolean(obj.isNewDesign),
        productName: {
          id: obj.product_id,
          productDesignNo: obj.productDesignNo,
        },
        quantity: obj.quntity,
        sourceOrder: obj.orderSourceFrom,
        manufacturerName: {
          id: obj.makerId,
          manufacturerName: obj.manufacturerName,
        },
        handlingDay: obj.handlingTime,
        note: obj.note,
        isRowMaterial: "",
        isProductSelected: "",
        stockId: obj.stockId,
      };

      let singleRecord = _linkCat_Att.filter(
        (linkObj) => linkObj.categoryId == obj.categoryId
      ) || [{ categoryAttId: [{}] }];

      let _mainStoneList =
        _productStoneDetails._mainStoneList.filter(
          (stonObj) => stonObj.productDefiId == obj.product_id
        ) || "";
      let _sideStoneList =
        _productStoneDetails._sideStoneList.filter(
          (stonObj) => stonObj.productDefiId == obj.product_id
        ) || "";

      let newJson = {
        stoneDetails: {
          _mainStoneList: _mainStoneList,
          _sideStoneList: _sideStoneList,
        },
      };
      setStoneDetailsOrderWise[i] = newJson;

      if (singleRecord.length > 0)
        attributeCompoArray = JSON.parse(singleRecord[0].categoryAttId) || [];

      editAttributeList[i] = { catgeoryAttributeCompo: attributeCompoArray };
      let singleJson = [];
      _savePurchaseAttributeValues.map((saveObj, count) => {
        if (obj.stockId == saveObj[1].sid) {
          let sss = saveObj[0];
          singleJson = { ...singleJson, ...sss };
        }
      });
      selectedSaveAttrValue[i] = singleJson;
    });
  }

  const [inputList, setInputList] = useState(productList || []); // [singleProductOrder]|| );
  const [textboxValue, settextboxValue] = useState(selectedSaveAttrValue || []);
  const [stoneDetailsArray, setStoneDetailsArray] = useState(
    setStoneDetailsOrderWise || [{ stoneDetails: [] }]
  );
  /* eslint no-unused-vars : "off" */
  const [attributeValueList, setAttributeValueList] = useState(
    editAttributeList || []
  );

  const handleInputChangeDiffComponent = (name, value, index) => {
    const list = [...inputList];
    if (name == "isNewDesign") {
      list[index][name] = value;
      list[index]["productName"] = { id: "", productDesignNo: "" };
      if (_productStoneDetails) dispatch(resetProductStoneDetails());
    } else list[index][name] = value || "";
    setInputList(list);
  };

  const commanInputValueChange = (name, value) => {
    const list = { ...commanValue };
    list[name] = value || "";
    setCommanValue(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    if (list[index]["isRowMaterial"]) {
      list[index]["productName"] = { id: "", productDesignNo: "" };
      if (_productStoneDetails) dispatch(resetProductStoneDetails());
    }

    list[index][name] = value || "";
    setInputList(list);
    // handleChangeDiffCompo("arrayProductOrder", list)
  };

  const onDesignNameChangeGetStoneDetails = (_stoneArray, i) => {
    let newArray = [];
    newArray = { stoneDetails: _stoneArray }; // _stoneArray._mainStoneList
    const list = [...stoneDetailsArray];
    list.splice(i, 1, newArray);
    setStoneDetailsArray(list);
  };

  const dynamicAttributeValueChange = (value, index, id) => {
    const list = [...textboxValue];
    let singleCompo = list[index] || {};
    list[index] = { ...singleCompo, [id]: value || "" };
    settextboxValue(list);
    if (inputList[index]["isNewDesign"] == false)
      onChangeCheckIsInStock(index, list);
  };

  const onChangeCheckIsInStock = (index, list) => {
    if (
      inputList[index].isRowMaterial == false &&
      inputList[index].productName.id == ""
    ) {
      return;
    }
    let productDetails = [];
    productDetails[0] = {
      designDefinationId: inputList[index].productName.id,
      categoryId: inputList[index].categoryId,
      isRowMaterial: inputList[index].isRowMaterial,
      isNewDesign: inputList[index].isNewDesign,
    };
    let attributeValue = [];
    attributeValue[0] = list[index];
    dispatch(checkProductInStock(productDetails, attributeValue));
    setRenderIndex(index);
  };

  const renderNewAttributeFunc = (rowIndex) => {
    let newAttributeRender = [],
      getArrayObject = [],
      renderTextBox = [],
      inputVal = "";

    if (_linkCat_Att.length > 0) {
      if (attributeValueList.length <= rowIndex) {
        return;
      }

      getArrayObject = attributeValueList;
      renderTextBox = getArrayObject[rowIndex].catgeoryAttributeCompo || [];

      newAttributeRender = renderTextBox.map((row, renderIndex) => {
        let attValue = _attributeValues.filter((obj) => obj.id == row.id) || [];
        if (attValue.length)
          attValue = JSON.parse(attValue[0].attributeValue) || [];

        if (textboxValue.length > rowIndex)
          inputVal = textboxValue[rowIndex][row.id.toString()];

        return (
          <Grid key={row.id.toString()} item xl={3} lg={3} md={3} xs={3}>
            {row.datatype == "SingleSelection" && (
              <Autocomplete
                freeSolo
                name={row.id.toString()}
                value={inputVal || { id: "", value: "" }}
                onChange={(event, value) => {
                  dynamicAttributeValueChange(
                    value,
                    rowIndex,
                    row.id.toString()
                  );
                }}
                options={attValue}
                getOptionLabel={(option) => (option ? option.value : "")}
                getOptionSelected={(option, value) =>
                  option.value == value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={row.attributeName}
                    variant="outlined"
                  />
                )}
              />
            )}
            {row.datatype == "MultiSelection" && (
              <Autocomplete
                multiple
                name={row.id.toString()}
                options={attValue}
                disableCloseOnSelect
                getOptionLabel={(option) => (option ? option.value : "")}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={inputVal || []}
                input={<Input />}
                onChange={(event, value) => {
                  dynamicAttributeValueChange(
                    value,
                    rowIndex,
                    row.id.toString()
                  );
                }}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.value}
                    </React.Fragment>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={row.attributeName}
                    placeholder={row.attributeName}
                  />
                )}
              />
            )}
            {row.datatype == "FreeText" && (
              <TextField
                label={row.attributeName}
                name={row.id.toString()}
                fullWidth
                value={inputVal || ""}
                onChange={(e) => {
                  dynamicAttributeValueChange(
                    e.target.value,
                    rowIndex,
                    row.id.toString()
                  );
                }}
                variant="outlined"
              />
            )}
          </Grid>
        );
      });
    }
    return newAttributeRender;
  };

  const saveProductOrderList = () => {
    // dispatch(createSellProductListDetails(platformAccountId=1, platformOrderNo=21212, inputList, navigate))
    //dispatch(createSellProductListDetails(commanValue.platformAccountId, commanValue.platformOrderNoDropDown, inputList, textboxValue, navigate))

    dispatch(
      updateSellProductListDetails(
        commanValue.platformAccountId,
        commanValue.platformOrderNoDropDown,
        inputList,
        textboxValue,
        navigate
      )
    );
  };

  let newProductBoxRender = "";
  newProductBoxRender = inputList.map((x, i) => {
    return (
      <CardContent key={i}>
        <Grid container spacing={3}>
          <Grid item md={2} xs={12}>
            <InputLabel htmlFor="icon-button-photo" size="small">
              <Avatar className={classes.avatar} src={previewImage} />
            </InputLabel>
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="Category Name"
              name="categoryname"
              fullWidth
              value={x.handlingDay || ""}
              onChange={(e) => handleInputChange(e, i)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isNewDesign"
                  checked={x.isNewDesign}
                  size="small"
                  onChange={(e, value) =>
                    handleInputChangeDiffComponent(
                      "isNewDesign",
                      Boolean(value),
                      i
                    )
                  }
                  color="primary"
                />
              }
              label="Is new design?"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="Design No"
              name="designo"
              fullWidth
              value={x.handlingDay || ""}
              onChange={(e) => handleInputChange(e, i)}
              variant="outlined"
            />
          </Grid>

          <Grid item md={1} xs={6}>
            <TextField
              label="Quantity"
              name="quantity"
              fullWidth
              type="number"
              variant="outlined"
              onChange={(e) => handleInputChange(e, i)}
              value={x.quantity || 1}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {renderNewAttributeFunc(i)}
          <br key="001" />
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={2} xs={4}>
            <TextField
              label="Transaction Id"
              name="transid"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Sold Price"
              name="soldprice"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Paypal Fee"
              name="paypalfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Party Fee"
              name="partyfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Tax Fee"
              name="taxfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Ads Fee"
              name="adsfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={2} xs={4}></Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Return Money to buyer"
              name="returnmoney"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>

          <Grid item md={2} xs={4}>
            <TextField
              label="Revise Paypal Fee "
              name="revisepaypalfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Revise Party Fee"
              name="revisepartyfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Revise Tax Fee"
              name="revisetaxfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              label="Revise Ads Fee"
              name="adsfee"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {inputList.length !== 1 && inputList.length - 1 !== i && <Divider />}
      </CardContent>
    );
  });

  return (
    <>
      {/* <Page
                title="Add Stock"
            > */}
      {/* <ToastNotify /> */}
      <Container maxWidth="lg">
        <Formik
          initialValues=""
          validationSchema={Yup.object().shape({
            // qty: Yup.string().required('Quantity name is required!'),
            // price: Yup.string().required('price name is required!'),
          })}
          onSubmit={(values, { resetForm }) => {
            saveProductOrderList();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="Add Finance Details of Order"
                  title="Finance Details"
                />
                <Grid container spacing={3} justify="center" p={2}>
                  <Grid item md={3} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container>
                        <KeyboardDatePicker
                          disableToolbar
                          name="orderDate"
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label="Order date"
                          // value={values.orderDate || new Date()}
                          // onChange={handleDateChange}
                          //onChange={(event, value) => { handleChange("orderDate", value) }}
                          onChange={(e, value) => {
                            commanInputValueChange(
                              "platformAccountId",
                              e.target.value
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      name="platformAccountId"
                      fullWidth
                      label="Platform and Id"
                      onChange={(e, value) => {
                        commanInputValueChange(
                          "platformAccountId",
                          e.target.value
                        );
                      }}
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      value={commanValue.platformAccountId}
                    >
                      {defultPlatformEmail}
                      {platformEmailAccountRender}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      freeSolo
                      name="platformOrderNoDropDown"
                      options={_allOrderList}
                      value={
                        commanValue.platformOrderNoDropDown || {
                          id: "",
                          platformOrderNo: "",
                        }
                      }
                      getOptionLabel={(option) =>
                        option ? option.platformOrderNo : ""
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        commanInputValueChange(
                          "platformOrderNoDropDown",
                          value
                        );
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Search platform Order No"
                            variant="outlined"
                          />
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider />

                {newProductBoxRender}
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttonMargin}
                    type="submit"
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
      <Popup
        key="productDesignPopup"
        // title="Add Customer Details"
        openPopup={productOpenPopup}
        setOpenPopup={setProductOpenPopup}
      >
        <ProductCreate
          setOpenPopup={setProductOpenPopup}
          isPopup={productOpenPopup}
        />
      </Popup>
      <Popup
        key="stockInPopup"
        openPopup={stockInOpenPopup}
        setOpenPopup={setStockInOpenPopup}
      >
        <StockInList
          key="0010"
          TableData={_InStockProduct}
          setOpenPopup={setStockInOpenPopup}
          isPopup={stockInOpenPopup}
          fillAttributeValue={fillAttributeValue}
        />
      </Popup>
    </>
  );
};

export default SellOrderFinanceDetails;
