/*eslint eqeqeq: "off"*/
import {
  ADD_COURIER_COMP_DETAILS,
  FETCH_COURIER_COMP_DETAILS_LIST,
  EDIT_COURIER_COMP_DETAILS,
  UPDATE_COURIER_COMP_DETAILS,
  DELETE_COURIER_COMP_DETAILS,
} from "./_courierCompany_Type";

const initialState = {
  _editedDetails: "",
  _id: "",
  _msg: "",
  _allDetails: [],
};

const CourierReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURIER_COMP_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case EDIT_COURIER_COMP_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case UPDATE_COURIER_COMP_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case FETCH_COURIER_COMP_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
      };
    case DELETE_COURIER_COMP_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
        _id: action.payload,
      };

    // case CLEAR_COURIER_EDITED_DATA: return {
    //     ...state,
    //     _editedDetails: '',
    // }
    default:
      return state;
  }
};

export default CourierReducer;
