/*eslint eqeqeq: "off"*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { customerIds } from "./CustomerList";
import { allPendingCustomers } from ".";
import { jsPDF } from 'jspdf'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, setParentFilterFn,custoIds,pendingCustomes, ...rest }) => {

  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => { }, [filterFn]);

  const generatePDf = () => {

    let filterData = [];
    var doc = new jsPDF({ orientation: "p", lineHeight: 1.5 })

    let y = 0, z = 0;

    pendingCustomes.forEach((ele, i) => {
      for (let x of custoIds) {
        if (x == ele.id)
          filterData.push(ele)
      }

    })

    filterData.forEach((ele, i) => {

      if (i == 0) {
        y = 20
      } else if (y >= 200) {
        z = 0;
        y = 20;
        doc.addPage()
      } else {
        y = ((z + 2) * 10) + (y - (z * 8))
      }

      doc.text(10, y, `Customer Name: ${ele.customerName}`);
      y = y + 9
      doc.text(10, y, `Customer Email: ${ele.customerEmail}`);
      y = y + 9
      doc.text(10, y, `Customer MobileNo: ${ele.customerMobileNo}`);
      y = y + 9;
      var splitTitle = doc.splitTextToSize(`Customer Address : ${ele.customerAddress}`, 150);
      doc.text(10, y, splitTitle);
      y = y + 15;
      z = z + 1;

    })

    doc.save("pdf generated")

    return <> <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    </>
  }

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.customerName.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
    setParentFilterFn(filterFn);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            const success = generatePDf()
            if (success)
              toast('pdf downloaded successfully')
          }}
        >
          Print
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search customer by name"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
