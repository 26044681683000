/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
// import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  insertSellOrderPrimaryDetails,
  updateSellOrderPrimaryDetails,
  updateSellOrderPrimaryDetailsBack,
} from "./_orderBook_Action";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { fetchPltformNameList, fetchAllPlatformAcc } from "../../redux";
import CustomerCreate from "../customer/CustomerCreate";
import Popup from "src/components/Popup";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const CommanDetailsOrder = ({
  className,
  saveOrder,
  handleChangeDiffCompo,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let _platfromAccountId =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];
  let _allPlatfromNameList =
    useSelector((state) => state.platfromAccount._allPlatfromNames) || [];

  let _editedDetails =
    useSelector((state) =>
      state.orderBook._commanEditedDetails
        ? state.orderBook._commanEditedDetails
        : state.orderBook.storeDetails
    ) || "";
  let firstFormId = useSelector((state) => state.orderBook.firstFormId);
  const [openPopup, setOpenPopup] = useState(false);

  const [values, setValues] = useState({
    platformName: _editedDetails.platformId
      ? _editedDetails.platformId
      : _editedDetails.platformName || "",
    platformAccountId: _editedDetails.accountId
      ? _editedDetails.accountId
      : _editedDetails.platformAccountId || "",
    platformOrderNo: _editedDetails.platformOrderNo || "",
    priority: _editedDetails.isPriority
      ? _editedDetails.isPriority
      : _editedDetails.priority || "0",
    custName: _editedDetails.customerName
      ? _editedDetails.customerName
      : _editedDetails.custName || "",
    custEmail: _editedDetails.customerEmail
      ? _editedDetails.customerEmail
      : _editedDetails.custEmail || "",
    custPhone: _editedDetails.customerMobileNo
      ? _editedDetails.customerMobileNo
      : _editedDetails.custPhone || 0,
    custAddress: _editedDetails.customerAddress
      ? _editedDetails.customerAddress
      : _editedDetails.custAddress || "",
    deliveryDay: _editedDetails.deliveryDay || 0,
    note: _editedDetails.orderNotes
      ? _editedDetails.orderNotes
      : _editedDetails.note || "",
    orderDate: _editedDetails.orderDate || new Date(),
  });

  const handleChange = (name, data) => {
    const list = { ...values };
    list[name] = data || "";
    setValues(list);
  };

  const backBtnClick = () => {
    navigate("/app/order_book", { replace: false });
  };

  useEffect(() => {
    if (_platfromAccountId.length <= 0) {
      dispatch(fetchAllPlatformAcc());
    }
    dispatch(fetchPltformNameList());
    // eslint-disable-next-line
  }, []);

  let platformNameDefultOption = (
    <option key="" value="000e0">
      Select Platform{" "}
    </option>
  );
  let defultOption = (
    <option key="" value="000e0">
      Select Account Id{" "}
    </option>
  );
  let dropdownRender = [];
  if (_platfromAccountId) {
    dropdownRender = _platfromAccountId.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.accountName}
        </option>
      );
    });
  }
  let platFormNameListDropdown = [];
  if (_allPlatfromNameList) {
    platFormNameListDropdown = _allPlatfromNameList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName}
        </option>
      );
    });
  }

  // let orderStatusDefultOption = <option key="" value="000e0">Select Order Status</option>
  // let orderStatusDropdownRender = [], orderStatusArray = [{ id: 1, orderStatus: "Pending" }, { id: 2, orderStatus: "Received from Maker" }, { id: 3, orderStatus: "Quality Check PASS" },
  // { id: 4, orderStatus: "Quality Check FAILED" }, { id: 5, orderStatus: "Quality Check issue with shipped" }, { id: 6, orderStatus: "Shipped" }, { id: 7, orderStatus: "Delivered" }, { id: 8, orderStatus: "Return" },
  // { id: 9, orderStatus: "Cancel" }, { id: 10, orderStatus: "Lost" }, { id: 11, orderStatus: "Replacement" }, { id: 12, orderStatus: "Exchanage" }]

  // if (orderStatusArray) {
  //     orderStatusDropdownRender = orderStatusArray.map(option => {
  //         return <option
  //             key={option.id}
  //             value={option.id}>
  //             {option.orderStatus}
  //         </option>;
  //     });
  // }

  var buttonText = "Save & Next";
  if (_editedDetails && !firstFormId) {
    buttonText = "Update";
  } else if (_editedDetails && firstFormId) {
    buttonText = "Update & Next";
  } else {
    buttonText = "Save & Next";
  }
  const handleDateChange = (date) => {
    handleChange("orderDate", date);
  };

  const [formErrors, setFormErrors] = useState({
    platformName: "",
    platformAccountId: "",
    platformOrderNo: "",
    custName: "",
  });

  const validate = () => {
    let isError = false;
    const errorList = { ...formErrors };

    if (values.platformName == "" || values.platformName == 0) {
      isError = true;
      errorList["platformName"] = "Please select platform name";
    }
    if (values.platformAccountId == "" || values.platformAccountId == 0) {
      isError = true;
      errorList["platformAccountId"] = "Please select platform email";
    }
    if (values.platformOrderNo == "" || values.platformOrderNo == 0) {
      isError = true;
      errorList["platformOrderNo"] = "Please Enter platform OrderNo.";
    }
    if (values.custName == "" || values.custName == 0) {
      isError = true;
      errorList["custName"] = "Please Enter customer name.";
    }

    if (isError) setFormErrors(errorList);

    return isError;
  };

  const clearValidationError = (e) => {
    const errorList = { ...formErrors };
    let name = e.target.name;
    errorList[name] = "";
    setFormErrors(errorList);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Formik
          initialValues={values}
          onSubmit={({ resetForm }) => {
            let err = validate();
            if (!err) {
              if (_editedDetails && buttonText == "Update") {
                values.custId = _editedDetails.custId;
                values.orderid = _editedDetails.id;
                dispatch(updateSellOrderPrimaryDetails(values, navigate));
              } else if (firstFormId) {
                values.custId = firstFormId.customerId;
                values.orderid = firstFormId.id;
                dispatch(updateSellOrderPrimaryDetailsBack(values, navigate));
              } else {
                dispatch(insertSellOrderPrimaryDetails(values, navigate));
              }
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form
              autoComplete="off"
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Order Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Platform Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformName"
                        error={Boolean(formErrors.platformName)}
                        helperText={formErrors.platformName}
                        fullWidth
                        label="Platform Name"
                        onChange={(e) => {
                          handleChange("platformName", e.target.value);
                          dispatch(fetchAllPlatformAcc(e.target.value));
                          clearValidationError(e);
                        }}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={values.platformName}
                      >
                        {platformNameDefultOption}
                        {platFormNameListDropdown}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformAccountId"
                        fullWidth
                        label="Platform and Id"
                        error={Boolean(formErrors.platformAccountId)}
                        helperText={formErrors.platformAccountId}
                        onChange={(e) => {
                          handleChange("platformAccountId", e.target.value);
                          clearValidationError(e);
                        }}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={values.platformAccountId}
                      >
                        {defultOption}
                        {dropdownRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformOrderNo"
                        error={Boolean(formErrors.platformOrderNo)}
                        helperText={formErrors.platformOrderNo}
                        fullWidth
                        label="Platform Order No"
                        onChange={(e) => {
                          handleChange("platformOrderNo", e.target.value);
                          clearValidationError(e);
                        }}
                        value={values.platformOrderNo}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Create order date :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <KeyboardDatePicker
                            disableToolbar
                            name="orderDate"
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="Create order date"
                            value={values.orderDate || new Date()}
                            onChange={handleDateChange}
                            //onChange={(event, value) => { handleChange("orderDate", value) }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Customer Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Customer Name"
                        error={Boolean(formErrors.custName)}
                        helperText={formErrors.custName}
                        fullWidth
                        name="custName"
                        variant="outlined"
                        onChange={(e) => {
                          handleChange("custName", e.target.value);
                          clearValidationError(e);
                        }}
                        value={values.custName}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Customer Email"
                        fullWidth
                        name="custEmail"
                        type="email"
                        variant="outlined"
                        onChange={(e) =>
                          handleChange("custEmail", e.target.value)
                        }
                        value={values.custEmail}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Customer Phone No"
                        fullWidth
                        name="custPhone"
                        type="number"
                        variant="outlined"
                        onChange={(e) =>
                          handleChange("custPhone", e.target.value)
                        }
                        value={values.custPhone}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Customer Address"
                        fullWidth
                        name="custAddress"
                        multiline
                        variant="outlined"
                        onChange={(e) =>
                          handleChange("custAddress", e.target.value)
                        }
                        value={values.custAddress}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Due Days :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="deliveryDay"
                        fullWidth
                        label="Due Days"
                        type="number"
                        value={values.deliveryDay}
                        onChange={(e) =>
                          handleChange("deliveryDay", e.target.value)
                        }
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Is Priority? :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <RadioGroup
                        row
                        label="Is Priority"
                        name="priority"
                        value={values.priority.toString() || "0"}
                        onChange={(e) =>
                          handleChange("priority", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio size="small" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Add Note :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="note"
                        fullWidth
                        label="Add Note"
                        multiline
                        rows={5}
                        onChange={(e) => handleChange("note", e.target.value)}
                        value={values.note}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttonMargin}
                    type="submit"
                  >
                    {buttonText}
                  </Button>

                  {/* <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.buttonMargin}
                                        onClick={e => nextStep()}
                                    >
                                        Next
                                 </Button> */}
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
      <Popup
        title="Add Customer Details"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CustomerCreate setOpenPopup={setOpenPopup} isPopup={openPopup} />
      </Popup>
    </>
  );
};

CommanDetailsOrder.propTypes = {
  className: PropTypes.string,
};

export default CommanDetailsOrder;
