import {
  ADD_ATTRIBUTE_DETAILS,
  FETCH_ATTRIBUTE_LIST,
  EDIT_CATEGORY_ATTRIBUTE_DETAILS,
  DELETE_ATTRIBUTE_DETAILS,
  FETCH_CATEGORY_DROPDOWN_LIST,
} from "./_categoryAttribute_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const createNewDetails = (values, navigate, isPopup) => {
  let formData = {
    attributename: values.categoryAttribute,
    datatype: values.datatypeName,
    attvalues: values.attValues,
    description: values.description,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-category-attribute",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let message = res.data.message;
          dispatch({
            type: ADD_ATTRIBUTE_DETAILS,
            message: message,
          });
          if (isPopup === undefined || isPopup === false)
            navigate("../categoryattribute", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchAllCategoryAttribute = (page = 0, limit = 0) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-category-attribute?page=" +
        page +
        "&limit=" +
        limit,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          //let message = res.data.message
          dispatch(getAllDetails(result));
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
      });
  };
};

export const getAllDetails = (data) => {
  return {
    type: FETCH_ATTRIBUTE_LIST,
    listview: data,
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_CATEGORY_ATTRIBUTE_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-category-attribute",
      method: "POST",
      data: {
        attributeid: r_id,
        attributename: updateData.categoryAttribute,
        datatype: updateData.datatypeName,
        description: updateData.description,
        isenable: true,
        isdelete: false,
        attvalues: updateData.attValues,
        deleteValueList: updateData.deleteValueList,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("../categoryattribute", { replace: false });
          //alert(message);
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-category-attribute",
      method: "POST",
      data: { attributeid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          //let message = res.data.message
          dispatch({
            type: DELETE_ATTRIBUTE_DETAILS,
            payload: id,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchAllCategory_DropDown = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-category",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if ((res.data.status = 200)) {
          let result = res.data.data;
          dispatch(getDropdownData(result));
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        console.log("err.response", err.response.data.message);
      });
  };
};

export const getDropdownData = (data) => {
  return {
    type: FETCH_CATEGORY_DROPDOWN_LIST,
    listview: data,
  };
};
