import React, { useEffect, useState } from "react";
import { Container, makeStyles, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import OrderList from "./OrderList";
import ReturnList from './ReturnList'
import { useSelector, useDispatch } from "react-redux";
import { fetchAllDetails } from "./_order_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ProfitBySubOrders = () => {

  let viewOrderList;
  let viewReturnList;

  const classes = useStyles();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [platId, setPlatId] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [value, setValue] = React.useState(0);
  const [tab,setTab] = React.useState("InProfit")
  const dispatch = useDispatch();
  let allFetchData = useSelector((state) => state.orderProfit._allDetails) || [];
  let allFetchReturn = useSelector((state) => state.orderProfit._returnDetails) || [];
  useEffect(() => {
    dispatch(fetchAllDetails(platId,dateRange,tab));
  }, [filterFn,platId,dateRange]);

  viewOrderList = <OrderList TableData={allFetchData} filterFn={filterFn} />;
  viewReturnList = <ReturnList TableData={allFetchReturn} platId={platId} dateRange={dateRange} tab={tab} filterFn={filterFn}/>;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabName = (tab) => {
    setTab(tab)
    dispatch(fetchAllDetails(platId,dateRange,tab));
  };
  return (
    <Page className={classes.root} title="Customer">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} setPlatId={setPlatId} setDateRange={setDateRange} />
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="order tabs">
            <Tab
              label="In Profit"
              {...a11yProps(0)}
              onClick={() => 
                getTabName("InProfit")
              }
            />
            <Tab
              label="In Return"
              {...a11yProps(1)}
              onClick={() => 
                getTabName("InReturn")}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          { allFetchData.length ? <Box mt={3}>{viewOrderList}</Box> : ""}
        </TabPanel>
        <TabPanel value={value} index={1}>
           {allFetchReturn.length ? <Box mt={3}>{viewReturnList}</Box> : ""}
        </TabPanel>
      </Container>
    </Page>
  );
};

export default ProfitBySubOrders;
