/*eslint eqeqeq: "off"*/
import * as Action from "./_inc_Exp_Type";

const initialState = {
  _editedDetails: "",
  _id: "",
  _allDetails: [],
};

const incomeExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_ALL_INCOME_EXPENSE_LIST:
      return {
        ...state,
        _allDetails: action.payload,
        _editedDetails: "",
      };
    case Action.ADD_INCOME_EXPENSE_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case Action.EDIT_INCOME_EXPENSE_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case Action.UPDATE_INCOME_EXPENSE_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _id: action.id,
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.id ? action.payload[0] : obj
        ),
      };
    case Action.DELETE_INCOME_EXPENSE_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload.id
        ),
      };
    default:
      return state;
  }
};

export default incomeExpenseReducer;
