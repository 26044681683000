/*eslint eqeqeq: "off"*/
import {
  ADD_STONE_SHAPE_DETAILS,
  FETCH_ALL_STONE_SHAPE_DETAILS_LIST,
  EDIT_STONE_SHAPE_DETAILS,
  UPDATE_STONE_SHAPE_DETAILS,
  DELETE_STONE_SHAPE_DETAILS,
} from "./_stoneDetails_Type";
const initialState = {
  _editedDetails: "",
  _msg: "",
  _allDetails: [],
};

const stoneShapeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STONE_SHAPE_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case EDIT_STONE_SHAPE_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_STONE_SHAPE_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case FETCH_ALL_STONE_SHAPE_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
        _msg: "",
      };
    case DELETE_STONE_SHAPE_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };

    default:
      return state;
  }
};

export default stoneShapeReducer;
