import { createStore, applyMiddleware, combineReducers } from "redux";
import userAccountReducer from "./reducer/userAccountReducer";
import userReducer from "./reducer/userReducer";
import platfromAccReducer from "../views/platform/_platformAccount_Reducer";
import orderReducer from "src/views/profitBySubOrders/_order_reducer";
import manufacturerReducer from "../views/manufacturer/_manufacturer_Reducer";
import customerReducer from "../views/customer/_customer_Reducer";
import categoryReducer from "../views/category/_category_Reducer";
import categoryAttributeReducer from "../views/categoryAttribute/_categoryAttribute_Reducer";
import productStockReducer from "../views/singleproductStock/_product_Stock_Reducer";
import productReducer from "../views/product/_product_Reducer";
import firmReducer from "../views/firm/_firm_Reducer";
import linkReducer from "../views/link_CategoryAttribute/_link_Cat_Att_Reducer";
import supplierReducer from "../views/supplier/_supplier_Reducer";
import couierCompanyReducer from "../views/courierCompany/_courierCompany_Reducer";
import orderBookReducer from "../views/orderBook/_orderBook_Reducer";
import purchaseReducer from "../views/purchase_Invoice/_purchase_Reducer";
import stoneShapeReducer from "../views/stoneDetails/_stoneShape_Reducer";
import stoneTypeReducer from "../views/stoneDetails/_stoneType_Reducer";
import orderFinanceReducer from "../views/orderFinance/_finance_Reducer";
import incomeExpenseReducer from "../views/incomeExpense/_inc_Exp_Reducer";
import issueReturnsReducer from "../views/issuesReturns/_issue_Returns_Reducer";
import stockDetailsReducer from "../views/stock_details/_stock_Details_Reducer";
import issueStoneReturnsReducer from "src/views/iss_stone_maker/_order_reducer";
import dashboardReducer from "src/views/reports/DashboardView/_dashboard_reducer";
import overDueReducer from "src/views/overDueOrders/_overDue_Reducer";

const thunkMiddleware = require("redux-thunk").default;

const mainReducer = combineReducers({
  userAccount: userAccountReducer,
  user: userReducer,
  platfromAccount: platfromAccReducer,
  manufacturer: manufacturerReducer,
  customer: customerReducer,
  category: categoryReducer,
  categoryAttribute: categoryAttributeReducer,
  product: productReducer,
  linkCat_Att: linkReducer,
  supplier: supplierReducer,
  firm: firmReducer,
  singleProductStock: productStockReducer,
  couierCompany: couierCompanyReducer,
  orderBook: orderBookReducer,
  purchase: purchaseReducer,
  stoneShape: stoneShapeReducer,
  stoneType: stoneTypeReducer,
  orderFinance: orderFinanceReducer,
  incomeExpense: incomeExpenseReducer,
  issueReturns: issueReturnsReducer,
  issueStoneReturn: issueStoneReturnsReducer,
  stockDetails: stockDetailsReducer,
  orderProfit:orderReducer,
  dashboard:dashboardReducer,
  overDueOrder:overDueReducer
});

const store = createStore(mainReducer, applyMiddleware(thunkMiddleware));

export default store;
