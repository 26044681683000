export const ADD_NEW_PURCHASE_DETAILS = "ADD_NEW_PURCHASE_DETAILS";
export const FETCH_ALL_PURCHASE_INVOICE_LIST =
  "FETCH_ALL_PURCHASE_INVOICE_LIST";
export const EDIT_OLD_PURCHASE_INVOICE_DETAILS =
  "EDIT_OLD_PURCHASE_INVOICE_DETAILS";
export const UPDATE_PURCHASE_INVOICE_DETAILS =
  "UPDATE_PURCHASE_INVOICE_DETAILS";
export const DELETE_PURCHASE_INVOICE_ENTRY_DETAILS =
  "DELETE_PURCHASE_INVOICE_ENTRY_DETAILS";
export const FETCH_INVOICE_PRODUCT_ENTRY_LIST =
  "FETCH_INVOICE_PRODUCT_ENTRY_LIST";
export const GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES =
  "GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES";
