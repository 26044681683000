/* eslint no-unused-vars : "off" */
/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  Divider,
  Button,
  Dialog,
} from "@material-ui/core";
/*eslint no-useless-rename: "off"*/
import { ChevronDown, ChevronUp } from "react-feather";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { issueStoneMakerDispatcher } from "./_order_Action";
import { createNewDetailsPost } from "src/utils/actionMethod";

const useStyles = makeStyles((theme) => ({
  root: {},
  issueButton: {
    margin: "auto",
    display: "flex",
    marginTop: "20px",
  },
}));

const headCells = [
  { id: "expand", label: "" },
  { id: "orderDate", label: "Order Date" },
  { id: "makerSku", label: "Maker SKU" },
  { id: "orderId", label: "Order ID" },
  { id: "designNo", label: "Design No." },
  { id: "deliveryDay", label: "Delivery Day" },
  { id: "manufacturerName", label: "Manufacturer Name" },
  { id: "stoneIssueMaker", label: "Stone Issue Maker", disableSorting: true },
];
function DetailsArrayloop(props) {
  const { row } = props;
  return (
    <>
      <Box marginBottom="15px">
        <Typography component="h5" variant="h5" className="typography_margin">
          Requirements
        </Typography>
        {row.map((item, i) => {
          return item.dimondShape === undefined ? (
            "No Diamond Require"
          ) : (
            <div key={i}>
              <h5>{item.dimondType}</h5>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={500}
              >
                <span>Available:- {item.availableOnStockQty}</span>
                <span>Require:- {item.requireQty}</span>
                <span>Shape:- {item.dimondShape}</span>
                <span>Size:- {item.dimondSize}</span>
              </Box>
              <Divider className="divider_margin" />
            </div>
          );
        })}
      </Box>
    </>
  );
}
function DetailsArray(props) {
  const { row } = props;
  return (
    <Box marginBottom="15px">
      <Typography component="h5" variant="h5" className="typography_margin">
        {/* Requirements */}
        No Diamond Require
      </Typography>

      {/* {row.map((item, i) => {
          return item.dimondShape === undefined ? (
            "No Diamond Require"
          ) : (
            <div key={i}>
              <h5>{item.dimondType}</h5>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={500}
              >
                <span>Available:- {item.availableOnStockQty}</span>
                <span>Require:- {item.requireQty}</span>
                <span>Shape:- {item.dimondShape}</span>
                <span>Size:- {item.dimondSize}</span>
              </Box>
              <Divider className="divider_margin" />
            </div>
          );
        })} */}

    </Box>
  );
}
function SimpleDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = React.useState("");
  const { onClose, open, row, array } = props;
  const handleClose = () => {
    onClose();
  };
  const issueMaker = (row) => {
    if (
      row.manufacturerName == null ||
      row.manufacturerName == undefined ||
      row.manufacturerName == ""
    ) {
      setErrorMsg("First assign manufacturer.");
    } else {
      let flag = true
      array.forEach((element) => {
        if (element.availableOnStockQty < element.requireQty) {
          setErrorMsg("Enough stock is require");
          setTimeout(() => {
            setErrorMsg("")
          }, 5000);
          flag = false
          return;
        }
      });

      if(flag == true){

        var data = {
          productStockId: row.stockId,
          makerEmail: row.manufacturerEmail,
          definitionId: row.definition_id,
          subProductUid: row.product_uid,
          productQty: row.productQty,
          makerSku: row.makerSku,
          prod_Name:row.prod_Name,
          prod_Image:row.prod_Image,
          cate_Name:row.cate_Name,
          productQty:row.productQty,
          dueDay:row.due_day,
          handlingDay:row.handlingDay,
          diamondDetails: array,
          requiredRowsCount: array.length,
          issueRowsCount: array.length,
        };

        dispatch(issueStoneMakerDispatcher(data,navigate, true,));
          handleClose();
      }

    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Box sx={{ margin: 20 }} key={row.product_uid}>
        {array.length >= 1 ? (
          array.map((item, i) => {
            return item.dimondShape == undefined ? (
              "No Diamond Require"
            ) : (
              <div key={i}>
                {/* <FormControlLabel
                  label={item.dimondType}
                  control={
                    <Checkbox
                      checked={item.isChecked}
                      onChange={(e) => handleChange(e, item, i)}
                    />
                  }
                /> */}
                <Typography
                  component="h5"
                  variant="h5"
                  className="typography_margin"
                >
                  {item.dimondType}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  maxWidth={500}
                >
                  <span className="issue_maker_span">
                    Available:- {item.availableOnStockQty}
                  </span>
                  <span className="issue_maker_span">
                    Require:- {item.requireQty}
                  </span>
                  <span className="issue_maker_span">
                    Shape:- {item.dimondShape}
                  </span>
                  <span className="issue_maker_span">
                    Size:- {item.dimondSize}
                  </span>
                </Box>
                <Divider className="divider_margin" />
              </div>
            );
          })
        ) : row.dimondShape == undefined ? (
          "No Diamond Requiress"
        ) : (
          <>
            <h5>{row.dimondType}</h5>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              maxWidth={500}
            >
              <span>Available:- {row.availableOnStockQty}</span>
              <span>Require:- {row.requireQty}</span>
              <span>Shape:- {row.dimondShape}</span>
              <span>Size:- {row.dimondSize}</span>
            </Box>
          </>
        )}
        <Button
          color="primary"
          variant="contained"
          className={classes.issueButton}
          onClick={(e) => issueMaker(row)}
        >
          Stone Issue Maker
        </Button>
        <p style={{ color: "red" }}>{errorMsg}</p>
      </Box>
    </Dialog>
  );
}
function Row(props) {
  const { row, rowProductStockInfo } = props;
  let ProductStockInfo = rowProductStockInfo[0];
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = (row) => {
    if (row.singleOrderStatus === "InIssue") {
      let data = {
        subProductUid: row.product_uid,
      };
      let result = createNewDetailsPost("/api/get-inissue-status-orders", data);
    }
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  let detailsArray = "";
  const array = [];
  /*eslint array-callback-return: "off"*/
  rowProductStockInfo.map((stock) => {
    if (stock.length >= 1) {
      stock.map((e) => {
        if (row.product_uid == e.sub_product_uid) {
          detailsArray = e;
          array.push(e);
        }
      });
    } else {
      if (row.product_uid == stock[0].sub_product_uid) {
        detailsArray = stock[0];
      }
    }
  });

  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell padding="checkbox">
          <div onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{moment(row.orderDate).format("DD-MM-YYYY")}</TableCell>
        <TableCell>{row.makerSku}</TableCell>
        <TableCell>{row.platformOrderNo}</TableCell>
        <TableCell>
          {row.isNewDesign == 1 ? "New Design" : row.definition_id}
        </TableCell>
        <TableCell>{row.deliveryDay}</TableCell>
        <TableCell>{row.manufacturerName}</TableCell>
        <TableCell width="20%">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleClickOpen(row)}
          >
            Stone Issue Maker
          </Button>
        </TableCell>
        <SimpleDialog
          key={row.product_uid}
          open={openDialog}
          onClose={handleClose}
          row={row}
          array={array}
        />
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                component="h5"
                variant="h5"
                className="typography_margin"
              >
                Details
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                maxWidth={500}
                justifyContent="space-between"
              >
                <p>
                  <span>
                    Design Name :{" "}
                    <span>
                      {row.isNewDesign == 1 ? "New Design" : row.prod_Name}
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    Product Qty : <span>{row.productQty}</span>
                  </span>
                </p>
              </Box>
            </Box>
            {array.length >= 1 ? (
              <DetailsArrayloop row={array} />
            ) : (
              <DetailsArray row={array} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const OrderList = ({ className, TableData,ProductData, filterFn, ...rest }) => {
  const classes = useStyles();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    selectedAllIds,
  } = commanTable(TableData, headCells, filterFn);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />

            {Object.keys(TableData).length !== 0 &&
            TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <Row
                    key={row.product_uid}
                    row={row}
                    rowProductStockInfo={ProductData}
                  />
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      {TableData.length != undefined && TableData.length > 0 ? (
        <TblPagination />
      ) : (
        ""
      )}
    </Card>
  );
};

OrderList.propTypes = {
  className: PropTypes.string,
};

export default OrderList;
