import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import AccountView from "src/views/account/AccountView";
import UserListView from "src/views/user";
import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import OrderView from "src/views/demo";

import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";
import PlatformAccountView from "src/views/platform";
import PlatformNameView from "src/views/platform/PlatformNameCreate";
import PlatformAccountCreate from "src/views/platform/platformAccount";

import ManufacturerCreate from "src/views/manufacturer/ManufacturerCreate";
import Manufacturer from "src/views/manufacturer/index";

import SupplierCreate from "src/views/supplier/SupplierCreate";
import Supplier from "src/views/supplier";

import CustomerCreate from "src/views/customer/CustomerCreate";
import Customer from "src/views/customer";
import Customers from "src/views/pendingCustomers";

import Category from "src/views/category";
import CategoryCreate from "src/views/category/CategoryCreate";

import CategoryAttribute from "src/views/categoryAttribute";
import CategoryAttributeCreate from "src/views/categoryAttribute/CategoryAttributeCreate";

import OrderBookList from "src/views/orderBook/";
import CommanOrderDetails from "src/views/orderBook/CommanOrderDetails";
import AddSellOrder from "src/views/orderBook/AddSellOrder";
import SellOrderFinanceDetails from "src/views/orderBook/FinanceDetails";

import OrderFinance from "src/views/orderFinance";
import AddFinanceDetail from "src/views/orderFinance/FinanceDetails";

import FinanceManufacturer from "src/views/orderFinance/FinanceManufacturer";

// import AttributeValueView from 'src/views/Nott_c_AttributeValue';
// import AttributeValueCreate from 'src/views/Nott_c_AttributeValue/AttributeValueCreate';

import ProductView from "src/views/product";
import ProductCreate from "src/views/product/ProductCreate";

import ProductStockView from "src/views/singleproductStock";
import AddProductStock from "src/views/singleproductStock/AddProductStock";

import PurchaseListView from "src/views/purchase_Invoice";
import CreatePurchase from "src/views/purchase_Invoice/CreatePurchase";

import LinkCategoryAttView from "src/views/link_CategoryAttribute";
import CreateLinkCatAtt from "src/views/link_CategoryAttribute/CreateLinkCatAtt";

import FirmCreate from "src/views/firm/FirmCreate";
import FirmListView from "src/views/firm";

import CourierCompanyCreate from "src/views/courierCompany/CourierCompanyCreate";
import CourierCompanyListView from "src/views/courierCompany";

import StoneShapeCreate from "src/views/stoneDetails/StoneShapeCreate";
import StoneShape from "src/views/stoneDetails";

import StoneTypeCreate from "src/views/stoneDetails/StoneTypeCreate";

import IncomeExpense from "src/views/incomeExpense";
import IncomeExpenseCreate from "./views/incomeExpense/IncomeExpenseCreate";

import IssueRerurns from "src/views/issuesReturns";
import Iss_stone from "./views/iss_stone_maker";

import StockDetails from "./views/stock_details";
import StockDetailsCreate from "./views/stock_details/StockDetailsCreate";
import ProfitBySubOrders from "./views/profitBySubOrders";
import OverDueOrders from "./views/overDueOrders";

export const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "stone_info", element: <StoneShape /> },
      { path: "stone_info/createshape", element: <StoneShapeCreate /> },
      { path: "stone_info/editshape", element: <StoneShapeCreate /> },
      { path: "stone_info/createtype", element: <StoneTypeCreate /> },
      { path: "stone_info/edittype", element: <StoneTypeCreate /> },

      { path: "purchase", element: <PurchaseListView /> },
      { path: "purchase/create", element: <CreatePurchase /> },
      { path: "purchase/edit", element: <CreatePurchase /> },

      { path: "order_book", element: <OrderBookList /> },
      { path: "order_book/create", element: <CommanOrderDetails /> },
      { path: "order_book/add_sell_order", element: <AddSellOrder /> },
      { path: "order_book/edit", element: <CommanOrderDetails /> },
      {
        path: "order_book/financeDetails",
        element: <SellOrderFinanceDetails />,
      },

      { path: "order_finance/", element: <OrderFinance /> },
      {
        path: "order_finance/manufacturer_edit",
        element: <FinanceManufacturer />,
      },
      { path: "order_finance/customer_edit", element: <AddFinanceDetail /> },

      { path: "order_profit", element: <ProfitBySubOrders /> },
      { path: "over_due", element: <OverDueOrders /> },

      { path: "courier_company", element: <CourierCompanyListView /> },
      { path: "courier_company/create", element: <CourierCompanyCreate /> },
      { path: "courier_company/edit", element: <CourierCompanyCreate /> },

      { path: "firm", element: <FirmListView /> },
      { path: "firm/create", element: <FirmCreate /> },
      { path: "firm/edit", element: <FirmCreate /> },

      { path: "linkcat_att", element: <LinkCategoryAttView /> },
      { path: "linkcat_att/create", element: <CreateLinkCatAtt /> },
      { path: "linkcat_att/edit", element: <CreateLinkCatAtt /> },

      { path: "products", element: <ProductView /> },
      { path: "products/create", element: <ProductCreate /> },
      { path: "products/edit", element: <ProductCreate /> },

      { path: "product_stock", element: <ProductStockView /> },
      { path: "product_stock/create", element: <AddProductStock /> },
      { path: "product_stock/edit", element: <AddProductStock /> },

      { path: "platformAccount", element: <PlatformAccountView /> },
      { path: "platformAccount/create", element: <PlatformAccountCreate /> },
      { path: "platformAccount/edit", element: <PlatformAccountCreate /> },
      { path: "platformAccount/createname", element: <PlatformNameView /> },

      { path: "manufacturer/create", element: <ManufacturerCreate /> },
      { path: "manufacturer/edit", element: <ManufacturerCreate /> },
      { path: "manufacturer", element: <Manufacturer /> },

      { path: "supplier/create", element: <SupplierCreate /> },
      { path: "supplier/edit", element: <SupplierCreate /> },
      { path: "supplier", element: <Supplier /> },

      { path: "customer/create", element: <CustomerCreate /> },
      { path: "customer/edit", element: <CustomerCreate /> },
      { path: "customer", element: <Customer /> },
      { path: "pendingcustomers", element: <Customers /> },

      { path: "categorys/create", element: <CategoryCreate /> },
      { path: "categorys/edit", element: <CategoryCreate /> },
      { path: "categorys", element: <Category /> },

      {
        path: "categoryattribute/create",
        element: <CategoryAttributeCreate />,
      },
      { path: "categoryattribute/edit", element: <CategoryAttributeCreate /> },
      { path: "categoryattribute", element: <CategoryAttribute /> },

      // { path: 'attributevalue/create', element: <AttributeValueCreate /> },
      // { path: 'attributevalue/edit', element: <AttributeValueCreate /> },
      // { path: 'attributevalue', element: <AttributeValueView /> },

      { path: "users/create", element: <AccountView /> },
      { path: "users/edit", element: <AccountView /> },
      { path: "users", element: <UserListView /> },

      { path: "inc_exp", element: <IncomeExpense /> },
      { path: "inc_exp/create", element: <IncomeExpenseCreate /> },
      { path: "inc_exp/edit", element: <IncomeExpenseCreate /> },

      { path: "iss_ret", element: <IssueRerurns /> },
      { path: "iss_stone", element: <Iss_stone /> },

      { path: "stock_details", element: <StockDetails /> },
      { path: "stock_details/list", element: <StockDetailsCreate /> },

      { path: "dashboard", element: <DashboardView /> },
      { path: "orders", element: <OrderView /> },
      { path: "settings", element: <SettingsView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "register", element: <RegisterView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export const loginRoute = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "register", element: <RegisterView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
];

export default routes;
