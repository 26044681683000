import {TOTALPROFIT,PENDINGISSUSTONEORDERS,RETURNCUSTFINANCERATIO,TOTALTURNOVER} from './_dashboard_type'
const initialState = {
    _msg: "",
    _allDetails: [],
    _pendingStonIssueOrders:[],
    _returnRatio:[],
    _totalTurnOver:[]
  };

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case TOTALPROFIT:
        return {
          ...state,
          _msg: action.message,
          _allDetails: action.listview,
        };
      case PENDINGISSUSTONEORDERS:
        return {
          ...state,
          _msg: action.message,
          _pendingStonIssueOrders: action.listview,
        };
      case RETURNCUSTFINANCERATIO:
        return {
          ...state,
          _msg: action.message,
          _returnRatio: action.listview,
        };
      case TOTALTURNOVER:
        return {
          ...state,
          _msg: action.message,
          _totalTurnOver: action.listview,
        };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;