/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Avatar,
  Divider,
  Grid,
  TextField,
  makeStyles,
  InputLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPlatformAcc } from "../../redux";
import { config } from "../../Constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  createNewDetails,
  updateFinanceCustomerDetails,
  updateFinanceReturnsDetails,
} from "./_finance_Action";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const REACT_APP_PATH = config.url.PRODUCT_IMG_PATH;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
  heading: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#546e7a",
  },
  accodionRoot: {
    backgroundColor: "#f5f7f9",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  AccordionDetails: {
    display: "block",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
})); /*  */

const SellOrderFinanceDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let _editData =
    useSelector((state) => state.orderFinance._editedDetails) || "";
  let _pltformOrder_Details =
    useSelector((state) => state.orderBook._get_PltformOrder_Details) || {};
  let _productListOrderWise =
    useSelector((state) => state.orderFinance._productListOrderWise) || [];
  let _financeDetailsProductWise =
    useSelector((state) => state.orderFinance._financeDetailsProductWise) || [];
  let _platfromAccountList =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];
  /* eslint no-unused-vars : "off" */
  let plformOrderNo = { id: "", platformOrderNo: "" };
  if (Object.keys(_pltformOrder_Details).length > 0) {
    plformOrderNo = {
      id: _pltformOrder_Details.id,
      platformOrderNo: _pltformOrder_Details.platformOrderNo,
    };
  }

  useEffect(() => {
    dispatch(fetchAllPlatformAcc());
    // eslint-disable-next-line
  }, []);

  let defultPlatform = (
    <option key="" value="000e0">
      Select Platform
    </option>
  );
  let platformAccountRender = [];
  if (_platfromAccountList) {
    platformAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName}
        </option>
      );
    });
  }
  let defultEmail = (
    <option key="" value="000e0">
      Select Id
    </option>
  );
  let platformEmailAccountRender = [];
  if (_platfromAccountList) {
    platformEmailAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.accountEmailId}
        </option>
      );
    });
  }

  var buttonText = "Save";
  if (_financeDetailsProductWise.length > 0) {
    buttonText = "Update";
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var singleProductOrder = {
    //USER TEMPLETE
    subOrderid: "",
    productImage: null,
    categoryname: "",
    designo: "",
    isNewDesign: true,
    quantity: 1,
    note: "",
    transid: "",
    soldprice: 0,
    paypalfee: 0,
    partyfee: 0,
    taxfee: 0,
    adsfee: 0,
    returnmoney: 0,
    revisepaypalfees: 0,
    revisepartyfees: 0,
    revisetax: 0,
    reviseadsfees: 0,
    isproductreturn: "0",
  };
  var singleProductReturns = {
    returnmoney: 0,
    revisepaypalfees: 0,
    revisepartyfees: 0,
    revisetax: 0,
    reviseadsfees: 0,
    isproductreturn: "0",
    tbluid: 0,
  };

  let productList = [singleProductOrder];
  let productListRetuns = [singleProductReturns];
  if (_productListOrderWise.length > 0) {
    /*eslint array-callback-return: "off"*/
    _productListOrderWise.map((obj, i) => {
      productList[i] = {
        subOrderid: obj.id,
        productImage: obj.productImage,
        tbluid: 0,
        categoryname: obj.categoryName,
        designo: obj.productDesignNo,
        isNewDesign: Boolean(obj.isNewDesign),
        quantity: obj.quntity,
        transid: "",
        soldprice: 0,
        paypalfee: 0,
        partyfee: 0,
        taxfee: 0,
        adsfee: 0,
        note: "",
        netPrice: 0,
        returnmoney: 0,
        revisepaypalfees: 0,
        revisepartyfees: 0,
        revisetax: 0,
        reviseadsfees: 0,
        isproductreturn: "0",
      };
      productListRetuns[i] = {
        tbluid: 0,
        returnmoney: 0,
        revisepaypalfees: 0,
        revisepartyfees: 0,
        revisetax: 0,
        reviseadsfees: 0,
        isproductreturn: "0",
      };

      _financeDetailsProductWise.map((financeObj, jj) => {
        if (obj.id == financeObj.subOrderid) {
          productList[i].tbluid = financeObj.id;
          productList[i].transid = financeObj.transcationId;
          productList[i].soldprice = financeObj.soldPrice;
          productList[i].paypalfee = financeObj.paypalFees;
          productList[i].partyfee = financeObj.partyFees;
          productList[i].taxfee = financeObj.tax;
          productList[i].adsfee = financeObj.adsFees;
          productList[i].netPrice = financeObj.netPrice || 0; // financeObj.soldPrice //financeObj.netPrice

          productList[i].note = financeObj.note;
          productList[i].returnmoney = financeObj.returnMoney;
          productList[i].revisepaypalfees = financeObj.revisePaypalFees;
          productList[i].revisepartyfees = financeObj.revisePartyFees;
          productList[i].revisetax = financeObj.reviseTax;
          productList[i].reviseadsfees = financeObj.reviseAdsFees;
          productList[
            i
          ].isproductreturn = financeObj.isProductReturn.toString();
          productListRetuns[i].tbluid = financeObj.id;
          productListRetuns[i].returnmoney = financeObj.returnMoney;
          productListRetuns[i].revisepaypalfees = financeObj.revisePaypalFees;
          productListRetuns[i].revisepartyfees = financeObj.revisePartyFees;
          productListRetuns[i].revisetax = financeObj.reviseTax;
          productListRetuns[i].reviseadsfees = financeObj.reviseAdsFees;
          productListRetuns[
            i
          ].isproductreturn = financeObj.isProductReturn.toString();
        }
      });
    });
  }

  const [inputList, setInputList] = useState(productList || []); // [singleProductOrder]|| );
  const [inputListReturns, setInputListReturns] = useState(
    productListRetuns || []
  ); // [singleProductOrder]|| );

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value || "";
    setInputList(list);
    const listReturns = [...inputListReturns];
    listReturns[index][name] = value || "";
    setInputListReturns(listReturns);
  };
  const backBtnClick = () => {
    navigate("/app/order_finance", { replace: false });
  };
  const onPriceAndFeeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    if (name == "soldprice") {
      list[index]["netPrice"] =
        value -
        Number(list[index]["paypalfee"]) -
        Number(list[index]["partyfee"]) -
        Number(list[index]["taxfee"]) -
        Number(list[index]["adsfee"]);
    } else if (name == "paypalfee") {
      list[index]["netPrice"] =
        Number(list[index]["soldprice"]) -
        Number(list[index]["partyfee"]) -
        Number(list[index]["taxfee"]) -
        Number(list[index]["adsfee"]) -
        value;
    } else if (name == "partyfee") {
      list[index]["netPrice"] =
        Number(list[index]["soldprice"]) -
        Number(list[index]["paypalfee"]) -
        Number(list[index]["taxfee"]) -
        Number(list[index]["adsfee"]) -
        value;
    } else if (name == "taxfee") {
      list[index]["netPrice"] =
        Number(list[index]["soldprice"]) -
        Number(list[index]["partyfee"]) -
        Number(list[index]["paypalfee"]) -
        Number(list[index]["adsfee"]) -
        value;
    } else if (name == "adsfee") {
      list[index]["netPrice"] =
        Number(list[index]["soldprice"]) -
        Number(list[index]["partyfee"]) -
        Number(list[index]["taxfee"]) -
        Number(list[index]["paypalfee"]) -
        value;
    }

    list[index][name] = value || "";
    setInputList(list);
  };

  let newProductBoxRender = "";
  newProductBoxRender = inputList.map((x, i) => {
    return (
      <div key={i}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Product Image :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="icon-button-photo" size="small">
              <Avatar
                className={classes.avatar}
                src={REACT_APP_PATH + x.productImage}
              />
            </InputLabel>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Category Name :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Category Name"
              name="categoryname"
              fullWidth
              value={x.categoryname || ""}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Design Details :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isNewDesign"
                  checked={x.isNewDesign}
                  size="small"
                  color="primary"
                  disabled
                />
              }
              label="Is new design?"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Design No"
              name="designo"
              fullWidth
              value={x.designo || ""}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Quantity :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Quantity"
              name="quantity"
              fullWidth
              type="number"
              variant="outlined"
              value={x.quantity || 1}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Sold Price :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Sold Price"
              name="soldprice"
              fullWidth
              onChange={(e) => onPriceAndFeeChange(e, i)}
              value={x.soldprice}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Extra Fees :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Paypal Fee"
              name="paypalfee"
              fullWidth
              onChange={(e) => onPriceAndFeeChange(e, i)}
              value={x.paypalfee}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Party Fee"
              name="partyfee"
              fullWidth
              onChange={(e) => onPriceAndFeeChange(e, i)}
              value={x.partyfee}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            ></Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Tax Fee"
              name="taxfee"
              fullWidth
              onChange={(e) => onPriceAndFeeChange(e, i)}
              value={x.taxfee}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Ads Fee"
              name="adsfee"
              fullWidth
              onChange={(e) => onPriceAndFeeChange(e, i)}
              value={x.adsfee}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Net Price :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Net Price"
              name="netprice"
              fullWidth
              onChange={e => handleInputChange(e, i)}
              value={x.netPrice || 0}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Transaction Id :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Transaction Id"
              name="transid"
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.transid}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Notes :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Notes"
              name="note"
              fullWidth
              multiline
              rows={5}
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
        </Grid>
        &ensp;&ensp;
        <Accordion square>
          <AccordionSummary
            className={classes.accodionRoot}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Returns Details</Typography>
          </AccordionSummary>
          &ensp;
          <AccordionDetails className={classes.AccordionDetails}>
            <Formik
              initialValues=""
              validationSchema={Yup.object().shape({})}
              onSubmit={() => {
                dispatch(
                  updateFinanceReturnsDetails(
                    JSON.stringify(inputListReturns),
                    i,
                    navigate
                  )
                );
              }}
            >
              {({ errors, handleSubmit }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Return Money to buyer :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Return Money to buyer"
                        name="returnmoney"
                        fullWidth
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.returnmoney}
                        variant="outlined"
                        required
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Extra Charges :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Revise Paypal Fee "
                        name="revisepaypalfees"
                        fullWidth
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.revisepaypalfees}
                        variant="outlined"
                        required
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Revise Party Fee"
                        name="revisepartyfees"
                        fullWidth
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.revisepartyfees}
                        variant="outlined"
                        required
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      ></Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Revise Tax Fee"
                        name="revisetax"
                        fullWidth
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.revisetax}
                        variant="outlined"
                        required
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Revise Ads Fee"
                        name="reviseadsfees"
                        fullWidth
                        onChange={(e) => handleInputChange(e, i)}
                        value={x.reviseadsfees}
                        variant="outlined"
                        required
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Product Return from Client? :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <RadioGroup
                          row
                          label="Is Return"
                          aria-label="Return"
                          name="isproductreturn"
                          value={x.isproductreturn}
                          onChange={(e) => handleInputChange(e, i)}
                          required
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid md={12} xs={12} item>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.buttonMargin}
                        type="submit"
                        disabled={x.isproductreturn === ""}
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                </form>
              )}
            </Formik>
          </AccordionDetails>
        </Accordion>
        &ensp;&ensp;
        {inputList.length !== 1 && inputList.length - 1 !== i && <Divider />}
      </div>
    );
  });

  const validate = () => {
    let isError = false;
    // const errorList = { ...formErrors }

    // if (values.platformName == '' || values.platformName == 0) {
    //     isError = true;
    //     errorList["platformName"] = "Please select platform name"
    // }

    return isError;
  };

  return (
    <>
      {/* <Page
                title="Add Stock"
            > */}
      {/* <ToastNotify /> */}
      <Container maxWidth="xl">
        <Formik
          initialValues=""
          validationSchema={Yup.object().shape({
            // qty: Yup.string().required('Quantity name is required!'),
            // price: Yup.string().required('price name is required!'),
          })}
          onSubmit={(values, { resetForm }) => {
            let err = validate();
            if (!err) {
              if (buttonText == "Update") {
                dispatch(
                  updateFinanceCustomerDetails(
                    _editData.id,
                    inputList,
                    navigate
                  )
                );
              } else {
                dispatch(createNewDetails(_editData.id, inputList, navigate));
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Finance Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Order date :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <KeyboardDatePicker
                            disableToolbar
                            disabled
                            name="orderDate"
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="Order date"
                            value={_editData.orderDate || new Date()}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Platform Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformAccountId"
                        fullWidth
                        label="Platform Name"
                        required
                        select
                        disabled
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={_editData.accountId}
                      >
                        {defultPlatform}
                        {platformAccountRender}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="platformAccountId"
                        fullWidth
                        label="Email ID"
                        required
                        select
                        disabled
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={_editData.accountId}
                      >
                        {defultEmail}
                        {platformEmailAccountRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Order No"
                        name="platformOrderNoDropDown"
                        fullWidth
                        value={_editData.platformOrderNo}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  {newProductBoxRender}
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backBtnClick}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.buttonMargin}
                      type="submit"
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default SellOrderFinanceDetails;
