import React, { useEffect, useState } from "react";
import { Container, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import PlatformList from "./PlatformList";
import { useSelector, useDispatch } from "react-redux";
// import { fetchAllSupplierDetails, clearEditedDetails } from './_supplier_Action'
import { fetchAllPlatformAcc } from "../../redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Platform = () => {
  const classes = useStyles();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const dispatch = useDispatch();
  let allFetchData =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];

  useEffect(() => {
    dispatch(fetchAllPlatformAcc());
    // eslint-disable-next-line
  }, []);

  let viewComponent;
  viewComponent = <PlatformList TableData={allFetchData} filterFn={filterFn} />;

  return (
    <Page className={classes.root} title="Platform Account">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />
        <Box mt={3}>{viewComponent}</Box>
      </Container>
    </Page>
  );
};

export default Platform;
