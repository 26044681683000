import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Typography,
  Collapse,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import { ChevronDown, ChevronUp } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
    spanElement: {},
  },
}));
function Row(props) {
  const { row } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let arrays = JSON.parse(row.productDetails) || [];
  var collapseDetails = [];
  for (let item of arrays) {
    collapseDetails.push(item);
  }
  return (
    <React.Fragment>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell padding="checkbox">
          <div>
            <IconButton aria-label="expand row" size="small">
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{row.productDefinationId}</TableCell>
        <TableCell>{row.productTotalQty}</TableCell>
        <TableCell>{row.productWeight.toFixed(2)}</TableCell>
        <TableCell>{row.itransactionDoneBy}</TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box>
                {collapseDetails.map((key, i) => {
                  var replaceCurlybracess = JSON.stringify(key[0]).replace(
                    "{",
                    "["
                  );
                  var replaceCurlybracessEnd = replaceCurlybracess.replace(
                    "}",
                    "]"
                  );
                  var replaceemptybracess = replaceCurlybracessEnd.replace(
                    "[",
                    ""
                  );
                  var replaceemptybracessEnd = replaceemptybracess.replace(
                    "]",
                    ""
                  );
                  return (
                    <div key={i}>
                      <span className={classes.spanElement}>
                        {replaceemptybracessEnd}
                      </span>
                    </div>
                  );
                })}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const StockDetailsList = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();
  /* eslint no-unused-vars : "off" */

  const headCells = [
    { id: "collpase", label: "" ,disableSorting: true},
    { id: "name", label: "Product Name" },
    { id: "qty", label: "Qty" },
    { id: "weight", label: "Weight" },
    { id: "price", label: "Average Price" },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData, headCells, filterFn);

  console.log("--",TableData);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />
            {TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <Row
                    key={row.stockProductid}
                    row={row}
                    rowProductStockInfo={TableData}
                  />
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

StockDetailsList.propTypes = {
  className: PropTypes.string,
};

export default StockDetailsList;
