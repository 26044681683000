import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  Divider,
  Button,
  Dialog,
  Grid,
  TextField,
} from "@material-ui/core";
/*eslint no-useless-rename: "off"*/
import { ChevronDown, ChevronUp } from "react-feather";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createNewDetailsPost } from "../../utils/actionMethod";
import { fetchAllDetails } from "../courierCompany/_courierCompany_Action";
import { Formik } from "formik";
import * as Yup from "yup";
import { issueMakerDispatcher } from "./_issue_Returns_Action";

const useStyles = makeStyles((theme) => ({
  root: {},
  issueButton: {
    margin: "auto",
    display: "flex",
    marginTop: "20px",
  },
}));

const headCells = [
  { id: "expand", label: "" },
  { id: "orderDate", label: "Order Date" },
  { id: "customerName", label: "Customer Name" },
  { id: "platFormName", label: "PlateForm Name" },
  { id: "platFormEmail", label: "PlatForm Email" },
  { id: "edit", label: "Depart Courier", disableSorting: true },
];
function DetailsArrayloop(props) {
  const { row } = props;
  let x = 0;
  return (
    <>
      {
        row.map((ele, i) => {

          return <div key={i}>
            <Box sx={{ margin: 1 }} key={i}>
              <Typography
                component="h5"
                variant="h5"
                className="typography_margin"
              >
                {row.length > 1 ? `Product Details : ${++x}` : `Product Details`}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                maxWidth={1000}
                justifyContent="space-between"
              >
                <p>
                  <b>
                    Design Name :-
                    <b>
                      {ele.isNewDesign == 1 ? "New Design" : " " + ele.prod_Name}
                    </b>
                  </b>
                </p>
                <p>
                  <b>
                    makerSku :-<b>{" " + ele.makerSku}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Manufacturer Name :- <b>{" " + ele.manufacturerName}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Manufacturer Email :- <b>{" " + ele.manufacturerEmail}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Product Qty :- <b>{" " + ele.productQty}</b>
                  </b>
                </p>
              </Box>
            </Box>
            <Box marginBottom="15px" key={i + 1}>
              <Typography component="h5" variant="h5" className="typography_margin">
                Requirements
              </Typography>
              {ele.diamondDetails.map((item, i) => {
                return item.dimondShape === undefined ? (
                  "No Diamond Require"
                ) : (
                  <div key={i}>
                    <h5>{item.dimondType}</h5>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      maxWidth={600}
                    >
                      <span>Available:- {item.availableOnStockQty}</span>
                      <span>Require:- {item.requireQty}</span>
                      <span>Shape:- {item.dimondShape}</span>
                      <span>Size:- {item.dimondSize}</span>
                    </Box>
                    <Divider className="divider_margin" />
                  </div>
                );
              })}
            </Box>
            <br />
          </div>

        })

      }

    </>
  );
}
function DetailsArray(props) {
  const { row } = props;
  return <>
    {
      row.map((ele, i) => {

        return <div key={i}>

          <Box sx={{ margin: 1 }}>
            <Typography
              component="h5"
              variant="h5"
              className="typography_margin"
            >
              Details
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              maxWidth={1000}
              justifyContent="space-between"
            >
              <p>
                <b>
                  Design Name :-
                  <b>
                    {ele.isNewDesign == 1 ? " New Design" : " " + ele.prod_Name}
                  </b>
                </b>
              </p>
              <p>
                <b>
                  makerSku :-<b>{" " + ele.makerSku}</b>
                </b>
              </p>
              <p>
                <b>
                  Manufacturer Name :-<b>{" " + ele.manufacturerName}</b>
                </b>
              </p>
              <p>
                <b>
                  Manufacturer Email :-<b>{" " + ele.manufacturerEmail}</b>
                </b>
              </p>
              <p>
                <b>
                  Product Qty :-<b>{" " + ele.productQty}</b>
                </b>
              </p>
            </Box>
          </Box>
          <Box marginBottom="15px">
            <Typography component="h5" variant="h5" className="typography_margin">
              No Diamond Require
            </Typography>
          </Box>
          <br />
        </div>
      })
    }
  </>
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const array = [];

  row.productList.map((ele) => {
    if (ele?.diamondDetails?.length >= 1) {
      ele.diamondDetails.map((e) => {
        if (row.order_uid == e.order_uid) {
          array.push(e);
        }
      })
    }
  })

  const openDepartCourier = (row) => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell padding="checkbox">
          <div onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{moment(row.orderDate).format("DD-MM-YYYY")}</TableCell>
        <TableCell>{row.customerName}</TableCell>
        <TableCell>
          {row.platFormName}
        </TableCell>
        <TableCell>{row.platFormEmail}</TableCell>
        <TableCell width="20%">
          <Button
            color="primary"
            variant="contained"
            onClick={() => openDepartCourier(row)}
          >
            depart Courier
          </Button>
        </TableCell>
        <SimpleDialog
          key={1}
          open={openDialog}
          onClose={handleClose}
          row={row}
        />
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>

          <Collapse in={open} timeout="auto" unmountOnExit>
            {array.length >= 1 ?
              (
                <DetailsArrayloop row={row.productList} />
              ) : (
                <DetailsArray row={row.productList} />
              )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function SimpleDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("")
  const { onClose, open, row } = props;

  let allFetchData =
    useSelector((state) => state.couierCompany._allDetails) || [];
  useEffect(() => {
    dispatch(fetchAllDetails());
  }, []);
  var initialdata = {
    courierName: "",
    trackingNo: "",
  };

  let defultOption = (
    <option key="0" value="0">
      Select Courier
    </option>
  );
  let dropdownRender = [];
  if (allFetchData) {
    dropdownRender = allFetchData.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.courierCompanyName}
        </option>
      );
    });
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Formik
        initialValues={initialdata}
        validationSchema={Yup.object().shape({
          courierName: Yup.string().required("Name is required!"),
          trackingNo: Yup.number()
            .required("Tracking Number is required!")
            .integer("Must be a valid phone number"),
        })}
        onSubmit={async (values, { resetForm }) => {
          let data = {
            order_uid: row.order_uid,
            courierId: values.courierName,
            courierTrackingNo: values.trackingNo,
            orderStatus: "DEPART",
          };
          let result = await createNewDetailsPost(
            "/api/manufa-to-courier-depart",
            data
          );
          dispatch(issueMakerDispatcher([], navigate, false, "READYTOCOURIER"));
          if (result.data.msg) {
            setErrorMsg(result.data.msg);
            setTimeout(() => {
              setErrorMsg("")
            }, 5000);
          }
          if (!result.status) {
            dispatch(
              issueMakerDispatcher([], navigate, false, "READYTOCOURIER")
            );
          }else if(result.data.status){
            handleClose()
          }
          resetForm({ values: "" });
        }
      }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ margin: 20 }} key={1}>
              <Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Courier Name :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      name="courierName"
                      fullWidth
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.courierName && errors.courierName)}
                      helperText={touched.courierName && errors.courierName}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      value={values.courierName}
                    >
                      {defultOption}
                      {dropdownRender}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      Tracking No. :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.trackingNo && errors.trackingNo)}
                      fullWidth
                      helperText={touched.trackingNo && errors.trackingNo}
                      label="Tracking No"
                      name="trackingNo"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.trackingNo}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.issueButton}
                >
                  Depart Courier
                </Button>
                <p style={{ color: "red" }}>{errorMsg}</p>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
const InCourier = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    selectedAllIds,
  } = commanTable(TableData, headCells, filterFn);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />

            {Object.keys(TableData).length !== 0 &&
              TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <Row
                    key={row.order_uid}
                    row={row}
                  />
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      {TableData.length != undefined && TableData.length > 0 ? (
        <TblPagination />
      ) : (
        ""
      )}
    </Card>
  );
};

InCourier.propTypes = {
  className: PropTypes.string,
};

export default InCourier;
