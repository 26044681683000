import { LOGIN_USER, SET_CURRENT_USER, LOGOUT_USER } from "../action/userType";
const initialState = {
  isLoggedIn: false,
  email: "",
  user_id: "",
  msg: "",
  userDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        msg: action.payload,
        isLoggedIn: action.isLogin,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        userDetails: action.payload,
        isLoggedIn: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: action.isLog,
      };
    default:
      return state;
  }
};

export default userReducer;
