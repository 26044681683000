/*eslint eqeqeq: "off"*/
import {
     FETCH_OVER_DUE_ORDERS
      } from "./_overDue_Type";
      const initialState = {
        _msg: "",
        _allDetails: [],
        _returnDetails: [],
      };
      
      const overDueReducer = (state = initialState, action) => {
        switch (action.type) {
          case FETCH_OVER_DUE_ORDERS:
            return {
              ...state,
              _msg: action.message,
              _allDetails: action.listview,
            };
          default:
            return state;
        }
      };
      
      export default overDueReducer;