
import {
  FETCH_ORDER_DETAILS_LIST,
  FETCH_ID_WISE_RETURN_ORDER
} from "./_order_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");
export const fetchAllDetails = (id,dateRange,tab) => {
  if (tab == undefined) {
    tab = "InProfit";
  }
  if(tab=="InProfit")
  return function(dispatch) {
    var OPTIONS = {
      url: id&&dateRange ? REACT_APP_URL + `/api/get-subOrder-finance-profit?platFormId=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}` : !id&&dateRange ? REACT_APP_URL +`/api/get-subOrder-finance-profit?startDate=${dateRange[0]}&endDate=${dateRange[1]}` : id && !dateRange ?  REACT_APP_URL + `/api/get-subOrder-finance-profit?platFormId=${id}` : REACT_APP_URL + `/api/get-subOrder-finance-profit`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data;
          dispatch(getAllDetails(result));
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
        toast.error(err.response.data.message);
      });
  };
  else if(tab == "InReturn"){
    return function(dispatch){
      var OPTIONS = {
        url: id&&dateRange ? REACT_APP_URL + `/api/get-id-wise-return?platFormId=${id}&startDate=${dateRange[0]}&endDate=${dateRange[1]}` : !id&&dateRange ? REACT_APP_URL +`/api/get-id-wise-return?startDate=${dateRange[0]}&endDate=${dateRange[1]}` : id && !dateRange ?  REACT_APP_URL + `/api/get-id-wise-return?platFormId=${id}` : REACT_APP_URL + `/api/get-id-wise-return`,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      axios(OPTIONS)
        .then((res) => {
          if (res.status == 200) {
            let result =res.data.data;
            dispatch(getreturnDetails(result));
          } else {
            toast("Soming Wrong.....!");
          }
        })
        .catch((err) => {
          dispatch(getreturnDetails([]));
          toast.error(err.response.data.message);
        });
    }
  }
};

export const getreturnDetails = (data) => {
  return {
    type: FETCH_ID_WISE_RETURN_ORDER,
    listview: data,
  };
};
export const getAllDetails = (data) => {
  return {
    type: FETCH_ORDER_DETAILS_LIST,
    listview: data,
  };
};