export const GET_ALL_CUSTOMER_UNFILLED_FINANCE_ORDERLIST ='GET_ALL_CUSTOMER_UNFILLED_FINANCE_ORDERLIST'
export const ADD_FIN_CUST_DETAILS = 'ADD_FIN_CUST_DETAILS';
export const EDIT_FIN_CUST_DETAILS = 'EDIT_FIN_CUST_DETAILS';
export const UPDATE_FIN_CUST_DETAILS = 'UPDATE_FIN_CUST_DETAILS';
export const DELETE_FIN_CUST_DETAILS = 'DELETE_FIN_CUST_DETAILS';
export const GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE = 'GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE';
export const GET_FINANCE_DETAILS_PRODUCTWISE = 'GET_FINANCE_DETAILS_PRODUCTWISE';
//////// Manufacturer ////
export const GET_ALL_MANU_UNFILLED_FINANCE_ORDERLIST ='GET_ALL_MANU_UNFILLED_FINANCE_ORDERLIST'
export const ADD_FIN_MANU_DETAILS = 'ADD_FIN_MANU_DETAILS';
export const GET_MANU_FINANCE_DETAILS_PRODUCTWISE = 'GET_MANU_FINANCE_DETAILS_PRODUCTWISE';
