/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Avatar,
  TextField,
  makeStyles,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

// import Popup from 'src/components/Popup';

import { createNewDetails, updateDetails } from "./_product_Action";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../category/_category_Action";
import { fetchAllStoneShape } from "../stoneDetails/_stoneShape_Action";
import { config } from "../../Constants";
import imageCompression from "browser-image-compression";
import ToastNotify from "../../components/ToastNotify";

let REACT_APP_PATH = config.url.PRODUCT_IMG_PATH;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  inputFileLabel: {
    padding: "8px 10px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffff",
    border: "1px solid rgb(185 189 191)",
    borderRadius: "4px",
    cursor: "pointer",
  },
  inputFile: {
    margin: "auto",
    cursor: "pointer",
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const ProductCreate = ({
  className,
  isShowBackBtn,
  setOpenPopup,
  isPopup,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.product._editedDetails) || "";
  let _category =
    useSelector((state) => state.category._onlyProductCategory) || [];
  let _editProductStoneDetails =
    useSelector((state) => state.product._editProductStoneDetails) || {};
  let _stoneShapeList =
    useSelector((state) => state.stoneShape._allDetails) || [];

  const [cateID, setCateID] = useState(_editData.categoryId);
  const [showBack, setShowBack] = useState(true);
  const [cateIDError, setCateIDError] = useState("");

  useEffect(() => {
    if (isShowBackBtn === false) {
      setShowBack(false);
    }
    if (_stoneShapeList.length <= 0) dispatch(fetchAllStoneShape());

    if (_category.length <= 0) dispatch(fetchAllCategory(true)); /// only product, no row material, Just pass true
    // eslint-disable-next-line
  }, []);
  const backBtnClick = () => {
    navigate("/app/products", { replace: false });
  };
  const onCategoryChange = (e) => {
    setCateID(e.target.value);
  };

  let defultOption = (
    <option key="" value="000e0">
      Select Category
    </option>
  );
  let dropdownRender = [];

  if (_category) {
    dropdownRender = _category.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.categoryName}
        </option>
      );
    });
  }
  //////////////////////////
  let defultShpeOption = (
    <option key="" value="000e0">
      Select Shape
    </option>
  );
  let shapeDDRender = [];
  if (_stoneShapeList) {
    shapeDDRender = _stoneShapeList.map((option) => {
      return (
        <option key={option.id} value={option.shapeName}>
          {option.shapeName}
        </option>
      );
    });
  }

  var initialdata = {
    categoryId: "",
    description: "",
    designno: "",
    productDbImage: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      categoryId: _editData.categoryId,
      description: _editData.productDescription,
      designno: _editData.productDesignNo,
      productDbImage: REACT_APP_PATH + _editData.productImage,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      categoryId: "",
      description: "",
      designno: "",
      productDbImage: "",
    };
    buttonText = "Create";
  }

  const validate = () => {
    let isError = false;

    var data = parseInt(cateID);
    if (data <= 0 || isNaN(data)) {
      isError = true;
      setCateIDError("Please select product category");
    }
    return isError;
  };

  const [previewImage, setPreviewImage] = useState(
    REACT_APP_PATH + _editData.productImage || null
  );
  const [uploadImage, setUploadImage] = useState(null);

  //////////////////////////////////////////////////////////////////////////////

  var mainStoneAttribute = {
    shape: "000e0",
    size: "",
    quantity: "",
  };
  var sideStoneAttribute = {
    shape: "000e0",
    size: "",
    quantity: "",
  };
  let mainStoneList = [mainStoneAttribute] || [];
  let sideStoneList = [sideStoneAttribute] || [];

  if (Object.keys(_editProductStoneDetails).length > 0) {
    // if (_editProductStoneDetails._mainStoneList.length > 0) {
    mainStoneList = _editProductStoneDetails._mainStoneList;
    // }

    //  if (_editProductStoneDetails._sideStoneList.length > 0) {
    sideStoneList = _editProductStoneDetails._sideStoneList;
    //  }
  }

  const [mainStoneInputList, setMainStoneInputList] = useState(mainStoneList);
  const [sideStoneInputList, setSideStoneInputList] = useState(sideStoneList);

  const [errorMainStoneShape, setErrorMainStoneShape] = useState([]);
  const [errorSideStoneShape, setErrorSideStoneShape] = useState([]);

  const [removeStoneList, setRemoveStoneList] = useState([]);

  let sizeErrorMessagae = "Enter only digit or like this format 1.5x7.8";
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name == "size") {
      /*eslint no-useless-escape: "off"*/
      if (!value.match(/^[\d\.\x]+$/)) {
        if (value.length != 0) {
          const errorList = [...errorMainStoneShape];
          errorList[index] = sizeErrorMessagae;
          setErrorMainStoneShape(errorList);

          return;
        }
      }
    }
    setErrorMainStoneShape([]);

    const list = [...mainStoneInputList];
    list[index][name] = value || "";
    setMainStoneInputList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...mainStoneInputList];

    let removeElement = list[index];
    setRemoveStoneList([removeElement, ...removeStoneList]);
    list.splice(index, 1);
    setMainStoneInputList(list);
  };
  const handleAddClick = () => {
    setMainStoneInputList([mainStoneAttribute, ...mainStoneInputList]);
    setErrorMainStoneShape([]);
  };
  ////////////////////////////////////////Side
  const handleSideStoneChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "size") {
      if (!value.match(/^[\d\.\x]+$/)) {
        if (value.length != 0) {
          const errorList = [...errorSideStoneShape];
          errorList[index] = sizeErrorMessagae;
          setErrorSideStoneShape(errorList);

          return;
        }
      }
    }
    setErrorSideStoneShape([]);

    const list = [...sideStoneInputList];
    list[index][name] = value || "";
    setSideStoneInputList(list);
  };
  const handleSideStoneRemoveClick = (index) => {
    const list = [...sideStoneInputList];
    let removeElement = list[index];
    setRemoveStoneList([removeElement, ...removeStoneList]);

    list.splice(index, 1);
    setSideStoneInputList(list);
  };
  const handleSideStoneAddClick = () => {
    setSideStoneInputList([sideStoneAttribute, ...sideStoneInputList]);
    setErrorSideStoneShape([]);
  };

  let mainStoneTextboxRender = [];
  mainStoneTextboxRender =
    mainStoneInputList != undefined &&
    mainStoneInputList.map((x, i) => {
      return (
        <Grid container spacing={3} alignItems="center" key={i}>
          <Grid item xs sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Main Stone {mainStoneInputList.length - i}:{" "}
            </Typography>
          </Grid>
          <Grid item md={2} xs>
            <TextField
              fullWidth
              label="Stone Shape"
              name="shape"
              variant="outlined"
              onChange={(e) => {
                handleInputChange(e, i);
              }}
              select
              SelectProps={{ native: true }}
              value={x.shape}
            >
              {defultShpeOption}
              {shapeDDRender}
            </TextField>
          </Grid>
          <Grid item md={2} xs>
            <TextField
              label="Size"
              name="size"
              variant="outlined"
              onChange={(e) => handleInputChange(e, i)}
              value={x.size}
              error={Boolean(errorMainStoneShape[i])}
              helperText={errorMainStoneShape[i]}
              onBlur={(e) => setErrorMainStoneShape([])}
            />
          </Grid>
          <Grid item md={2} xs>
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              variant="outlined"
              onChange={(e) => handleInputChange(e, i)}
              value={x.quantity}
            />
          </Grid>

          <Box display="flex" justifyContent="flex-end" p={2}>
            {0 === i && (
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleAddClick}
              >
                <AddIcon />
              </Button>
            )}
            &ensp;&ensp;
            {mainStoneInputList.length !== 1 && (
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleRemoveClick(i)}
              >
                <RemoveIcon />
              </Button>
            )}
          </Box>
        </Grid>
        /* {mainStoneInputList.length !== 1 && mainStoneInputList.length - 1 !== i && <Divider />} */
      );
    });

  //// SIDE STONE

  let sideStoneTextboxRender = [];
  sideStoneTextboxRender =
    sideStoneInputList != undefined &&
    sideStoneInputList.map((x, i) => {
      return (
        <Grid container spacing={3} key={i} alignItems="center">
          <Grid item xs sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Side Stone {sideStoneInputList.length - i}:{" "}
            </Typography>
          </Grid>
          <Grid item md={2} xs>
            <TextField
              fullWidth
              label="Stone Shape"
              name="shape"
              variant="outlined"
              onChange={(e) => {
                handleSideStoneChange(e, i);
              }}
              select
              SelectProps={{ native: true }}
              value={x.shape}
            >
              {defultShpeOption}
              {shapeDDRender}
            </TextField>
          </Grid>
          <Grid item md={2} xs>
            <TextField
              label="Size"
              name="size"
              variant="outlined"
              onChange={(e) => handleSideStoneChange(e, i)}
              value={x.size}
              error={Boolean(errorSideStoneShape[i])}
              helperText={errorSideStoneShape[i]}
              onBlur={(e) => setErrorSideStoneShape([])}
            />
          </Grid>
          <Grid item md={2} xs>
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              variant="outlined"
              onChange={(e) => handleSideStoneChange(e, i)}
              value={x.quantity}
            />
          </Grid>

          <Box display="flex" justifyContent="flex-end" p={2}>
            {0 === i && (
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleSideStoneAddClick}
              >
                <AddIcon />
              </Button>
            )}
            &ensp;&ensp;
            {sideStoneInputList.length !== 1 && (
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleSideStoneRemoveClick(i)}
              >
                <RemoveIcon />
              </Button>
            )}
          </Box>
        </Grid>
        /* {sideStoneInputList.length !== 1 && sideStoneInputList.length - 1 !== i && <Divider />} */
      );
    });

  const handleThumbnailImage = async (event) => {
    if (event.target.files.length > 0) {
      const imageFile = event.target.files[0] || null;

      setPreviewImage(URL.createObjectURL(imageFile));

      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 400, // max 1980
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await setUploadImage(compressedFile);

        // await uploadToServer(compressedFile); // write your own logic
      } catch (error) {
        console.log(error);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Page title="Add Product Defination">
        <ToastNotify />
        <Container maxWidth="xl">
          <Formik
            initialValues={initialdata}
            validationSchema={Yup.object().shape({
              designno: Yup.string().required("Design Number is required!"),
            })}
            onSubmit={(values, { resetForm }) => {
              const err = validate();
              if (!err) {
                values.mainStoneInputList = mainStoneInputList;
                values.sideStoneInputList = sideStoneInputList;
                values.removeStoneList = removeStoneList;
                values.categoryId = cateID;
                values.productImage = uploadImage;

                if (buttonText === "Update" && _editData) {
                  dispatch(updateDetails(values, _editData.id, navigate));
                } else {
                  dispatch(createNewDetails(values, navigate));
                }
                setCateID("000e0");
                resetForm({ values: "" });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                autoComplete="off"
                //noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <Typography variant="h4" className={classes.cardHeader}>
                    Product Details
                  </Typography>

                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Product Image :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          htmlFor="icon-button-photo"
                          size="small"
                          className={classes.inputFileLabel}
                        >
                          <Avatar
                            className={classes.avatar}
                            src={previewImage}
                          />
                          <Input
                            className={classes.inputFile}
                            accept="image/*"
                            id="icon-button-photo"
                            onChange={handleThumbnailImage}
                            type="file"
                          />
                        </InputLabel>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Product Category :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(cateIDError)}
                          fullWidth
                          helperText={cateIDError}
                          label="Product Category"
                          name="categoryId"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            onCategoryChange(e);
                            setCateIDError("");
                          }}
                          required
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          value={cateID}
                        >
                          {defultOption}
                          {dropdownRender}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Design No :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.designno && errors.designno)}
                          fullWidth
                          helperText={touched.designno && errors.designno}
                          required
                          label="Design No"
                          name="designno"
                          onBlur={handleBlur}
                          value={values.designno}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        {mainStoneTextboxRender}
                        {sideStoneTextboxRender}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Note :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Note"
                          name="description"
                          multiline
                          rows="5"
                          fullWidth
                          onBlur={handleBlur}
                          value={values.description}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    {showBack ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={backBtnClick}
                      >
                        Back
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      //disabled={isSubmitting}
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
        {/* <Popup
                    title="Add Category"
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <CategoryCreate isPopup={openPopup} />
                </Popup>
                <Popup
                    title="Add Category Attribute"
                    openPopup={attributeOpenPopup}
                    setOpenPopup={setAttributeOpenPopup}
                >
                    <CategoryAttributeCreate isPopup={attributeOpenPopup} />
                </Popup> */}
      </Page>
    </>
  );
};

ProductCreate.propTypes = {
  className: PropTypes.string,
};

export default ProductCreate;
