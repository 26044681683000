import {
  ADD_NEW_STOCK__DETAILS,
  UPDATE_PRODUCT_STOCK_DETAILS,
  FIND_PRODUCT_BY_NAME_DESIGN,
  FETCH_ALL_PRODUCT_STOCK_ENTRY_LIST,
  EDIT_OLD_SINGLE_PRODUCT_STOCK_DETAILS,
  DELETE_PRODUCT_STOCK_ENTRY_DETAILS,
} from "./_product_Stock_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";
import { isArray } from "lodash";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const fetchAllProductStockEntry = (productsku, design) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-all-product-stock-details",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_ALL_PRODUCT_STOCK_ENTRY_LIST,
            listview: result,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_PRODUCT_STOCK_ENTRY_LIST,
          listview: [],
        });
      });
  };
};

export const findProductByNameAndDesign = (productsku, design) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-single-product?productsku=" +
        productsku +
        "&designno=" +
        design,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FIND_PRODUCT_BY_NAME_DESIGN,
            data: result,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FIND_PRODUCT_BY_NAME_DESIGN,
          data: [],
        });
      });
  };
};

export const createNewDetails = (values, navigate) => {
  let singleArray = [];
  singleArray[0] = {
    designName: values.designName,
    categoryId: values.categoryId,
    isRowMaterial: values.isRowMaterial,
    qty: values.qty,
    price: values.price,
    weight: values.weight,
    totalPrice: values.totalPrice,
    gstPer: values.gstPer,
  };

  let data = {
    productDetails: singleArray,
    attributeValue: [values.attributeValue],
    purchaseDate: values.stockIndate,
    supplierId: values.supplierId,
    payby: values.payby,
    dueDay: values.dueDay,
    totalInvoiceAmount: 0,
    isSingelStock: true,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-new-purchase-invoice-details",
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_NEW_STOCK__DETAILS,
            payload: result,
          });
          navigate("../product_stock", { replace: false });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_OLD_SINGLE_PRODUCT_STOCK_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (values, r_id, navigate) => {
  let singleArray = [];
  singleArray[0] = {
    designName: values.designName,
    categoryId: values.categoryId,
    isRowMaterial: values.isRowMaterial,
    qty: values.qty,
    price: values.price,
    weight: values.weight,
    totalPrice: values.totalPrice,
    gstPer: values.gstPer,
    transactionId: values.transactionId,
    stockId: values.stockId,
  };

  let data = {
    invoiceId: "",
    productDetails: singleArray,
    attributeValue: [values.attributeValue],
    purchaseDate: values.stockIndate,
    supplierId: values.supplierId,
    payby: values.payby,
    dueDay: values.dueDay,
    totalInvoiceAmount: 0,
    isSingelStock: true,
  };

  // updateData.stockid = r_id
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-old-purchase-invoice-details",
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          if (!isArray(result)) {
            // Because of sometime we get single object
            result[0] = result;
          }
          // let message = res.data.message
          dispatch({
            type: UPDATE_PRODUCT_STOCK_DETAILS,
            payload: result[0],
          });
          navigate("../product_stock", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const deleteSingleDetails = (id, stoackId) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/delete-singel-transaction-stock-details",
      method: "POST",
      data: { transactionId: id, stockid: stoackId },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_PRODUCT_STOCK_ENTRY_DETAILS,
            payload: id,
          });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};
