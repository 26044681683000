/*eslint eqeqeq: "off"*/
import {
  ADD_NEW_PURCHASE_DETAILS,
  FETCH_ALL_PURCHASE_INVOICE_LIST,
  FETCH_INVOICE_PRODUCT_ENTRY_LIST,
  GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
  EDIT_OLD_PURCHASE_INVOICE_DETAILS,
  UPDATE_PURCHASE_INVOICE_DETAILS,
  DELETE_PURCHASE_INVOICE_ENTRY_DETAILS,
} from "./_purchase_Type";
const initialState = {
  _editedDetails: "",
  _allDetails: [],
  _productEntry: [],
  _savePurchaseEntryValues: [],
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_PURCHASE_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
        _productEntry: [],
      };
    case EDIT_OLD_PURCHASE_INVOICE_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_PURCHASE_INVOICE_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _productEntry: [],
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case FETCH_ALL_PURCHASE_INVOICE_LIST:
      return {
        ...state,
        _editedDetails: "",
        _productEntry: [],
        _allDetails: action.listview,
      };
    case FETCH_INVOICE_PRODUCT_ENTRY_LIST:
      return {
        ...state,
        _productEntry: action.listview,
      };
    case GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES:
      return {
        ...state,
        _savePurchaseEntryValues: action.payload,
      };
    case DELETE_PURCHASE_INVOICE_ENTRY_DETAILS:
      return {
        ...state,
        _edituserDetails: "",
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };

    default:
      return state;
  }
};

export default purchaseReducer;
