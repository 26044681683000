/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon,
  Lock as LockIcon,
} from "react-feather";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import { editUserDetails, deleteUserDetails } from "../../redux";
import DeleteDialog from "src/components/DeleteDialog";
import PermissionModal from "./permissionModal";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const headCells = [
  { id: "fullName", label: "Employee Name" },
  { id: "email", label: "Email" },
  { id: "phoneNo", label: "Mobile Number" },
  { id: "rollname", label: "Roll Type" },
  { id: "lock", label: "Permission", disableSorting: true },
  { id: "edit", label: "Edit", disableSorting: true },
  { id: "delete", label: "Delete", disableSorting: true },
];

const UsersList = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [dialogopenpermission, setDialogOpenPermission] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState("");
  /* eslint no-unused-vars : "off" */
  const [value, setValue] = React.useState("");
  const [userId, setUserId] = React.useState("");

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    selectedAllIds,
  } = commanTable(TableData, headCells, filterFn, 1);

  const editDetails = (id, userdata) => {
    dispatch(editUserDetails(id, userdata));
    navigate("./edit", { replace: false });
  };
  const openDialog = (id) => {
    setDeleteRecord(id);
    setDialogOpen(!dialogopen);
  };
  const openDialogPermission = (id) => {
    setDialogOpenPermission(true);
    setUserId(id);
  };
  const confirmDialog = () => {
    dispatch(deleteUserDetails(deleteRecord));
  };
  const handleClose = () => {
    setDialogOpenPermission(false);
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />
            {TableData.length > 0 ? (
              <TableBody>
                {/* {TableData.slice(0, limit).map((row) => ( */}
                {recordsAfterPagingAndSorting().map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phoneNo}</TableCell>
                    <TableCell>{user.rollname}</TableCell>

                    <TableCell width="5%">
                      <IconButton size="small">
                        <LockIcon
                          onClick={() => openDialogPermission(user.id)}
                        />
                        {userId == user.id ? (
                          <PermissionModal
                            keepMounted
                            open={dialogopenpermission}
                            onClose={handleClose}
                            value={value}
                          />
                        ) : (
                          ""
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => editDetails(user.id, user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => openDialog(user.id)}
                      >
                        <DeleteIcon />
                        {deleteRecord == user.id ? (
                          <DeleteDialog
                            openDialog={openDialog}
                            confirmDialog={confirmDialog}
                            dialogopen={dialogopen}
                          />
                        ) : (
                          ""
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

UsersList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};

export default UsersList;
