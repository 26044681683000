/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";

const Toolbar = ({ className, setParentFilterFn, ...rest }) => {
  const navigate = useNavigate();
  // const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [filterFnDate, setFilterFnDate] = useState({
    fn: (items) => {
      return items;
    },
  });
  const handleDateChange = (e) => {
    let target = e.target;
    setFilterFnDate({
      fn: (items) => {
        if (target.value == "") {
          return items;
        } else {
          return items.filter((x) => x.transactionDate.includes(target.value));
        }
      },
    });
    setParentFilterFn(filterFnDate);
  };
  const AddIncomeExpenseformNavigate = () => {
    navigate("./create", { replace: false });
  };
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.transactionCategory
              .toLowerCase()
              .includes(target.value.toLowerCase())
          );
      },
    });
    setParentFilterFn(filterFn);
  };

  return (
    <div {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={AddIncomeExpenseformNavigate}
        >
          Add New Income & Expense
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search by Category"
                variant="outlined"
              />
              <TextField
                id="date"
                label="Transaction Date"
                type="date"
                // defaultValue={selectedDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleDateChange(e)}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
