import * as ACTION from "./_finance_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";
import {
  createNewDetailsPost,
  fetchDataMethod,
  updateDetailsPost,
} from "../../utils/actionMethod";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const getAllcustomerUnfilledfinanceOrderlist = () => {
  return async (dispatch) => {
    let result = await fetchDataMethod(
      "/api/get-allcustomer-unfilled-finance-orderlist"
    );
    dispatch({
      type: ACTION.GET_ALL_CUSTOMER_UNFILLED_FINANCE_ORDERLIST,
      payload: result.data,
    });
  };
};

export const createNewDetails = (orderid, financeDetails, navigate) => {
  let formData = {
    orderid: orderid,
    financeDetails: financeDetails,
  };

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-sell-customer-finance-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ACTION.ADD_FIN_CUST_DETAILS,
            payload: result,
          });
          navigate("../order_finance", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: ACTION.EDIT_FIN_CUST_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const getProductListOrderwiseForFinance = (
  id,
  navigate,
  navigationPath
) => {
  let OPTIONS = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return async (dispatch) => {
    function onSuccess(res) {
      if (res.data.status === 200) {
        let result = res.data.data;
        dispatch({
          type: ACTION.GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE,
          listview: result,
        });
        if (navigate) navigate(navigationPath, { replace: false });
      } else {
        dispatch({
          type: ACTION.GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE,
          listview: [],
        });
        return [];
      }
    }
    function onError(error) {
      dispatch({
        type: ACTION.GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE,
        listview: [],
      });
      return [];
    }
    try {
      const success = await axios.get(
        REACT_APP_URL +
          "/api/get-sell-orderlist-productwise-forfinance?saleOrderId=" +
          id,
        OPTIONS
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const getCustomerFinanceDetailsProductWiseForEdit = (
  id,
  navigate,
  navigationPath
) => {
  let OPTIONS = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return async (dispatch) => {
    try {
      const success = await axios.get(
        REACT_APP_URL +
          "/api/get-sell-customer-financelist-productWise?saleOrderId=" +
          id,
        OPTIONS
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }

    function onSuccess(res) {
      if (res.data.status === 200) {
        let result = res.data.data;
        dispatch({
          type: ACTION.GET_FINANCE_DETAILS_PRODUCTWISE,
          listview: result,
        });
        if (navigate) navigate(navigationPath, { replace: false });
      } else {
        dispatch({
          type: ACTION.GET_FINANCE_DETAILS_PRODUCTWISE,
          listview: [],
        });
        return [];
      }
    }
    function onError(error) {
      dispatch({ type: ACTION.GET_FINANCE_DETAILS_PRODUCTWISE, listview: [] });
      return [];
    }
  };
};

export const updateFinanceCustomerDetails = (
  mainOrderid,
  updatedData,
  navigate
) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sell-customer-finance-details",
      method: "POST",
      data: {
        mainOrderid: mainOrderid,
        financeUpdatedData: updatedData,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: ACTION.UPDATE_FIN_CUST_DETAILS,
            payload: result[0],
          });
          navigate("../order_finance", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const updateFinanceReturnsDetails = (updatedData, index, navigate) => {
  let returnsData = JSON.parse(updatedData);

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sell-customer-return-finance-details",
      method: "POST",
      data: {
        financeReturnData: returnsData[index],
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: ACTION.UPDATE_FIN_CUST_DETAILS,
            payload: result[0],
          });
          navigate("../order_finance", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

//////////////////////////////////////////////////////// Manufaturer details //////////////////

export const getAllManufacturerUnfilledfinanceOrderlist = () => {
  return async (dispatch) => {
    let result = await fetchDataMethod(
      "/api/get-allmanufacturer-unfilled-finance-orderlist"
    );
    dispatch({
      type: ACTION.GET_ALL_MANU_UNFILLED_FINANCE_ORDERLIST,
      payload: result.data,
    });
  };
};

export const createManufacturerFinanceDetails = (
  orderid,
  manufacturerFinanceDetails,
  navigate
) => {
  let formData = {
    orderid: orderid,
    manuFinanceDetails: manufacturerFinanceDetails,
  };

  return async (dispatch) => {
    let result = await createNewDetailsPost(
      "/api/add-sell-manufacturer-finance-details",
      formData
    );
    dispatch({
      type: ACTION.ADD_FIN_MANU_DETAILS,
      payload: result.data,
    });
    if (result.status) {
      navigate("../order_finance?id=1", { replace: false });
    }
  };
};

export const updateFinanceManufacturerDetails = (
  orderid,
  manufacturerFinanceDetails,
  navigate
) => {
  let formData = {
    orderid: orderid,
    manuFinanceDetails: manufacturerFinanceDetails,
  };

  return async (dispatch) => {
    let result = await updateDetailsPost(
      "/api/update-manufacturer-finance-details",
      formData
    );
    dispatch({
      type: ACTION.ADD_FIN_MANU_DETAILS,
      payload: result.data,
    });
    if (result.status) {
      navigate("../order_finance?id=1", { replace: false });
    }
  };
};

export const getManufacturerFinanceDetailsProductWiseForEdit = (
  id,
  navigate
) => {
  return async (dispatch) => {
    let result = await fetchDataMethod(
      "/api/get-manufacturer-financedetails-productWise-edit?saleOrderId=" + id
    );
    dispatch({
      type: ACTION.GET_MANU_FINANCE_DETAILS_PRODUCTWISE,
      payload: result.data,
    });
    if (result.status) {
      navigate("./manufacturer_edit", { replace: false });
    }
  };
};
