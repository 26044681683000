import {
  ADD_NEW_PURCHASE_DETAILS,
  FETCH_ALL_PURCHASE_INVOICE_LIST,
  FETCH_INVOICE_PRODUCT_ENTRY_LIST,
  GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
  EDIT_OLD_PURCHASE_INVOICE_DETAILS,
  UPDATE_PURCHASE_INVOICE_DETAILS,
  DELETE_PURCHASE_INVOICE_ENTRY_DETAILS,
} from "./_purchase_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const fetchAllPurchaseInvoices = (productsku, design) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-all-purchase-invoice",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_ALL_PURCHASE_INVOICE_LIST,
            listview: result,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_PURCHASE_INVOICE_LIST,
          listview: [],
        });
      });
  };
};

export const getInvoiceProductEntry = (invoice_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-all-product-stock-details?invoiceId=" +
        invoice_id,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_INVOICE_PRODUCT_ENTRY_LIST,
            listview: result,
          });
          // dispatch(getAttributeSaveValue(invoice_id, result, navigate))
          //navigate('./edit', { replace: false })
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_INVOICE_PRODUCT_ENTRY_LIST,
          listview: [],
        });
      });
  };
};

export const getAttributeSaveValuesssss = (
  invoiceId,
  navigate,
  stockId,
  saleorderid
) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-save-product-value?invoiceId=" +
        invoiceId +
        "&stockId=" +
        stockId +
        "&saleOrderId=" +
        saleorderid,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;

          dispatch({
            type: GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
            payload: result,
          });
          if (navigate) navigate("./edit", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
          payload: [],
        });
      });
  };
};

export const getAttributeSaveValue = (
  invoiceId,
  navigate,
  stockId,
  saleorderid
) => {
  let OPTIONS = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return async (dispatch) => {
    function onSuccess(res) {
      if (res.data.status === 200) {
        let result = res.data.data;
        dispatch({
          type: GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
          payload: result,
        });
        if (navigate) navigate("./edit", { replace: false });
      } else {
        dispatch({
          type: GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES,
          payload: [],
        });
        return [];
      }
    }
    function onError(error) {
      dispatch({ type: GET_SAVE_PURCHASE_ENTRY_ATTRIBUTE_VALUES, payload: [] });
      return [];
    }
    try {
      const success = await axios.get(
        REACT_APP_URL +
          "/api/get-save-product-value?invoiceId=" +
          invoiceId +
          "&stockId=" +
          stockId +
          "&saleOrderId=" +
          saleorderid,
        OPTIONS
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const createNewDetails = (values, navigate) => {
  values.isSingelStock = false;
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-new-purchase-invoice-details",
      method: "POST",
      data: values,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_NEW_PURCHASE_DETAILS,
            payload: result,
          });
          navigate("../purchase", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_OLD_PURCHASE_INVOICE_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  updateData.isSingelStock = false;
  updateData.invoiceId = r_id;
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-old-purchase-invoice-details",
      method: "POST",
      data: updateData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_PURCHASE_INVOICE_DETAILS,
            payload: result[0],
          });
          navigate("../purchase", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/delete-purchase-invoice-details",
      method: "POST",
      data: { invoiceId: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_PURCHASE_INVOICE_ENTRY_DETAILS,
            payload: id,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};
