/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Input,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  fetchAllAttributeValues,
  fetchAllProducts,
  getProductStoneDetails,
  resetProductStoneDetails,
} from "../product/_product_Action";
import { createNewDetails, updateDetails } from "./_product_Stock_Action";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../category/_category_Action";
import {
  fetchAllCategoryAttribute,
  resetLinkState,
} from "../link_CategoryAttribute/_link_Cat_Att_Action";
import { fetchAllSupplierDetails } from "../supplier/_supplier_Action";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ToastNotify from "../../components/ToastNotify";
import { fetchAllStoneType } from "../stoneDetails/_stoneType_Action";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  divider: {
    margin: "15px 0",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  nomargin: {
    margin: "0",
  },
})); /*  */

const ProductCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let _category = useSelector((state) => state.category._allDetails) || [];
  let _linkCat_Att =
    useSelector((state) => state.linkCat_Att._allDetails) || [];
  let _allSupplierData =
    useSelector((state) => state.supplier._allDetails) || [];
  let _editData =
    useSelector((state) => state.singleProductStock._editedDetails) || "";
  let _productStoneDetails =
    useSelector((state) => state.product._editProductStoneDetails) || {};
  let _allStoneTypes =
    useSelector((state) => state.stoneType._allDetails) || [];

  let _attributeValues =
    useSelector((state) => state.product._attributeValues) || [];
  let _savePurchaseAttributeValues =
    useSelector((state) => state.purchase._savePurchaseEntryValues) || [];

  let _allProductList = useSelector((state) => state.product._allDetails) || [];

  let textboxRender = [];

  let productdetails = { id: "", productName: "", productDesignNo: "" };

  if (_editData.productDesignNo) {
    productdetails = {
      id: _editData.productDesignId,
      productDesignNo: _editData.productDesignNo,
    };
  }
  let singleSaveAttValue = [];
  /*eslint array-callback-return: "off"*/
  _savePurchaseAttributeValues.map((saveObj, count) => {
    let sss = saveObj[0];
    singleSaveAttValue = { ...singleSaveAttValue, ...sss };
  });

  const [cateID, setCateID] = useState(_editData.categoryId);
  const [cateIDError, setCateIDError] = useState("");
  const [isRowMaterial, setIsRowMaterial] = useState(
    Boolean(!_editData.productDesignId) || false
  );

  const [textboxValue, settextboxValue] = useState(singleSaveAttValue || []);
  const [selectedDate, setSelectedDate] = useState(
    _editData.stockInDate || new Date()
  );
  let editSuppDetail = "";
  if (_editData.supplierName) {
    editSuppDetail = {
      id: _editData.suppid,
      supplierName: _editData.supplierName,
    };
  }
  const [supplierValue, setSupplierValue] = React.useState(
    editSuppDetail || { id: "", supplierName: "" }
  );
  const [designNoValue, setDesignNoValue] = useState(
    productdetails || { id: "", productDesignNo: "" }
  );
  const [designNoError, setDesignNoError] = useState("");

  const [productConfig, setProductConfig] = useState({
    payby: "",
    dueDay: _editData.payemntDueDay || "",
    weight: _editData.productWeight || "",
    price: _editData.perQtyPrice || "",
    qty: _editData.productQty || "",
    gstPer: _editData.purchaseGst || "",
    totalPrice: _editData.totalPrice || "",
    transactionId: _editData.id || "",
    stockId: _editData.productStockStoreId || "",
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const backBtnClick = () => {
    navigate("/app/product_stock", { replace: false });
  };
  useEffect(() => {
    dispatch(fetchAllAttributeValues());

    if (!_editData.categoryId) {
      dispatch(resetLinkState());
    } else {
      dispatch(fetchAllCategoryAttribute());
      dispatch(fetchAllProducts(_editData.categoryId));
    }
    if (_editData.productDesignId) {
      dispatch(getProductStoneDetails(_editData.productDesignId));
    }
    if (_category.length <= 0) dispatch(fetchAllCategory());
    if (_allSupplierData.length <= 0) dispatch(fetchAllSupplierDetails());
    if (_allStoneTypes.length <= 0) dispatch(fetchAllStoneType());

    dispatch(resetProductStoneDetails());
    // eslint-disable-next-line
  }, []);

  let attributeCompoArray = [],
    renderArray = [],
    renderProductStones = [];

  const onCategoryChange = (e) => {
    attributeCompoArray = [];
    renderArray = [];
    _linkCat_Att = [];
    renderProductStones = [];
    textboxRender = [];
    let isRowMaterial = false,
      selectedCategory;
    if (_category) {
      selectedCategory = _category.filter(
        (record) => record.id == e.target.value
      );
    }
    if (selectedCategory.length > 0)
      isRowMaterial = Boolean(selectedCategory[0].isRowMaterial);
    if (isRowMaterial) {
      setIsRowMaterial(isRowMaterial);
      dispatch(resetProductStoneDetails());
    } else {
      setIsRowMaterial(isRowMaterial);
      dispatch(fetchAllProducts(e.target.value));
    }
    setDesignNoValue({ id: "", productDesignNo: "" });
    dispatch(fetchAllCategoryAttribute(e.target.value));
    settextboxValue([]);
    setCateID(e.target.value);
  };

  let defultOption = (
    <option key="" value="000e0">
      Select Category
    </option>
  );
  let dropdownRender = [];

  if (_category) {
    dropdownRender = _category.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.categoryName}
        </option>
      );
    });
  }

  let stoneTypedefultOption = (
    <option key="" value="000e0">
      Select Stone
    </option>
  );
  let stoneTypeDropdownRender = [];
  if (_allStoneTypes) {
    stoneTypeDropdownRender = _allStoneTypes.map((option) => {
      return (
        <option key={option.id} value={option.stoneType}>
          {option.stoneType}
        </option>
      );
    });
  }
  const dynamicProductConfigChange = (name, value) => {
    let projList = { ...productConfig };
    projList[name] = value;
    setProductConfig(projList);
  };

  const handlePriceChange = (name, value) => {
    let projList = { ...productConfig };
    let lastGst = Number(projList["gstPer"]);

    projList[name] = value;

    if (name == "price") {
      // projList['totalPrice'] = value * projList['weight'] || '';
      projList["totalPrice"] =
        Number(projList["price"] * projList["weight"]) +
          (Number(projList["price"]) *
            Number(projList["weight"]) *
            Number(projList["gstPer"])) /
            100 || 0;
    }

    if (name == "totalPrice") {
      // projList['price'] = value / projList['weight'] || 0;
      projList["price"] =
        (Number(projList["totalPrice"]) -
          lastGst * (Number(projList["totalPrice"]) / (100 + lastGst))) /
          projList["weight"] || 0;
    }

    if (name == "gstPer") {
      projList["totalPrice"] =
        Number(projList["totalPrice"]) -
        lastGst * (Number(projList["totalPrice"]) / (100 + lastGst));
      projList["totalPrice"] =
        Number(projList["price"] * projList["weight"]) +
          (Number(projList["price"]) *
            Number(projList["weight"]) *
            Number(projList["gstPer"])) /
            100 || 0;
    }

    // if (name == 'weight') {
    //     projList['totalPrice'] = projList['price'] * value + (Number(projList['price']) * Number(value) + (Number(projList['totalPrice']) * Number(projList['gstPer']) / 100)) || '';

    //     projList['price'] = projList['totalPrice'] / value || '';
    // }

    setProductConfig(projList);
  };

  const dynamicHandleChange = (value, id) => {
    settextboxValue({
      ...textboxValue,
      [id]: value || "",
    });
  };
  textboxRender[0] = <br key="001" />;

  const renderFuncProductStoneDetails = (_passArray, stoneSide) => {
    renderArray = [];
    renderArray = _passArray || [];
    renderProductStones = renderArray.map((row, i) => {
      return (
        <div key={i}>
          <Grid container spacing={3} key={row.id.toString()}>
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {stoneSide}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Shape"
                name={row.id.toString()}
                value={row.shape || ""}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="size"
                name={row.id.toString() + "sz"}
                value={row.size || ""}
                variant="outlined"
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              ></Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Quantity"
                name={row.id.toString() + "qty"}
                value={row.quantity || ""}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                name={"stype" + row.id.toString()}
                label="Select type"
                onChange={(e) => {
                  dynamicHandleChange(
                    e.target.value,
                    "stype" + row.id.toString()
                  );
                }}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={textboxValue["stype" + row.id.toString()]}
              >
                {stoneTypedefultOption}
                {stoneTypeDropdownRender}
              </TextField>
            </Grid>
          </Grid>
        </div>
      );
    });
    return renderProductStones;
  };

  const renderNewAttributeFunc = () => {
    attributeCompoArray = [];

    if (_linkCat_Att.length > 0) {
      if (_editData) {
        let link_Val = _linkCat_Att.filter(
          (obj) => obj.categoryId == _editData.categoryId
        );
        if (link_Val.length > 0)
          attributeCompoArray = JSON.parse(link_Val[0].categoryAttId);
        else attributeCompoArray = JSON.parse(_linkCat_Att[0].categoryAttId);
      } else {
        attributeCompoArray = JSON.parse(_linkCat_Att[0].categoryAttId);
      }

      textboxRender = attributeCompoArray.map((row, i) => {
        let attValue = _attributeValues.filter((obj) => obj.id == row.id) || [];

        if (attValue.length)
          attValue = JSON.parse(attValue[0].attributeValue) || [];

        let inputVal;
        inputVal = textboxValue[row.id.toString()];

        return (
          <Grid
            key={row.id.toString()}
            container
            spacing={3}
            alignItems="center"
            className={classes.nomargin}
          >
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {row.attributeName} :
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {row.datatype == "SingleSelection" && (
                <Autocomplete
                  required
                  freeSolo
                  name={row.id.toString()}
                  value={inputVal || { id: "", value: "" }}
                  onChange={(event, value) => {
                    dynamicHandleChange(value, row.id.toString());
                  }}
                  options={attValue}
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={row.attributeName}
                      variant="outlined"
                    />
                  )}
                />
              )}
              {row.datatype == "MultiSelection" && (
                <Autocomplete
                  required
                  multiple
                  name={row.id.toString()}
                  options={attValue}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={inputVal || []} //  {textboxValue[row.id.toString()] || []}
                  input={<Input />}
                  onChange={(event, value) => {
                    dynamicHandleChange(value, row.id.toString());
                  }}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.value}
                      </React.Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={row.attributeName}
                      placeholder={row.attributeName}
                    />
                  )}
                />
              )}
              {row.datatype == "FreeText" && (
                <TextField
                  required
                  label={row.attributeName}
                  name={row.id.toString()}
                  fullWidth
                  value={inputVal || ""} // {textboxValue[row.id.toString()] || ''}
                  onChange={(e) => {
                    dynamicHandleChange(e.target.value, row.id.toString());
                  }}
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
        );
      });
      return textboxRender;
    }
  };

  var initialdata = {};
  var buttonText = "Create";
  if (_editData) {
    buttonText = "Update";
  } else {
    buttonText = "Create";
  }

  const validate = () => {
    let isError = false;

    var data = parseInt(cateID);
    if (data <= 0 || isNaN(data)) {
      isError = true;
      setCateIDError("Please select product category");
    }
    if (
      (designNoValue.id <= 0 || designNoValue.id == "") &&
      isRowMaterial == false
    ) {
      isError = true;
      setDesignNoError("Please select product designNo");
    }
    return isError;
  };

  return (
    <>
      <Page title="Add Stock">
        <ToastNotify />
        <Container maxWidth="xl">
          <Formik
            initialValues={initialdata}
            validationSchema={Yup.object().shape({
              // productName: Yup.string().required('Product name is required!'),
              //designDefinationId: Yup.string().required('Design Number is required!'),
            })}
            onSubmit={(values, { resetForm }) => {
              const err = validate();
              if (!err) {
                values.attributeValue = textboxValue;
                values.categoryId = cateID;
                values.isRowMaterial = isRowMaterial;
                values.supplierId = supplierValue.id || "";
                values.payby = productConfig.payby
                  ? productConfig.payby
                  : "cash";
                values.designName = designNoValue;
                values.qty = productConfig.qty || 0;
                values.weight = productConfig.weight;
                values.price = productConfig.price;
                values.totalPrice = productConfig.totalPrice || 0;
                values.gstPer = productConfig.gstPer || 0;
                values.dueDay = productConfig.dueDay || 0;
                values.stockIndate = Date.parse(selectedDate);
                values.transactionId = productConfig.transactionId || "";
                values.stockId = productConfig.stockId || "";
                if (buttonText === "Update" && _editData) {
                  dispatch(updateDetails(values, _editData.id, navigate));
                } else {
                  dispatch(createNewDetails(values, navigate));
                }
                setCateID("000e0");
                resetForm({ values: "" });
                settextboxValue([]);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form //autoComplete="off"
                //noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <Typography variant="h4" className={classes.cardHeader}>
                    Stock Details
                  </Typography>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Stock Date :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              id="date-picker-inline"
                              label="Select stock in date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Supplier Name :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          required
                          name="supplierName"
                          freeSolo
                          value={supplierValue}
                          onChange={(event, value) => setSupplierValue(value)}
                          options={_allSupplierData}
                          getOptionLabel={(option) =>
                            option ? option.supplierName : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Supplier"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Payby :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <RadioGroup
                          row
                          label="PayBy"
                          name="payby"
                          value={
                            productConfig.payby ? productConfig.payby : "cash"
                          }
                          onChange={(e) =>
                            dynamicProductConfigChange("payby", e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="cash"
                            control={<Radio />}
                            label="Cash"
                          />
                          <FormControlLabel
                            value="credit"
                            control={<Radio />}
                            label="Credit"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Due Days :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          label="Due Days"
                          name="dueDay"
                          fullWidth
                          type="number"
                          onBlur={handleBlur}
                          value={productConfig.dueDay}
                          onChange={(e) =>
                            dynamicProductConfigChange("dueDay", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Product Category :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="categoryId"
                          error={Boolean(cateIDError)}
                          fullWidth
                          helperText={cateIDError}
                          label="Product Category"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            onCategoryChange(e);
                            setCateIDError("");
                          }}
                          required
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          value={cateID}
                        >
                          {defultOption}
                          {dropdownRender}
                        </TextField>
                      </Grid>
                      {isRowMaterial ? (
                        ""
                      ) : (
                        <Grid
                          container
                          spacing={3}
                          alignItems="center"
                          className={classes.nomargin}
                        >
                          <Grid item xs={6} sm={3}>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Search by Design :
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              disabled={isRowMaterial}
                              name="designName"
                              freeSolo
                              value={designNoValue}
                              onChange={(event, value) => {
                                setDesignNoValue(value);
                                if (value == null) {
                                  value = { id: "", productDesignNo: "" };
                                } else
                                  dispatch(getProductStoneDetails(value.id));
                                setDesignNoError("");
                                renderArray = [];
                              }}
                              options={_allProductList}
                              getOptionLabel={(option) =>
                                option ? option.productDesignNo : {}
                              }
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by design"
                                  variant="outlined"
                                  error={Boolean(designNoError)}
                                  helperText={designNoError}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {renderFuncProductStoneDetails(
                      _productStoneDetails._mainStoneList,
                      "MainStone :"
                    )}

                    {renderFuncProductStoneDetails(
                      _productStoneDetails._sideStoneList,
                      "SideStone :"
                    )}
                    <Grid
                      alignItems="center"
                      container
                      spacing={3}
                      className={classes.marginBottom}
                    >
                      {renderNewAttributeFunc()}

                      {/* {textboxRender} */}
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Quantity :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          required
                          label="Quantity"
                          name="qty"
                          fullWidth
                          onBlur={handleBlur}
                          value={productConfig.qty}
                          type="number"
                          // onChange={(e) => { handlePriceChange(e,values); handleChange() }}
                          onChange={(e) =>
                            dynamicProductConfigChange("qty", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={3} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Weight :
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <TextField
                          required
                          label="Weight"
                          name="weight"
                          fullWidth
                          type="number"
                          onBlur={handleBlur}
                          value={productConfig.weight}
                          onChange={(e) =>
                            handlePriceChange("weight", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <TextField
                          required
                          label="Price Per Weight"
                          name="price"
                          fullWidth
                          type="number"
                          onBlur={handleBlur}
                          value={productConfig.price}
                          onChange={(e) =>
                            handlePriceChange("price", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={3} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Price :
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <TextField
                          required
                          label="GST %"
                          name="gstPer"
                          fullWidth
                          type="number"
                          value={productConfig.gstPer || 0}
                          onChange={(e) =>
                            handlePriceChange("gstPer", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <TextField
                          required
                          label="Total Price"
                          name="totalPrice"
                          fullWidth
                          type="number"
                          onBlur={handleBlur}
                          value={productConfig.totalPrice}
                          onChange={(e) =>
                            handlePriceChange("totalPrice", e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backBtnClick}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      //disabled={isSubmitting}
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
        {/* <Popup
                    title="Add Category"
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <CategoryCreate isPopup={openPopup} />
                </Popup>
                <Popup
                    title="Add Category Attribute"
                    openPopup={attributeOpenPopup}
                    setOpenPopup={setAttributeOpenPopup}
                >
                    <CategoryAttributeCreate isPopup={attributeOpenPopup} />
                </Popup> */}
      </Page>
    </>
  );
};

ProductCreate.propTypes = {
  className: PropTypes.string,
};

export default ProductCreate;
