/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  setParentFilterFn,
  setParentFilterTypeFn,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [filterTypeFn, setFilterTypeFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const AddPlatformNavigate = () => {
    navigate("./createshape", { replace: false });
  };

  // useEffect(() => {
  // }, [filterFn, filterTypeFn])

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.shapeName.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
    setParentFilterFn(filterFn);
  };

  const handleStoneTypeSearch = (e) => {
    let target = e.target;
    setFilterTypeFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.stoneType.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
    setParentFilterTypeFn(filterTypeFn);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        {/* <Button
          color="primary"
          variant="contained"
          onClick={AddPlatformNavigate}
        >
          Add Stone Shape
        </Button> */}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box>
              <Grid display="flex" alignItems="center" spacing={3} container>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleSearch}
                    placeholder="Search shape name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={AddPlatformNavigate}
                  >
                    Add Stone Shape
                  </Button>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleStoneTypeSearch}
                    placeholder="Search shape type"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) =>
                      navigate("./createtype", { replace: false })
                    }
                  >
                    Add Stone Type
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
