/*eslint eqeqeq: "off"*/
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_categoryAttribute_Action";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAllCategory_DropDown } from './_categoryAttribute_Action'
import dataTypes from "./dataType";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const CategoryAttributeCreate = ({ isPopup, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // let _category = useSelector(state => state.categoryAttribute._categoryDropdown);

  //const [inputList, setInputList] = useState([{ attValue: "" }]);
  const [inputList, setInputList] = useState([{}]);

  let _editData = useSelector(
    (state) => state.categoryAttribute._editedDetails
  );
  let string = _editData.attributeValue;

  let textEditData = "";
  if (string) textEditData = JSON.parse(string);
  useEffect(() => {
    if (textEditData) setInputList(textEditData);
    else setInputList([{ attValue: "" }]);
    // eslint-disable-next-line
  }, []);

  let dataTypeDefultOption = (
    <option key="" value="000e0">
      Select Data Type
    </option>
  );
  let dataTypeDropdown = [];
  if (dataTypes) {
    dataTypeDropdown = dataTypes.map((option) => {
      return (
        <option key={option.id} value={option.datatypeName}>
          {option.datatypeName}
        </option>
      );
    });
  }

  var initialdata = {
    categoryAttribute: "",
    categoryId: "",
    description: "",
    datatypeName: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      categoryAttribute: _editData.attributeName,
      categoryId: _editData.categoryId,
      description: _editData.attributeDescription,
      datatypeName: _editData.attributeDataType,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      categoryAttribute: "",
      categoryId: "",
      description: "",
      datatypeName: "",
    };
    buttonText = "Create";
  }

  const [datatype, setDatatype] = useState(_editData.attributeDataType || "");
  const [removeListValue, setRemoveListValue] = useState([{}]);
  const backBtnClick = () => {
    navigate("/app/categoryattribute", { replace: false });
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value || "";
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    removeListValue.push(list[index]);
    setRemoveListValue(removeListValue);

    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([{ attValue: "" }, ...inputList]);
  };

  let createTexbox = "";
  if (datatype !== "000e0" && datatype !== "" && datatype !== "FreeText") {
    createTexbox = inputList.map((x, i) => {
      let dynamicValue = x.attValue,
        dynamicName = "attValue";

      if (textEditData) {
        dynamicValue = x[Object.keys(x)];
        dynamicName = Object.keys(x).toString();
      }
      return (
        <Grid key={i} container>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name={dynamicName}
              // name="attValue"
              label="Value"
              placeholder="Enter value"
              variant="outlined"
              onChange={(e) => handleInputChange(e, i)}
              value={dynamicValue}
              //value={x.attValue}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box display="flex" justifyContent="flex-end" p={2}>
              {0 === i && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleAddClick}
                >
                  Add
                </Button>
              )}
              &ensp;&ensp;
              {inputList.length !== 1 && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      );
    });
  }
  const [dataTypeError, setDataTypeError] = useState("");
  const validate = () => {
    let isError = false;

    if (datatype == "000e0" || datatype == "") {
      isError = true;
      setDataTypeError("Please select DataType");
    }
    return isError;
  };

  return (
    <Page title="Category Attribute">
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            categoryAttribute: Yup.string().required(
              "Category attribute is required!"
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            const err = validate();
            if (!err) {
              values.attValues = JSON.stringify(inputList);
              values.datatypeName = datatype;
              values.deleteValueList = removeListValue;
              if (buttonText === "Update" && _editData) {
                dispatch(updateDetails(values, _editData.id, navigate));
              } else {
                dispatch(createNewDetails(values, navigate, isPopup));
              }
              resetForm({ values: "" });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Attribute Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Attribute Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.categoryAttribute && errors.categoryAttribute
                        )}
                        fullWidth
                        helperText={
                          touched.categoryAttribute && errors.categoryAttribute
                        }
                        label="Category Attribute"
                        name="categoryAttribute"
                        required
                        onBlur={handleBlur}
                        value={values.categoryAttribute}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Attribute Type :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(dataTypeError)}
                        fullWidth
                        helperText={dataTypeError}
                        label="Select Data Type"
                        name="datatypeName"
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        onChange={(e) => {
                          setDatatype(e.target.value);
                          setDataTypeError("");
                        }}
                        value={datatype}
                      >
                        {dataTypeDefultOption}
                        {dataTypeDropdown}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={6} xs={12}>
                      {createTexbox}
                      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

CategoryAttributeCreate.propTypes = {
  className: PropTypes.string,
};

// const mapStatetoProps = (state) => {
//     return {
//         categoryAttribute: state.categoryAttribute._editedDetails.attributeName || '',
//         sid: state.categoryAttribute._editedDetails.id
//         // action: state.pass.action,
//         // id: state.pass.id,
//         // user_id: state.user.userDetails.userid,
//         // msg: state.pass.msg
//     }
// }

// const mapDispatchtoProps = (dispatch) => {
//     return {
//         // addPassCat: function (category, user_id) {
//         //     dispatch(addPassCat(category, user_id));
//         // },
//         updateCatAttribute: function (category, id) {
//             dispatch(updateDetails(category, id));
//         }
//     }
// }

//export default connect(mapStatetoProps, mapDispatchtoProps)(CategoryAttributeCreate);

export default CategoryAttributeCreate;
