import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_stoneType_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const StoneTypeCreate = ({ isPopup, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.stoneType._editedDetails);

  var initialdata = { stoneType: "" },
    buttonText = "Create";
  if (_editData) {
    initialdata = { stoneType: _editData.stoneType };
    buttonText = "Update";
  } else {
    initialdata = { stoneType: "" };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/stone_info", { replace: false });
  };
  return (
    <Page
      // className={classes.root}
      title="Stone Type"
    >
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            stoneType: Yup.string().required("Stone shape name is required!"),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (buttonText === "Update" && _editData) {
              dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              dispatch(createNewDetails(values, navigate, isPopup));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Stone Type Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Stone Type :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.stoneType && errors.stoneType)}
                        fullWidth
                        helperText={touched.stoneType && errors.stoneType}
                        label="Stone type"
                        name="stoneType"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.stoneType}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

StoneTypeCreate.propTypes = {
  className: PropTypes.string,
};

export default StoneTypeCreate;
