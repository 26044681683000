/*eslint eqeqeq: "off"*/
import {
  ADD_ATTRIBUTE_DETAILS,
  FETCH_ATTRIBUTE_LIST,
  EDIT_CATEGORY_ATTRIBUTE_DETAILS,
  UPDATE_ATTRIBUTE_DETAILS,
  DELETE_ATTRIBUTE_DETAILS,
  FETCH_CATEGORY_DROPDOWN_LIST,
} from "./_categoryAttribute_Type";
const initialState = {
  _editedDetails: "",
  _msg: "",
  _allDetails: [],
  _categoryDropdown: [],
};

const categoryAttributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTRIBUTE_DETAILS:
      return {
        ...state,
        _msg: action.message,
      };
    case EDIT_CATEGORY_ATTRIBUTE_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_ATTRIBUTE_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _msg: action.msg,
      };
    case FETCH_ATTRIBUTE_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
      };
    case DELETE_ATTRIBUTE_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };
    case FETCH_CATEGORY_DROPDOWN_LIST:
      return {
        ...state,
        _categoryDropdown: action.listview,
        _msg: "",
      };

    default:
      return state;
  }
};

export default categoryAttributeReducer;
