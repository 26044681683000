export const ADD_SELL_PRIMARY_ORDER_DETAILS = 'ADD_SELL_PRIMARY_ORDER_DETAILS';
export const ADD_SELL_ORDER_PRODUCT_LIST = 'ADD_SELL_ORDER_PRODUCT_LIST';
export const UPDATE_SELL_PRIMARY_ORDER_DETAILS = 'UPDATE_SELL_PRIMARY_ORDER_DETAILS';

export const FETCH_ORDERBOOK_DETAILS_LIST = 'FETCH_ORDERBOOK_DETAILS_LIST';
export const EDIT_ORDERBOOK_DETAILS = 'EDIT_ORDERBOOK_DETAILS';
export const DELETE_ORDERBOOK_DETAILS = 'DELETE_ORDERBOOK_DETAILS';
export const CLEAR_ORDERBOOK_EDITED_DATA = 'CLEAR_ORDERBOOK_EDITED_DATA';
export const IS_PRODUCT_IN_STOCK = 'IS_PRODUCT_IN_STOCK';
export const CLEAR_PRODUCT_IN_STOCK = 'CLEAR_PRODUCT_IN_STOCK';
export const PASS_ORDER_DETAILS_TO_PRODUCTLIST = 'PASS_ORDER_DETAILS_TO_PRODUCTLIST';
export const GET_PRODUCTLIST_SALE_ORDERWISE = 'GET_PRODUCTLIST_SALE_ORDERWISE';