/*eslint eqeqeq: "off"*/
import {
  ADD_LINK_ATT_CAT_DETAILS,
  FETCH_LINK_ATT_CAT_LIST,
  RESET_LINK_CATE_ATT,
  EDIT_LINK_ATT_CAT_DETAILS,
  UPDATE_LINK_ATT_CAT_DETAILS,
  DELETE_LINK_ATT_CAT_DETAILS,
} from "./_link_Cat_Att_Type";
const initialState = {
  _editedDetails: "",
  _msg: "",
  _allDetails: [],
  _categoryDropdown: [],
};

const linkAttReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LINK_ATT_CAT_DETAILS:
      return {
        ...state,
        // _allDetails: [action.payload, ...state._allDetails],

        _msg: action.message,
      };
    case EDIT_LINK_ATT_CAT_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_LINK_ATT_CAT_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _msg: action.msg,
      };
    case RESET_LINK_CATE_ATT:
      return {
        initialState,
      };
    case FETCH_LINK_ATT_CAT_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
      };
    case DELETE_LINK_ATT_CAT_DETAILS:
      // var arraList
      // arraList = state._allDetails
      // var index = arraList.indexOf(arraList.find(obj => {
      //     return obj.id == action.payload
      // }))

      // if (index >= 0) {
      //     arraList.splice(index, 1)
      //     arraList = arraList
      // }

      return {
        ...state,
        // _allDetails: arraList,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };

    default:
      return state;
  }
};

export default linkAttReducer;
