/*eslint eqeqeq: "off"*/
import { LOGIN_USER, SET_CURRENT_USER, LOGOUT_USER } from "./userType";
import setAuthenticationToken from "./setAuthenticationToken";
import jwt from "jsonwebtoken";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");

export const loginUser = (
  navigate,
  username,
  password,
  innerHeight,
  innerWidth,
  screenHeight,
  screenWidth,
  os,
  uuid
) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/user/login",
      method: "POST",
      data: {
        username: username,
        password: password,
        innerHeight: innerHeight,
        innerWidth: innerWidth,
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        os: os,
        uuid: uuid,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        const status = res.data.status;
        if (status == 200) {
          var token = res.data.data.tempToken;

          localStorage.setItem("jwtToken", token);
          setAuthenticationToken(token);
          dispatch(setCurrentUser(jwt.decode(token)));
          dispatch({
            type: LOGIN_USER,
            payload: res.data.message,
            isLogin: true,
          });
          //alert(res.data.message)
          //navigate('/',{ replace: true })
          window.location.href = "/";
          //toast(res.data.message)
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const logout = () => {
  return function(dispatch) {
    localStorage.removeItem("jwtToken");
    setAuthenticationToken(false);
    dispatch(setCurrentUser({}));

    dispatch({
      type: LOGOUT_USER,
      isLog: false,
    });
    window.location.href = "/";
  };
};
