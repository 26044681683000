/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Checkbox,
  TableHead,
  Table,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const OrderList = ({ className, TableData, filterFn, ...rest }) => {

  const classes = useStyles();
  const {
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData, '', filterFn);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Order Date
                </TableCell>
                <TableCell>
                  Customer Name
                </TableCell>
                <TableCell>
                  Customer Mobile No.
                </TableCell>
                <TableCell>
                  Order No.
                </TableCell>
                <TableCell>
                  Manufacturer Name
                </TableCell>
                <TableCell>
                  MakerSku
                </TableCell>
                <TableCell>
                  Delivery days
                </TableCell>
                <TableCell>
                  Over Due days
                </TableCell>
              </TableRow>
            </TableHead>
            {TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, i) => (
                  <TableRow
                    hover key={i}
                  >
                    <TableCell>{row.orderDate}</TableCell>
                    <TableCell>{row.customerName}</TableCell>
                    <TableCell>{row.customerMobileNo}</TableCell>
                    <TableCell>{row.order_uid}</TableCell>
                    <TableCell>{row.manufacturerName}</TableCell>
                    <TableCell>{row.makerSku}</TableCell>
                    <TableCell>{row.due_day} days</TableCell>
                    <TableCell>{row.over_due_day} days</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

OrderList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};
export default OrderList;
