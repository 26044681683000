/*eslint eqeqeq: "off"*/
import {
  ADD_CAT_DETAILS,
  FETCH_ALL_CATEGORY_DETAILS_LIST,
  FETCH_ONLY_PRODUCT_CATEGORY_LIST,
  EDIT_CATEGORY_DETAILS,
  UPDATE_CATEGORY_DETAILS,
  CLEAR_CATEGORY_EDIT,
  DELETE_CATEGORY_DETAILS,
} from "./_category_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const createNewDetails = (values, navigate, isPopup) => {
  let formData = {
    categoryname: values.categoryName,
    isrowmaterial: values.isrowmaterial,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-category",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_CAT_DETAILS,
            payload: result,
          });
          if (!isPopup) navigate("../categorys", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchAllCategory = (yesOnlyProduct) => {
  // true OR False
  if (isUndefined(yesOnlyProduct) || yesOnlyProduct === "") {
    yesOnlyProduct = false;
  }
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-category?onlyProductCategory=" +
        yesOnlyProduct,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          if (yesOnlyProduct == true) {
            dispatch({
              type: FETCH_ONLY_PRODUCT_CATEGORY_LIST,
              listview: result,
            });
          } else {
            dispatch({
              type: FETCH_ALL_CATEGORY_DETAILS_LIST,
              listview: result,
            });
          }
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_CATEGORY_DETAILS_LIST,
          listview: [],
        });
        dispatch({
          type: FETCH_ONLY_PRODUCT_CATEGORY_LIST,
          listview: [],
        });
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_CATEGORY_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const clearEditedDetails = () => {
  return {
    type: CLEAR_CATEGORY_EDIT,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-category",
      method: "POST",
      data: {
        categoryid: r_id,
        categoryname: updateData.categoryName,
        isrowmaterial: updateData.isrowmaterial,
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_CATEGORY_DETAILS,
            payload: result[0],
          });
          navigate("../categorys", { replace: false });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-category",
      method: "POST",
      data: { categoryid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_CATEGORY_DETAILS,
            payload: id,
          });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
