import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Hidden,
  List,
  Typography,
  makeStyles,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
} from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Globe as GlobeIcon,
  BookOpen as BookOpenIcon,
  ChevronDown,
  ChevronUp,
  List as ListIcon,
} from "react-feather";
import NavItem from "./NavItem";
//import {ReceiptIcon} from '@material-ui/icons';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import ApartmentIcon from '@material-ui/icons/Apartment';
// import InputIcon from '@material-ui/icons/Input';
// import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
//
import {
  CategoryOutlined as CategoryOutlinedIcon,
  Apartment as ApartmentIcon,
  Receipt as ReceiptIcon,
  Business as BusinessIcon,
  Store as StoreIcon,
  FlightTakeoff as FlightTakeoffIcon,
  ShoppingCart as ShoppingCartIcon,
  Dns as DnsIcon,
  KeyboardReturn as ReturnIcon,
  ShowChart as ShowChartIcon,
  Dashboard
} from "@material-ui/icons";

const user = {
  avatar: "/static/images/avatars/logo.png",
  jobTitle: "Inventory Mangement",
  name: "XYZ Enterprises Limited",
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  collpase: {
    paddingLeft: "20px",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [sells_open, setSellsOpen] = useState(false);
  const [reports_open, setReportsOpen] = useState(false);
  const [category_open, setCategoryOpen] = useState(false);
  const handleClick_sells = () => {
    setSellsOpen(!sells_open);
  };
  const handleClick_Reports = () => {
    setReportsOpen(!reports_open);
  };
  const handleClick_category = () => {
    setCategoryOpen(!category_open);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/dashboard"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <NavItem
            href="/app/dashboard"
            key="Report"
            title="Dashboard"
            icon={Dashboard}
          />
          <NavItem
            href="/app/products"
            key="Product Defination"
            title="Product Defination"
            icon={DnsIcon}
          />
          <NavItem
            href="/app/product_stock"
            key="Single Stock Entry"
            title="Single Stock Entry"
            icon={StoreIcon}
          />
          <NavItem
            href="/app/purchase"
            key="Purchase Invoice"
            title="Purchase Invoice"
            icon={ShoppingCartIcon}
          />

          <ListItem onClick={handleClick_sells} button>
            <ListItemIcon>
              <ReceiptIcon size={20} />
            </ListItemIcon>
            <ListItemText primary="Sells" />
            <div>
              {sells_open ? (
                <ChevronUp color="#6b778c" />
              ) : (
                <ChevronDown color="#6b778c" />
              )}
            </div>
          </ListItem>
          <Collapse
            in={sells_open}
            timeout="auto"
            unmountOnExit
            className={classes.collpase}
          >
            <List component="div" disablePadding>
              <NavItem
                href="/app/order_book"
                key="Add Sells Order"
                title="Add Sells Order"
              />
              <NavItem
                href="/app/order_finance"
                key="Sell Finance"
                title="Sell Finance"
              />
            </List>
          </Collapse>
          <ListItem onClick={handleClick_Reports} button>
            <ListItemIcon>
              <BarChartIcon size={20} />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            <div>
              {reports_open ? (
                <ChevronUp color="#6b778c" />
              ) : (
                <ChevronDown color="#6b778c" />
              )}
            </div>
          </ListItem>
          <Collapse
            in={reports_open}
            timeout="auto"
            unmountOnExit
            className={classes.collpase}
          >
            <List component="div" disablePadding>
            <NavItem
                href="/app/order_profit"
                key="Sell Finance Profit"
                title="Profit By Orders"
              />
            <NavItem
                href="/app/over_due"
                key="Over Due Orders"
                title="Over Due Orders"
              />
            </List>
          </Collapse>
          <NavItem
            href="/app/iss_ret"
            key="Order status"
            title="Order Status"
            icon={ReturnIcon}
          />
          <NavItem
            href="/app/iss_stone"
            key="Issue in Stock"
            title="Issue Stone"
            icon={ShowChartIcon}
          />
          <NavItem
            href="/app/platformAccount"
            key="Platform Account"
            title="Platform Account"
            icon={GlobeIcon}
          />
          <ListItem onClick={handleClick_category} button>
            <ListItemIcon>
              <CategoryOutlinedIcon size={20} />
            </ListItemIcon>
            <ListItemText secondary="Category & Attributes" />
            <div>
              {category_open ? (
                <ChevronUp color="#6b778c" />
              ) : (
                <ChevronDown color="#6b778c" />
              )}
            </div>
          </ListItem>
          <Collapse
            in={category_open}
            timeout="auto"
            unmountOnExit
            className={classes.collpase}
          >
            <List component="div" disablePadding>
              <NavItem
                href="/app/categorys"
                key="Product Category"
                title="Product Category"
              // icon={CategoryOutlinedIcon}
              />
              <NavItem
                href="/app/categoryattribute"
                key="Category Attribute"
                title="Category Attribute"
              // icon={CategoryOutlinedIcon}
              />
              <NavItem
                href="/app/linkcat_att"
                key="Link Category Attribute"
                title="Link Category Attribute"
              // icon={CategoryOutlinedIcon}
              />
            </List>
          </Collapse>
          <NavItem
            href="/app/manufacturer"
            key="Manufacturer"
            title="Manufacturer"
            icon={ApartmentIcon}
          />
          <NavItem
            href="/app/customer"
            key="Customer"
            title="Customer"
            icon={UsersIcon}
          />
          <NavItem
            href="/app/pendingcustomers"
            key="pendingcustomers"
            title="pendingCustomers"
            icon={UsersIcon}
          />
          <NavItem
            href="/app/supplier"
            key="Supplier"
            title="Supplier"
            icon={UsersIcon}
          />
          <NavItem
            href="/app/courier_company"
            key="Courier Company"
            title="Courier Company"
            icon={FlightTakeoffIcon}
          />
          <NavItem
            href="/app/firm"
            key="Firm"
            title="Firm"
            icon={BusinessIcon}
          />
          <NavItem
            href="/app/stone_info"
            key="Stone Details"
            title="Stone Details"
            icon={ListIcon}
          />
          <NavItem
            href="/app/users"
            key="Employee Users"
            title="Employee Users"
            icon={UsersIcon}
          />
          <NavItem
            href="/app/inc_exp"
            key="Income & Expense"
            title="Income & Expense"
            icon={BookOpenIcon}
          />
          <NavItem
            href="/app/stock_details"
            key="Stock Details"
            title="Stock Details"
            icon={ShowChartIcon}
          />
        </List>
      </Box>
      <Box flexGrow={1} />
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

export default NavBar;
