import React, { useEffect, useState } from "react";
import { Container, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import ManufacturerList from "./ManufacturerList";
import { useSelector, useDispatch } from "react-redux";
import { newfetchAllManufacturers } from "./_manufacturer_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Manufacturer = () => {
  const classes = useStyles();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const dispatch = useDispatch();
  let allFetchData =
    useSelector((state) => state.manufacturer._allDetails) || [];

  useEffect(() => {
    dispatch(newfetchAllManufacturers());
    // eslint-disable-next-line
  }, []);

  let viewComponent;
  viewComponent = (
    <ManufacturerList TableData={allFetchData} filterFn={filterFn} />
  ); //setParentPage={setParentPage} setParentLimit={setParentLimit} setReloadTable={setReloadTable} tableCount={allFetchData.length} />

  return (
    <Page className={classes.root} title="Manufacturer">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />
        <Box mt={3}>{viewComponent}</Box>
      </Container>
    </Page>
  );
};

export default Manufacturer;
