import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { PlusSquare as PlusSquareIcon } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  editSingleDetails,
  getProductListOrderwiseForFinance,
  getCustomerFinanceDetailsProductWiseForEdit,
  getManufacturerFinanceDetailsProductWiseForEdit,
} from "./_finance_Action";

import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const OrderBookList = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headCells = [
    { id: "orderDate", label: "Order Date" },
    { id: "platformName", label: "Platform Name" },
    { id: "platformAccountEmailId", label: "Platform Account Email" },
    { id: "platformOrderNo", label: "Platform Order No" },
    { id: "customerId", label: "Customer Name" },
    { id: "deliveryDay", label: "Delivery Day" },
  ];

  if (Boolean(rest.ismanufacturer))
    headCells.push({
      id: "manu_finance",
      label: "Manufacturer Detail",
      disableSorting: true,
    });
  else
    headCells.push({
      id: "cust_finance",
      label: "customer Finance Edit",
      disableSorting: true,
    });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData, headCells, filterFn);

  const addManufacturerFinanceDetails = async (orderid, data) => {
    dispatch(editSingleDetails(orderid, data));
    await dispatch(getProductListOrderwiseForFinance(orderid));
    await dispatch(
      getManufacturerFinanceDetailsProductWiseForEdit(orderid, navigate)
    );
  };

  const addCustomerFinanceDetails = async (orderid, data) => {
    dispatch(editSingleDetails(orderid, data));
    await dispatch(getCustomerFinanceDetailsProductWiseForEdit(orderid));
    await dispatch(
      getProductListOrderwiseForFinance(orderid, navigate, "./customer_edit")
    );
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />
            {TableData.length > 0 ? (
              <TableBody>
                {/* {TableData.slice(0, limit).map((row) => ( */}
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      {moment(row.orderDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row.platformName}</TableCell>
                    <TableCell>{row.accountEmailId}</TableCell>
                    <TableCell>{row.platformOrderNo}</TableCell>
                    <TableCell>{row.customerName}</TableCell>
                    <TableCell>{row.deliveryDay}</TableCell>
                    {Boolean(rest.ismanufacturer) && (
                      <TableCell width="5%">
                        <IconButton
                          size="small"
                          onClick={() =>
                            addManufacturerFinanceDetails(row.id, row)
                          }
                        >
                          <PlusSquareIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {Boolean(!rest.ismanufacturer) && (
                      <TableCell width="5%">
                        <IconButton
                          size="small"
                          onClick={() => addCustomerFinanceDetails(row.id, row)}
                        >
                          <PlusSquareIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

OrderBookList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};

export default OrderBookList;
