import {
  GET_ISSUE_STONE_DETAILS,
GET_PENDING_ISSUE_STONE_ORDERS
  } from "./_order_Type";
  const initialState = {
    _editedDetails: "",
    _id: "",
    _allDetails: "",
  };
  
  const issueStoneReturnsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PENDING_ISSUE_STONE_ORDERS:
        return {
          _allDetails: action.payload,
        };
      case GET_ISSUE_STONE_DETAILS:
        return {
          _allDetails: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default issueStoneReturnsReducer;