import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import { Provider } from "react-redux";
import setAuthenticationToken from "./redux/action/setAuthenticationToken";
import { setCurrentUser, logout } from "./redux";
import jwt from "jsonwebtoken";
import store from "./redux/store";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import { routes, loginRoute } from "src/routes";

const App = () => {
  if (localStorage.jwtToken) {
    setAuthenticationToken(localStorage.jwtToken);
    jwt.verify(
      localStorage.jwtToken,
      process.env.REACT_APP_SECRET_KEY,
      function(err, decode) {
        if (err) {
          store.dispatch(logout());
        } else {
          store.dispatch(setCurrentUser(decode));
        }
      }
    );
  }

  const routing = useRoutes(routes);
  const routinlogin = useRoutes(loginRoute);

  let viewMenu = routinlogin;
  if (localStorage.jwtToken) {
    viewMenu = routing;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {viewMenu}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
