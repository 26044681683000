import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Info as InfoIcon } from "react-feather";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  return (
    <Dialog
      fullWidth
      open={props.dialogopen}
      TransitionComponent={Transition}
      onClose={props.openDialog}
      aria-describedby="alert-dialog-description"
      aria-labelledby="customized-dialog-title"
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">
        <InfoIcon color="red" style={{ marginRight: "10px" }} />{" "}
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          Once deleted, you will not be able to recover this Record!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.confirmDialog}
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
        <Button onClick={props.openDialog} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
