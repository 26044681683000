const prod = {
  url: {
    API_URL: 'http://localhost:1337',
    // API_URL: "http://13.232.151.81:1344",
    PRODUCT_IMG_PATH: "http://13.232.151.81:1344/productImage/",
  },
};
const dev = {
  url: {
    // API_URL: 'http://localhost:1344',
    API_URL: 'http://localhost:1337',
    // API_URL: "http://192.168.29.230:1344",
    PRODUCT_IMG_PATH: "http://192.168.29.230:1344/productImage/",
    // PRODUCT_IMG_PATH: 'http://localhost:1344/productImage/',
  },
};

if (process.env.NODE_ENV === "production") {
  console.log = function() {};
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;

// export const config = process.env.NODE_ENV === 'development' ? dev : dev;
