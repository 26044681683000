import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  makeStyles,
  Card,
  Divider,
  Button,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import Page from "src/components/Page";
import ToastNotify from "../../components/ToastNotify";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  container: {
    marginTop: "20px",
  },
  details_info: {
    marginLeft: "15px",
  },
}));

const StockDetailsCreate = ({ className }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let singleData =
    useSelector((state) => state.stockDetails._editedDetails) || [];
  function createData(date, qty, weight, gst, price, total, payment, supplier) {
    return { date, qty, weight, gst, price, total, payment, supplier };
  }

  const rows = [
    createData("26-10-2021", 5, 1, 5, 500, 525, "Cash", "S1"),
    createData("24-10-2021", 2, 5.5, 8, 1000, 1525, "GPay", "S2"),
  ];
  const backBtnClick = () => {
    navigate("/app/stock_details", { replace: false });
  };
  return (
    <Page title="Stock Details">
      <ToastNotify />
      <Container maxWidth="xl" className={classes.container}>
        <Card>
          <Typography variant="h4" className={classes.cardHeader}>
            Product Details
          </Typography>
          <Divider />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Weight&nbsp;(Kg)</TableCell>
                  <TableCell>GST&nbsp;(%)</TableCell>
                  <TableCell>Price&nbsp;(Rs.)</TableCell>
                  <TableCell>Total Price&nbsp;(Rs.)</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Supplier Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.date}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.weight}</TableCell>
                    <TableCell>{row.gst}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell>{row.payment}</TableCell>
                    <TableCell>{row.supplier}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Button color="primary" variant="contained" onClick={backBtnClick}>
              Back
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

StockDetailsCreate.propTypes = {
  className: PropTypes.string,
};

export default StockDetailsCreate;
