import React, { useState, useEffect } from "react";
import { Container, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import { useSelector, useDispatch } from "react-redux";
import Toolbar from "./Toolbar";
import IncomeExpenseList from "./IncomeExpenseList";
import { getAllincomeExpenseDatalist } from "./_inc_Exp_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function IncomeExpense() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let allFetchData =
    useSelector((state) => state.incomeExpense._allDetails) || [];
  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    // if(allFetchData.length==0){
    dispatch(getAllincomeExpenseDatalist());
    // }
    // eslint-disable-next-line
  }, []);

  let viewComponent;
  viewComponent = (
    <IncomeExpenseList TableData={allFetchData} filterFn={filterFn} />
  );

  return (
    <Page className={classes.root} title="Income & Expense Details">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />
        <Box mt={3}>{viewComponent}</Box>
      </Container>
    </Page>
  );
}
