import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  Input,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_link_Cat_Att_Action";
import { fetchAllCategory } from "../category/_category_Action";
// import { fetchCatAttribute_DropDown } from '../Not_c_AttributeValue/_attributeValue_Action'
import { fetchAllCategoryAttribute } from "../categoryAttribute/_categoryAttribute_Action";

import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ToastNotify from "../../components/ToastNotify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const CreateCategoryAtt = ({ isPopup, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let _category = useSelector((state) => state.category._allDetails) || [];

  // let _catAttribute = useSelector(state => state.attributeValue._categoryAttributeDropdown) || [];
  let _catAttribute =
    useSelector((state) => state.categoryAttribute._allDetails) || [];
  let _editData = useSelector((state) => state.linkCat_Att._editedDetails);

  let string = _editData.categoryAttId;
  let textEditData = "";
  if (string) textEditData = JSON.parse(string);

  //const [categoryIdValue, setCategoryIdValue] = useState(_editData.categoryId || '');
  const [productAttribute, setProductAttribute] = useState(textEditData || []);

  useEffect(() => {
    if (_category.length <= 0) {
      dispatch(fetchAllCategory());
    }
    //dispatch(fetchCatAttribute_DropDown())
    dispatch(fetchAllCategoryAttribute());
    // eslint-disable-next-line
  }, []);
  const backBtnClick = () => {
    navigate("/app/linkcat_att", { replace: false });
  };
  let defultOption = (
    <option key="" value="000e0">
      Select Category
    </option>
  );
  let dropdownRender = [];
  if (_category) {
    dropdownRender = _category.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.categoryName}
        </option>
      );
    });
  }

  const handleChangeAttribute = (event, value) => {
    setProductAttribute(value);
  };

  var initialdata = { categoryId: "" };
  var buttonText = "Create";
  if (_editData) {
    initialdata = { categoryId: _editData.categoryId };
    buttonText = "Update";
  } else {
    initialdata = { categoryId: "" };
    buttonText = "Create";
  }

  // const categoryHandleChange = (e) => {

  //     setCategoryIdValue(e.target.value)

  // }

  return (
    <Page title="Category Attribute">
      <ToastNotify />

      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            categoryId: Yup.string().matches(
              /^((?!000e0).)*$/s,
              "Please select category"
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            let arrIds = [];
            for (let obj of productAttribute) {
              arrIds.push(Number(obj.id));
            }
            values.attributeids = arrIds;
            if (buttonText === "Update" && _editData) {
              dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              dispatch(createNewDetails(values, navigate, isPopup));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Link category to attribute
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Category Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.categoryId && errors.categoryId)}
                        fullWidth
                        helperText={touched.categoryId && errors.categoryId}
                        label="Product Category"
                        name="categoryId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // onChange={e => categoryHandleChange(e)}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={values.categoryId}
                        // value={categoryIdValue}
                      >
                        {defultOption}
                        {dropdownRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  {/* <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <InputLabel id="product-attribute-checkbox-label">Product Attribute</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                fullWidth
                                                value={productAttribute}
                                                onChange={handleChangeAttribute}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {_catAttribute.map((row) => (
                                                    <MenuItem key={row.id} value={row.id} >
                                                        <Checkbox checked={productAttribute.indexOf(row.id) > -1} />
                                                        <ListItemText primary={row.attributeName} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid> */}

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Attribute List :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        multiple
                        id="attributeName-auto"
                        options={_catAttribute}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.attributeName}
                        getOptionSelected={(option, value) =>
                          option.attributeName === value.attributeName
                        }
                        value={productAttribute}
                        input={<Input />}
                        onChange={(event, value) =>
                          handleChangeAttribute(event, value)
                        }
                        renderOption={(option, { selected }) => {
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                // style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.attributeName}
                            </React.Fragment>
                          );
                        }}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Attribute"
                            placeholder="Attributes"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

CreateCategoryAtt.propTypes = {
  className: PropTypes.string,
};

export default CreateCategoryAtt;
