/*eslint eqeqeq: "off"*/
import {
  ADD_PRODUCT_DETAILS,
  UPDATE_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS_LIST,
  EDIT_PRODUCT_DETAILS,
  CLEAR_PRODUCT_EDIT_DETAILS,
  RESET_PRODUCT_STONE_DETAILS,
  GET_PRODUCT_SAVE_STONE_DETAILS,
  GET_PRODUCT_ATTRIBUTE_VALUES,
  DELETE_PRODUCT_DETAILS,
  FETCH_SINGLE_PRODUCT_DETAILS,
} from "./_product_Type";
const initialState = {
  _editedDetails: "",
  _msg: "",
  _allDetails: [],
  _attributeValues: [],
  _saveProductValues: [],
  _editProductStoneDetails: { _mainStoneList: [], _sideStoneList: [] },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
        _editedDetails: "",
        _editProductStoneDetails: [{ _mainStoneList: [], _sideStoneList: [] }],
      };
    case EDIT_PRODUCT_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_PRODUCT_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _editProductStoneDetails: [{ _mainStoneList: [], _sideStoneList: [] }],
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case GET_PRODUCT_ATTRIBUTE_VALUES:
      return {
        ...state,
        _attributeValues: action.payload,
      };
    case RESET_PRODUCT_STONE_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _editProductStoneDetails: [{ _mainStoneList: [], _sideStoneList: [] }],
      };
    case GET_PRODUCT_SAVE_STONE_DETAILS:
      return {
        ...state,
        _editProductStoneDetails: {
          _mainStoneList: action.payload.filter(
            (record) => record.stoneCategory == 1
          ),
          _sideStoneList: action.payload.filter(
            (record) => record.stoneCategory == 2
          ),
        },
      };
    case FETCH_PRODUCT_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
        _msg: "",
      };
    case FETCH_SINGLE_PRODUCT_DETAILS:
      return {
        ...state,
        _editedDetails: action.data,
        _msg: "",
      };
    case DELETE_PRODUCT_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };
    case CLEAR_PRODUCT_EDIT_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _editProductStoneDetails: [],
      };

    default:
      return state;
  }
};

export default productReducer;
