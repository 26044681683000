import { config } from "../Constants";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const createNewDetailsPost = async (api, payload) => {
  let OPTIONS = {
    url: REACT_APP_URL + api,
    method: "POST",
    data: payload,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const success = await axios(OPTIONS);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }

  function onSuccess(res) {
    if (res.status === 201 || 200) {
      let result = { data: res.data.data, status: true };
      return result;
    } else {
      return { data: [], status: false };
    }
  }
  function onError(error) {
    return { data: [], status: false };
  }
};

export const fetchDataMethod = async (api) => {
  let OPTIONS = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const success = await axios.get(REACT_APP_URL + api, OPTIONS);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }

  function onSuccess(res) {
    if (res.data.status === 200) {
      let result = { data: res.data.data, status: true };
      return result;
    } else {
      return { data: [], status: false };
    }
  }
  function onError(error) {
    return { data: [], status: false };
  }
};

export const updateDetailsPost = async (api, payload) => {
  let OPTIONS = {
    url: REACT_APP_URL + api,
    method: "POST",
    data: payload,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const success = await axios(OPTIONS);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }

  function onSuccess(res) {
    if (res.status === 200) {
      let result = { data: res.data.data, status: true };
      return result;
    } else {
      return { data: [], status: false };
    }
  }
  function onError(error) {
    return { data: [], status: false };
  }
};

export const deleteSingleRecord = async (api, payload) => {
  let OPTIONS = {
    url: REACT_APP_URL + api,
    method: "POST",
    data: payload,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const success = await axios(OPTIONS);
    return onSuccess(success);
  } catch (error) {
    return onError(error);
  }

  function onSuccess(res) {
    if (res.data.status === 200) {
      let result = { data: res.data.data, status: true };
      return result;
    } else {
      return { data: [], status: false };
    }
  }
  function onError(error) {
    return { data: [], status: false };
  }
};
