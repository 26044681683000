import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      // menu section start
      ".MuiListItem-gutters": {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      ".MuiListItemIcon-root": {
        minWidth: "30px",
        color: "rgb(84, 110, 122)",
      },
      ".MuiTypography-displayBlock": {
        color: "#546e7a",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "1.75",
      },
      ".MuiAccordionSummary-content.Mui-expanded": {
        margin: "12px 0",
      },
      ".MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "48px",
      },
      ".makeStyles-root-78": {
        backgroundColor: "#fff",
      },
      "#alert-dialog-title>h2": {
        display: "flex",
        alignItems: "center",
      },
      ".MuiBackdrop-root": {
        backgroundColor: "rgb(0 0 0 / 43%)",
      },
      // menu section over
      ".MuiTabs-indicator": {
        backgroundColor: "#fff",
      },
      ".divider_margin": {
        marginTop: "5px",
        marginBottom: "5px",
      },
      ".typography_margin": {
        marginTop: "15px",
        marginBottom: "15px",
      },
      ".marginRight": {
        marginRight: "10px",
      },
      ".checkbox_selection": {
        padding: "5px 0",
      },
      ".checkbox_selection label": {
        marginLeft: "20px",
        fontSize: "16px",
      },
      ".checkbox_selection input": {
        cursor: "pointer",
      },
      ".childModule": {
        marginLeft: "20px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },
      ".select_input": {
        marginRight: "10px",
        cursor: "pointer",
      },
      ".MuiButton-root.active": {
        color: "rgb(63 81 181)",
        fontWeight: "700",
      },
      ".MuiCardContent-root:last-child":{
        paddingBottom:"16px"
      },
      ".issue_maker_span":{
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize:'14px'
      }
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
