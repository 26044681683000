import {
  ADD_STONE_SHAPE_DETAILS,
  FETCH_ALL_STONE_SHAPE_DETAILS_LIST,
  EDIT_STONE_SHAPE_DETAILS,
  UPDATE_STONE_SHAPE_DETAILS,
  DELETE_STONE_SHAPE_DETAILS,
} from "./_stoneDetails_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const createNewDetails = (values, navigate, isPopup) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-stone-shape",
      method: "POST",
      data: { shapename: values.shapeName },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_STONE_SHAPE_DETAILS,
            payload: result,
          });
          if (!isPopup) navigate("../stone_info", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchAllStoneShape = () => {
  // true OR False

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-stone-shape",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_ALL_STONE_SHAPE_DETAILS_LIST,
            listview: result,
          });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_STONE_SHAPE_DETAILS_LIST,
          listview: [],
        });
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_STONE_SHAPE_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-stone-shape",
      method: "POST",
      data: {
        shapeid: r_id,
        shapename: updateData.shapeName,
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_STONE_SHAPE_DETAILS,
            payload: result[0],
          });
          navigate("../stone_info", { replace: false });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-stone-shape",
      method: "POST",
      data: { shapeid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_STONE_SHAPE_DETAILS,
            payload: id,
          });
        } else {
          toast.error("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
