import {
  ADD_PLATFORM_ACC,
  FETCH_PLATFORM_ACC_LIST,
  EDIT_PLATFORM_ACC,
  DELETE_PLATFORM_ACC,
  FETCH_PLATFORM_NAME_LIST,
  EDIT_PLATFORM_NAME,
  DELETE_PLATFORM_NAME_ACC,
  UPDATE_PLATFORM_NAME,
} from "./_platformAccount_Type";
import { config } from "../../Constants";
var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const createPlatformAccount = (values, navigate) => {
  let formData = {
    saleplatformId: parseInt(values.platformName) || 0,
    accountEmailAddress: values.accountEmail,
    accountMobileNo: parseInt(values.phone) || 0,
    accountGstNo: values.accountGstNo,
    otherDetails: values.otherDetails,
    accountname: values.accountName,
    accountowner: values.ownerName,
    location: values.location,
    bankname: values.bankname,
    ifscno: values.ifsc,
    accountNo: values.bankAccountno,
  };

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-sale-account",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let message = res.data.message;
          dispatch({
            type: ADD_PLATFORM_ACC,
            message: message,
          });
          navigate("../platformAccount", { replace: false });

          // alert(message)
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const fetchAllPlatformAcc = (platformNameid) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-sale-account?platformid=" + platformNameid,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch(getPlatform(result));
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getPlatform = (data) => {
  return {
    type: FETCH_PLATFORM_ACC_LIST,
    listview: data,
  };
};

export const editPlatformAccount = (id, singleData) => {
  return {
    type: EDIT_PLATFORM_ACC,
    payload: singleData,
    id: id,
  };
};

export const updateAccount = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-account",
      method: "POST",
      data: {
        accountid: r_id,
        saleplatformId: updateData.platformName || 0,
        accountEmailAddress: updateData.accountEmail,
        accountMobileNo: updateData.phone || 0,
        accountGstNo: updateData.accountGstNo,
        otherDetails: updateData.otherDetails,
        accountname: updateData.accountName,
        accountowner: updateData.ownerName,
        location: updateData.location,
        bankname: updateData.bankname,
        ifscno: updateData.ifsc,
        accountNo: updateData.bankAccountno,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("../platformAccount", { replace: false });
          // alert(message);
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const deletePlatformAccount = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-account",
      method: "POST",
      data: { accountid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_PLATFORM_ACC,
            payload: id,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => console.log(err));
  };
};

///////////////

export const fetchPltformNameList = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-sale-platforms",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let message = res.data.message;
          let getPltformList = res.data.data || [];
          dispatch({
            type: FETCH_PLATFORM_NAME_LIST,
            message: message,
            data: getPltformList,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
};
export const createPlatformName = (platformName, navigate) => {
  let formData = {
    platformname: platformName,
  };

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-sale-platform",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let message = res.data.message;
          dispatch({
            type: ADD_PLATFORM_ACC,
            message: message,
          });
          navigate("../platformAccount/createname", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const editPlatformName = (id, singleData) => {
  return {
    type: EDIT_PLATFORM_NAME,
    payload: singleData,
    id: id,
  };
};

export const updatePlatformName = (updateData, r_id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-platform",
      method: "POST",
      data: {
        platformid: r_id,
        platformname: updateData,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          // let message = res.data.message
          dispatch({
            type: UPDATE_PLATFORM_NAME,
            payload: result[0],
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const deletePlatformName = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-sale-platform",
      method: "POST",
      data: { platformid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_PLATFORM_NAME_ACC,
            payload: id,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => console.log(err));
  };
};
