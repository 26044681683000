import React, { useState, useEffect } from "react";
import { Container, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import ProductStockList from "./ProductStockList";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProductStockEntry } from "./_product_Stock_Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ProductStock = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    dispatch(fetchAllProductStockEntry());
    // eslint-disable-next-line
  }, []);

  let allFetchData =
    useSelector((state) => state.singleProductStock._allDetails) || [];

  let viewComponent = (
    <ProductStockList TableData={allFetchData} filterFn={filterFn} />
  );

  return (
    <Page className={classes.root} title="Stock">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />

        <Box mt={3}>{viewComponent}</Box>
      </Container>
    </Page>
  );
};

export default ProductStock;
