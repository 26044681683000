import * as Action from "./_finance_Type";

const initialState = {
  _editedDetails: "",
  _action: "View",
  _id: "",
  _msg: "",
  _allDetails: [],
  _allUnfilledManucaturer: [],
  _productListOrderWise: [],
  _financeDetailsProductWise: [],
  _manufacturerFinanceDetailsEdit: [],
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_ALL_CUSTOMER_UNFILLED_FINANCE_ORDERLIST:
      return {
        ...state,
        _allDetails: action.payload,
      };
    case Action.ADD_FIN_CUST_DETAILS:
      return {
        ...state,
        // _allDetails: [action.payload, ...state._allDetails],
      };
    case Action.EDIT_FIN_CUST_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case Action.UPDATE_FIN_CUST_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        // _allDetails: state._allDetails.map((obj) =>
        //     obj.id == action.payload.id ? action.payload : obj
        // ),
      };
    case Action.GET_PRODUCTLIST_SALE_ORDERWISE_FINANCE:
      return {
        ...state,
        _productListOrderWise: action.listview,
      };
    case Action.GET_FINANCE_DETAILS_PRODUCTWISE:
      return {
        ...state,
        _financeDetailsProductWise: action.listview,
      };

    case Action.GET_MANU_FINANCE_DETAILS_PRODUCTWISE:
      return {
        ...state,
        _manufacturerFinanceDetailsEdit: action.payload,
      };
    case Action.ADD_FIN_MANU_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case Action.GET_ALL_MANU_UNFILLED_FINANCE_ORDERLIST:
      return {
        ...state,
        _allUnfilledManucaturer: action.payload,
      };

    default:
      return state;
  }
};

export default financeReducer;
