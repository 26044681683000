/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Input,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  fetchAllProducts,
  fetchAllAttributeValues,
  getProductStoneDetails,
  resetProductStoneDetails,
} from "../product/_product_Action"; /// For Dynamic Attr Value
import { fetchAllStoneType } from "../stoneDetails/_stoneType_Action";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllManufacturers } from "../manufacturer/_manufacturer_Action";
import {
  fetchAllOrderDetails,
  checkProductInStock,
  createSellProductListDetails,
  updateSellProductListDetails,
  clearProductInStock,
} from "./_orderBook_Action";
import { fetchAllPlatformAcc } from "../../redux";
import { fetchAllCategoryAttribute } from "../link_CategoryAttribute/_link_Cat_Att_Action";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Popup from "src/components/Popup";
import ProductCreate from "../product/ProductCreate";
import StockInList from "./StockInListPopup";
import { fetchAllCategory } from "../category/_category_Action";
import { editSingleDetails } from "../orderBook/_orderBook_Action";
import Page from "src/components/Page";
import ToastNotify from "../../components/ToastNotify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
  nomargin: {
    margin: "0",
  },
})); /*  */

const AddProductStock = ({
  className,
  prevStep,
  nextStep,
  isCommanOrderSave,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let _allProductList = useSelector((state) => state.product._allDetails) || [];
  let _allManufacturerData =
    useSelector((state) => state.manufacturer._allDetails) || [];
  let _allOrderList = useSelector((state) => state.orderBook._allDetails) || [];
  let _pltformOrder_Details =
    useSelector((state) => state.orderBook._get_PltformOrder_Details) || {};
  let _productListOrderWise =
    useSelector((state) => state.orderBook._productListOrderWise) || [];
  let _InStockProduct =
    useSelector((state) => state.orderBook._InStockProduct) || [];
  let _platfromAccountList =
    useSelector((state) => state.platfromAccount._allPlatfrom) || [];
  let _category = useSelector((state) => state.category._allDetails) || [];
  let _allStoneTypes =
    useSelector((state) => state.stoneType._allDetails) || [];
  let _productStoneDetails = useSelector(
    (state) => state.product._editProductStoneDetails
  ) || { _mainStoneList: [], _sideStoneList: [] };
  let _linkCat_Att =
    useSelector((state) => state.linkCat_Att._allDetails) || [];
  let _attributeValues =
    useSelector((state) => state.product._attributeValues) || [];
  let _savePurchaseAttributeValues =
    useSelector((state) => state.purchase._savePurchaseEntryValues) || [];
  let _storeDetails =
    useSelector((state) => state.orderBook.storeDetails) || "";
  const [productOpenPopup, setProductOpenPopup] = useState(false);
  const [stockInOpenPopup, setStockInOpenPopup] = useState(false);

  let plformOrderNo = { id: "", platformOrderNo: "" };
  if (Object.keys(_pltformOrder_Details).length > 0) {
    plformOrderNo = {
      id: _pltformOrder_Details.id,
      platformOrderNo: _pltformOrder_Details.platformOrderNo,
    };
  }

  const [commanValue, setCommanValue] = useState({
    platformAccountId: _pltformOrder_Details.accountId,
    platformOrderNoDropDown: plformOrderNo,
  });
  const [renderIndex, setRenderIndex] = useState(-1);

  const fillAttributeValue = (stockId, qty, weight) => {
    handleInputChangeDiffComponent("stockId", stockId, renderIndex);
    if (qty <= 0 || weight <= 0) {
      handleInputChangeDiffComponent("sourceOrder", 1, renderIndex);
    } else {
      handleInputChangeDiffComponent("sourceOrder", 2, renderIndex);
    }
    handleInputChangeDiffComponent("isProductSelected", true, renderIndex);
  };
  const backToFirstForm = () => {
    navigate("/app/order_book/create");
    dispatch(editSingleDetails(_storeDetails.id, _storeDetails));
  };
  const backToList = () => {
    navigate("/app/order_book");
  };
  useEffect(() => {
    let singleJson = [];
    /*eslint array-callback-return: "off"*/
    _savePurchaseAttributeValues.map((saveObj, count) => {
      let sss = saveObj[0];
      singleJson = { ...singleJson, ...sss };
    });

    let saveAttList = [...textboxValue];
    saveAttList[renderIndex] = singleJson;

    settextboxValue(saveAttList);
    // eslint-disable-next-line
  }, [_savePurchaseAttributeValues]);

  useEffect(() => {
    dispatch(fetchAllCategoryAttribute());
    if (_category.length <= 0) dispatch(fetchAllCategory());

    if (_allManufacturerData.length <= 0) dispatch(fetchAllManufacturers());
    if (_allProductList.length <= 0) dispatch(fetchAllProducts());

    if (_allOrderList.length <= 0) dispatch(fetchAllOrderDetails());

    // if (_linkCat_Att.length > 0)
    //     setCategoryAttriList(_linkCat_Att)
    if (_allStoneTypes.length <= 0) dispatch(fetchAllStoneType());

    //////
    dispatch(fetchAllAttributeValues());
    dispatch(fetchAllPlatformAcc());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleInputChange(1, 0);
    singleProductOrder.sourceOrder = 1;
    if (renderIndex >= 0)
      onDesignNameChangeGetStoneDetails(_productStoneDetails, renderIndex);
    // eslint-disable-next-line
  }, [_productStoneDetails]);

  let sourceFrom = [
    { id: 1, source: "From Manufacturer" },
    { id: 2, source: "In stock" },
  ];
  // let defultOption = (
  //   <option key="0" value="0">
  //     Select Source
  //   </option>
  // );
  let dropdownRender = [];
  if (sourceFrom) {
    dropdownRender = sourceFrom.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.source}
        </option>
      );
    });
  }
  let defultPlatform = (
    <option key="" value="000e0">
      Select Platform
    </option>
  );
  let platformAccountRender = [];
  if (_platfromAccountList) {
    platformAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.platformName}
        </option>
      );
    });
  }
  let defultEmail = (
    <option key="" value="000e0">
      Select Id
    </option>
  );
  let platformEmailAccountRender = [];
  if (_platfromAccountList) {
    platformEmailAccountRender = _platfromAccountList.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.accountName}
        </option>
      );
    });
  }

  let cateDefultOption = (
    <option key="" value="000e0">
      Select Category
    </option>
  );
  let cateDropdownRender = [];

  if (_category) {
    let productCategory = _category.filter((ele) => ele.isRowMaterial !== true)
    cateDropdownRender = productCategory.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.categoryName}
        </option>
      );
    });
  }
  var buttonText = "Save";
  if (_productListOrderWise.length > 0) {
    buttonText = "Update";
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var singleProductOrder = {
    //USER TEMPLETE
    categoryId: "",
    isNewDesign: false,
    productName: { id: "", productDesignNo: "" },
    quantity: 1,
    sourceOrder: 1,
    manufacturerName: { id: "", manufacturerName: "" },
    handlingDay: "",
    note: "",
    productCategoryAttribute: [],
    isRowMaterial: false,
    isProductSelected: false,
    stockId: 0,
  };

  let productList = [singleProductOrder],
    setStoneDetailsOrderWise = [{ stoneDetails: [] }],
    editAttributeList = [{}],
    selectedSaveAttrValue = [],
    attributeCompoArray = [];
  if (_productListOrderWise.length > 0) {
    _productListOrderWise.map((obj, i) => {
      productList[i] = {
        id: obj.id,
        categoryId: obj.categoryId,
        isNewDesign: Boolean(obj.isNewDesign),
        productName: {
          id: obj.product_id,
          productDesignNo: obj.productDesignNo,
        },
        quantity: obj.quntity,
        productPrice: obj.pricePerQty,
        obj,
        productWeight: obj.weightPerQty,
        sourceOrder: obj.orderSourceFrom,
        manufacturerName: {
          id: obj.makerId,
          manufacturerName: obj.manufacturerName,
        },
        handlingDay: obj.handlingTime,
        note: obj.note,
        isRowMaterial: "",
        isProductSelected: "",
        stockId: obj.stockId,
      };

      let singleRecord = _linkCat_Att.filter(
        (linkObj) => linkObj.categoryId == obj.categoryId
      ) || [{ categoryAttId: [{}] }];

      let _mainStoneList = _productStoneDetails._mainStoneList
        ? _productStoneDetails._mainStoneList.filter(
            (stonObj) => stonObj.productDefiId == obj.product_id
          ) || ""
        : "";
      let _sideStoneList = _productStoneDetails._sideStoneList
        ? _productStoneDetails._sideStoneList.filter(
            (stonObj) => stonObj.productDefiId == obj.product_id
          ) || ""
        : "";

      let newJson = {
        stoneDetails: {
          _mainStoneList: _mainStoneList,
          _sideStoneList: _sideStoneList,
        },
      };
      setStoneDetailsOrderWise[i] = newJson;

      if (singleRecord.length > 0)
        attributeCompoArray = JSON.parse(singleRecord[0].categoryAttId) || [];

      editAttributeList[i] = { catgeoryAttributeCompo: attributeCompoArray };
      let singleJson = [];
      _savePurchaseAttributeValues.map((saveObj, count) => {
        if (obj.stockId == saveObj[1].sid) {
          let sss = saveObj[0];
          singleJson = { ...singleJson, ...sss };
        }
      });
      selectedSaveAttrValue[i] = singleJson;
    });
  }

  const [inputList, setInputList] = useState(productList || []); // [singleProductOrder]|| );
  const [textboxValue, settextboxValue] = useState(selectedSaveAttrValue || []);
  const [stoneDetailsArray, setStoneDetailsArray] = useState(
    setStoneDetailsOrderWise || [{ stoneDetails: [] }]
  );
  const [attributeValueList, setAttributeValueList] = useState(
    editAttributeList || []
  );
  let stoneTypedefultOption = (
    <option key="" value="0">
      Select Stone
    </option>
  );
  let stoneTypeDropdownRender = [];
  if (_allStoneTypes) {
    stoneTypeDropdownRender = _allStoneTypes.map((option) => {
      return (
        <option key={option.id} value={option.stoneType}>
          {option.stoneType}
        </option>
      );
    });
  }

  const handleInputChangeDiffComponent = (name, value, index) => {
    const list = [...inputList];
    if (name == "isNewDesign") {
      list[index][name] = value;
      list[index]["productName"] = { id: "", productDesignNo: "" };
      if (_productStoneDetails) dispatch(resetProductStoneDetails());
    } else list[index][name] = value || "";
    setInputList(list);
  };

  const commanInputValueChange = (name, value) => {
    const list = { ...commanValue };
    list[name] = value || "";
    setCommanValue(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target ? e.target : e;
    const list = [...inputList];

    if (list[index]["isRowMaterial"]) {
      list[index]["productName"] = { id: "", productDesignNo: "" };
      // if (_productStoneDetails) dispatch(resetProductStoneDetails());
    }

    list[index][name] = value || "";
    setInputList(list);
    // handleChangeDiffCompo("arrayProductOrder", list)
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const attList = [...attributeValueList];
    attList.splice(index, 1);
    setAttributeValueList(attList);

    const attValue = [...textboxValue];
    attValue.splice(index, 1);
    settextboxValue(attValue);

    const stoneDetail = [...stoneDetailsArray];
    stoneDetail.splice(index, 1);
    setStoneDetailsArray(stoneDetail);
  };

  const handleAddClick = () => {
    setInputList([singleProductOrder, ...inputList]);
    setAttributeValueList([[], ...attributeValueList]);
    settextboxValue([{}, ...textboxValue]);
    setStoneDetailsArray([{ stoneDetails: [] }, ...stoneDetailsArray]);
    dispatch(clearProductInStock());
  };

  const onDesignNameChangeGetStoneDetails = (_stoneArray, i) => {
    let newArray = [];
    newArray = { stoneDetails: _stoneArray }; // _stoneArray._mainStoneList
    const list = [...stoneDetailsArray];
    list.splice(i, 1, newArray);
    setStoneDetailsArray(list);
  };

  const dynamicAttributeValueChange = (value, index, id) => {
    const list = [...textboxValue];
    let singleCompo = list[index] || {};
    list[index] = { ...singleCompo, [id]: value || "" };
    settextboxValue(list);
    if (inputList[index]["isNewDesign"] == false)
      onChangeCheckIsInStock(index, list);
  };

  const onChangeCheckIsInStock = (index, list) => {
    if (
      inputList[index].isRowMaterial == false &&
      inputList[index].productName.id == ""
    ) {
      return;
    }
    let productDetails = [];
    productDetails[0] = {
      designDefinationId: inputList[index].productName.id,
      categoryId: inputList[index].categoryId,
      isRowMaterial: inputList[index].isRowMaterial,
      isNewDesign: inputList[index].isNewDesign,
    };
    let attributeValue = [];
    attributeValue[0] = list[index];
    dispatch(checkProductInStock(productDetails, attributeValue));
    setRenderIndex(index);
  };

  const onCategoryChange = (categoryId, i) => {
    const listInput = [...inputList];

    listInput[i]["productName"] = { id: "", productDesignNo: "" };
    dispatch(fetchAllProducts(categoryId));
    const textValueList = [...textboxValue];
    textValueList[i] = {};
    settextboxValue(textValueList);
    ////
    let singleRecord = _linkCat_Att.filter(
      (obj) => obj.categoryId == categoryId
    ) || [{ categoryAttId: [{}] }];
    if (singleRecord.length > 0)
      attributeCompoArray = JSON.parse(singleRecord[0].categoryAttId) || [];
    else attributeCompoArray = [];

    let newArray = [];
    newArray = { catgeoryAttributeCompo: attributeCompoArray };
    const list = [...attributeValueList];
    list.splice(i, 1, newArray);
    setAttributeValueList(list);
    ////
    /// isRowMaterial
    const cList = [...inputList];
    let isRowMaterial = false,
      selectedCategory;
    if (_category) {
      selectedCategory = _category.filter((record) => record.id == categoryId);
    }
    if (selectedCategory.length > 0)
      isRowMaterial = Boolean(selectedCategory[0].isRowMaterial);

    cList[i]["isRowMaterial"] = isRowMaterial;
    setInputList(cList);
    ///
  };

  const renderProductStoneDetailsFunc = (_passArray, stoneSide, rowIndex) => {
    let renderProductStones = [],
      renderArray = [];

    // if (stoneDetailsArray.length <= 0) {
    //     return
    // } else
    //     renderArray = stoneDetailsArray[rowIndex].stoneDetails || []

    if (_passArray && stoneSide == "MainStone:")
      renderArray = _passArray._mainStoneList || [];
    else if (_passArray && stoneSide == "SideStone:")
      renderArray = _passArray._sideStoneList || [];

    // renderArray = _passArray.stoneDetails || []

    renderProductStones = renderArray.map((row, i) => {
      let stonTypeValue;
      if (textboxValue.length > 0)
        stonTypeValue = textboxValue[rowIndex]["stype" + row.id.toString()];
      return (
        <div key={i}>
          <Grid container spacing={3} key={row.id.toString()}>
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {stoneSide}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Shape"
                name={row.id.toString()}
                value={row.shape || ""}
                variant="outlined"
                aria-readonly
              />
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="size"
                name={row.id.toString() + "sz"}
                value={row.size || ""}
                variant="outlined"
                aria-readonly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              ></Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Quantity"
                name={row.id.toString() + "qty"}
                value={row.quantity || ""}
                variant="outlined"
                aria-readonly
              />
            </Grid>

            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                name={"stype" + row.id.toString()}
                label="Select type"
                onChange={(e) => {
                  dynamicAttributeValueChange(
                    e.target.value,
                    rowIndex,
                    "stype" + row.id.toString()
                  );
                }}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={stonTypeValue || 0}
              >
                {stoneTypedefultOption}
                {stoneTypeDropdownRender}
              </TextField>
            </Grid>
          </Grid>
        </div>
      );
    });
    return renderProductStones;
  };

  const renderNewAttributeFunc = (rowIndex) => {
    let newAttributeRender = [],
      getArrayObject = [],
      renderTextBox = [],
      inputVal = "";

    if (_linkCat_Att.length > 0) {
      if (attributeValueList.length <= rowIndex) {
        return;
      }

      getArrayObject = attributeValueList;
      renderTextBox = getArrayObject[rowIndex].catgeoryAttributeCompo || [];

      newAttributeRender = renderTextBox.map((row, renderIndex) => {
        let attValue = _attributeValues.filter((obj) => obj.id == row.id) || [];
        if (attValue.length)
          attValue = JSON.parse(attValue[0].attributeValue) || [];

        if (textboxValue.length > rowIndex)
          inputVal = textboxValue[rowIndex][row.id.toString()];

        return (
          <Grid
            key={row.id.toString()}
            container
            spacing={3}
            alignItems="center"
            className={classes.nomargin}
          >
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {row.attributeName} :
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {row.datatype == "SingleSelection" && (
                <Autocomplete
                  freeSolo
                  name={row.id.toString()}
                  value={inputVal || { id: "", value: "" }}
                  onChange={(event, value) => {
                    dynamicAttributeValueChange(
                      value,
                      rowIndex,
                      row.id.toString()
                    );
                  }}
                  options={attValue}
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={row.attributeName}
                      variant="outlined"
                    />
                  )}
                />
              )}
              {row.datatype == "MultiSelection" && (
                <Autocomplete
                  multiple
                  name={row.id.toString()}
                  options={attValue}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={inputVal || []}
                  input={<Input />}
                  onChange={(event, value) => {
                    dynamicAttributeValueChange(
                      value,
                      rowIndex,
                      row.id.toString()
                    );
                  }}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.value}
                      </React.Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={row.attributeName}
                      placeholder={row.attributeName}
                    />
                  )}
                />
              )}
              {row.datatype == "FreeText" && (
                <TextField
                  label={row.attributeName}
                  name={row.id.toString()}
                  fullWidth
                  value={inputVal || ""}
                  onChange={(e) => {
                    dynamicAttributeValueChange(
                      e.target.value,
                      rowIndex,
                      row.id.toString()
                    );
                  }}
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
        );
      });
    }
    return newAttributeRender;
  };

  const saveProductOrderList = () => {
    // dispatch(createSellProductListDetails(platformAccountId=1, platformOrderNo=21212, inputList, navigate))
    //

    if (buttonText === "Update") {
      dispatch(
        updateSellProductListDetails(
          commanValue.platformAccountId,
          commanValue.platformOrderNoDropDown,
          inputList,
          textboxValue,
          navigate
        )
      );
    } else {
      dispatch(
        createSellProductListDetails(
          commanValue.platformAccountId,
          commanValue.platformOrderNoDropDown,
          inputList,
          textboxValue,
          navigate
        )
      );
    }
  };

  const viewStockInProducts = (index) => {
    setStockInOpenPopup(true);
    onChangeCheckIsInStock(index, textboxValue);
  };

  const resetAttributeValue = (index) => {
    const list = [...textboxValue];
    list[index] = {};
    settextboxValue(list);
    handleInputChangeDiffComponent("isProductSelected", false, renderIndex);
  };

  let newProductBoxRender = "";
  newProductBoxRender = inputList.map((x, i) => {
    return (
      <div key={i}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Product Category :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Product Category"
              name="categoryId"
              onChange={(e) => {
                onCategoryChange(e.target.value || { categoryId: 0 }, i);
                handleInputChange(e, i);
              }}
              required
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={x.categoryId || 0}
            >
              {cateDefultOption}
              {cateDropdownRender}
            </TextField>
          </Grid>
        </Grid>
        {x.categoryId ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                Design :
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isNewDesign"
                    checked={x.isNewDesign}
                    size="small"
                    onChange={(e, value) =>
                      handleInputChangeDiffComponent(
                        "isNewDesign",
                        Boolean(value),
                        i
                      )
                    }
                    color="primary"
                  />
                }
                label="Is new design?"
              />
            </Grid>

            {x.isRowMaterial || x.isNewDesign ? (
              ""
            ) : (
              <Grid item md={3} xs={12}>
                <Autocomplete
                  disabled={x.isRowMaterial || x.isNewDesign}
                  name="productName"
                  freeSolo
                  value={x.productName || { id: "", productDesignNo: "" }}
                  onChange={(event, value) => {
                    if (value == null) {
                      value = { id: "", productDesignNo: "" };
                    } else dispatch(getProductStoneDetails(value.id || ""));

                    handleInputChangeDiffComponent("productName", value, i);
                    setRenderIndex(i);
                  }}
                  options={_allProductList}
                  getOptionLabel={(option) =>
                    option ? option.productDesignNo : {}
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="product search by design"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {/* {x.isRowMaterial || x.isNewDesign ?"": (
            <Box display="flex" p={2}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setProductOpenPopup(true)}
              >
                <AddIcon />
              </Button>
            </Box>
          )} */}
          </Grid>
        ) : (
          ""
        )}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Quantity :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Quantity"
              name="quantity"
              fullWidth
              type="number"
              variant="outlined"
              onChange={(e) => handleInputChange(e, i)}
              value={x.quantity || 1}
            />
          </Grid>
        </Grid>
        {x.productName.id != ""
          ? renderProductStoneDetailsFunc(
              stoneDetailsArray[i].stoneDetails,
              "MainStone:",
              i
            )
          : ""}
        {x.productName.id != ""
          ? renderProductStoneDetailsFunc(
              stoneDetailsArray[i].stoneDetails,
              "SideStone:",
              i
            )
          : ""}
        <Grid alignItems="center" container spacing={3}>
          {renderNewAttributeFunc(i)}
        </Grid>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Product Available :
            </Typography>
          </Grid>
          <Grid item md={3} xs={12} hidden={x.isProductSelected}>
            <Button
              className="typography_margin"
              color="primary"
              size="small"
              name="isProductSelected"
              variant="contained"
              onClick={(e) => viewStockInProducts(i)}
            >
              {" "}
              {x.isProductSelected
                ? "Selected"
                : _InStockProduct.length + " Product Available"}{" "}
            </Button>
          </Grid>

          <Grid item md={3} xs={6} hidden={!x.isProductSelected}>
            <Button
              color="primary"
              size="small"
              name="resetProductDetails"
              variant="contained"
              onClick={(e) => resetAttributeValue(i)}
            >
              {" "}
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Source of stock :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="sourceOrder"
              fullWidth
              label="Source of stock"
              onChange={(e) => {
                handleInputChange(e, i);
              }}
              required
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={_InStockProduct.length > 0 ? 2 : x.sourceOrder || 0}
              // defaultValue={_InStockProduct.length==0?1:0}
            >
              {/* {defultOption} */}
              {dropdownRender}
            </TextField>
          </Grid>
        </Grid>
        {x.sourceOrder == 1 && _InStockProduct.length == 0 ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                Manufacturer Details :
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Autocomplete
                hidden={x.sourceOrder == 1 ? false : true}
                name="manufacturerName"
                freeSolo
                value={x.manufacturerName || { id: "", manufacturerName: "" }}
                onChange={(event, value) => {
                  handleInputChangeDiffComponent("manufacturerName", value, i);
                }}
                options={_allManufacturerData}
                getOptionLabel={(option) =>
                  option ? option.manufacturerName : {}
                }
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Search Manufacturer"
                    variant="outlined"
                  /> // margin='normal'
                )}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              hidden={x.sourceOrder == 1 ? false : true}
            >
              <TextField
                label="Handling Days"
                name="handlingDay"
                fullWidth
                type="number"
                value={x.handlingDay || ""}
                onChange={(e) => handleInputChange(e, i)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Add Note :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Add Note"
              name="note"
              multiline
              rows={5}
              fullWidth
              onChange={(e) => handleInputChange(e, i)}
              value={x.note}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="flex-end" p={2}>
              {0 === i && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleAddClick}
                >
                  Add More
                </Button>
              )}
              &ensp;&ensp;
              {/* {inputList.length !== 1 && <Button color="primary" size='small' */}
              {inputList.length !== 1 && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        {inputList.length !== 1 && inputList.length - 1 !== i && (
          <Divider className={classes.divider} />
        )}
      </div>
    );
  });

  const validate = () => {
    let isError = false;
    // const errorList = { ...formErrors }

    // if (values.platformName == '' || values.platformName == 0) {
    //     isError = true;
    //     errorList["platformName"] = "Please select platform name"
    // }

    // if (_InStockProduct.length <= 0) {

    // }

    // if (isError)
    //     setFormErrors(errorList)

    return isError;
  };

  return (
    <Page title="Add Product of Order">
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues=""
          validationSchema={Yup.object().shape({
            // qty: Yup.string().required('Quantity name is required!'),
            // price: Yup.string().required('price name is required!'),
          })}
          onSubmit={(values, { resetForm }) => {
            let err = validate();
            if (!err) {
              saveProductOrderList();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Add Product of Order
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Platform Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled
                        name="platformAccountId"
                        fullWidth
                        label="Platform Name"
                        onChange={(e, value) => {
                          commanInputValueChange(
                            "platformAccountId",
                            e.target.value
                          );
                        }}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={commanValue.platformAccountId}
                      >
                        {defultPlatform}
                        {platformAccountRender}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled
                        name="platformAccountId"
                        fullWidth
                        label="Email ID"
                        onChange={(e, value) => {
                          commanInputValueChange(
                            "platformAccountId",
                            e.target.value
                          );
                        }}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={commanValue.platformAccountId}
                      >
                        {defultEmail}
                        {platformEmailAccountRender}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <Autocomplete
                        disabled
                        freeSolo
                        name="platformOrderNoDropDown"
                        options={_allOrderList}
                        value={
                          commanValue.platformOrderNoDropDown || {
                            id: "",
                            platformOrderNo: "",
                          }
                        }
                        getOptionLabel={(option) =>
                          option ? option.platformOrderNo : ""
                        }
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, value) => {
                          commanInputValueChange(
                            "platformOrderNoDropDown",
                            value
                          );
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="Search platform Order No"
                              variant="outlined"
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Divider className={classes.divider} />

                  {newProductBoxRender}
                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    {_storeDetails !== "" ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={backToFirstForm}
                      >
                        Back
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={backToList}
                      >
                        Back
                      </Button>
                    )}
                    <Button color="primary" variant="contained" type="submit">
                      {buttonText}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
      <Popup
        key="productDesignPopup"
        title="Add New Design Details"
        openPopup={productOpenPopup}
        setOpenPopup={setProductOpenPopup}
      >
        <ProductCreate
          isShowBackBtn={false}
          setOpenPopup={setProductOpenPopup}
          isPopup={productOpenPopup}
        />
      </Popup>
      <Popup
        title="Product List"
        key="stockInPopup"
        openPopup={stockInOpenPopup}
        setOpenPopup={setStockInOpenPopup}
      >
        <StockInList
          key="0010"
          TableData={_InStockProduct}
          setOpenPopup={setStockInOpenPopup}
          isPopup={stockInOpenPopup}
          fillAttributeValue={fillAttributeValue}
        />
      </Popup>
    </Page>
  );
};

AddProductStock.propTypes = {
  className: PropTypes.string,
};

export default AddProductStock;
