import * as ACTION from "./_order_Type";
import {
  createNewDetailsPost,
  fetchDataMethod,
} from "../../utils/actionMethod";

// export const issueStoneMakerDispatcher = (data, reloadData) => {
//   let status = 'INSTONEISSUEMAKER'
//   return async (dispatch) => {
//     if (reloadData) {
//       let result = await createNewDetailsPost("/api/make-stone-issue-maker", data);
//       dispatch({
//         type: ACTION.STONE_ISSUE_MAKER,
//         payload: result.data,
//       });
//       let result_orders = await fetchDataMethod(
//         "/api/get-stone-issue-orders?status=" + status
//       );
//       dispatch({
//         type: ACTION.GET_PENDING_ISSUE_STONE_ORDERS,
//         payload: result_orders.data,
//       });
//     } else {
//       let result = await fetchDataMethod(
//         "/api/get-stone-issue-orders?status=" + status
//       );
//       dispatch({
//         type: ACTION.GET_PENDING_ISSUE_STONE_ORDERS,
//         payload: result.data,
//       });
//     }
//   };
// };

export const issueStoneMakerDispatcher = (data, navigate, reloadData, tab) => {
  if (tab == undefined) {
    tab = "INSTONEISSUEMAKER";
  }
  return async (dispatch) => {
    if (reloadData) {
      let result = await createNewDetailsPost("/api/make-stone-issue-maker", data);
      dispatch({
        type: ACTION.STONE_ISSUE_MAKER,
        payload: result.data,
      });
      let result_orders = await fetchDataMethod(
        "/api/get-stone-issue-orders?status=" + tab
      );
      dispatch({
        type: ACTION.GET_PENDING_ISSUE_STONE_ORDERS,
        payload: result_orders.data,
      });
    }else if(tab == "STONEISSUEHISTORY"){
      let result = await fetchDataMethod(
        "/api/get-issue-stone-details"
      ); 
      dispatch({
        type: ACTION.GET_ISSUE_STONE_DETAILS,
        payload: result.data,
      });
    } 
    else {
      let result = await fetchDataMethod(
        "/api/get-stone-issue-orders?status=" + tab
      );
      dispatch({
        type: ACTION.GET_PENDING_ISSUE_STONE_ORDERS,
        payload: result.data,
      });
    }
  };
};
