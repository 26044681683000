/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Edit as EditIcon, Trash2 as DeleteIcon } from "react-feather";
import {
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";

import Page from "src/components/Page";
import {
  createPlatformName,
  updatePlatformName,
  fetchPltformNameList,
  deletePlatformName,
} from "./_platformAccount_Action";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
}));

const PlatformNameCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  //let _editData = useSelector(state => state.platfromAccount._editedName);
  let platformNameList = useSelector(
    (state) => state.platfromAccount._allPlatfromNames
  );

  const [platformName, setPlatformName] = useState("");
  const [platformId, setPlatformId] = useState("");

  const [platformNameError, setPlatformNameError] = useState("");
  const [buttonAction, setButtonAction] = useState("Create");

  //const [platformNameList, setPlatformNameList] = useState([]);

  useEffect(() => {
    dispatch(fetchPltformNameList());
    // eslint-disable-next-line
  }, []);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const AddPlatformNavigate = () => {
    navigate("../platformAccount/create", { replace: false });
  };
  const editDetails = async (id, data) => {
    setPlatformNameError("");
    //await dispatch(editPlatformName(id, data));
    setPlatformId(id);
    setPlatformName(data.platformName);
    setButtonAction("Update");
  };

  const catIdChange = (e) => {
    setPlatformName(e.target.value);
    setPlatformNameError("");
  };

  const deleteDetails = async (id) => {
    dispatch(deletePlatformName(id));
  };

  const validate = () => {
    let isError = false;
    setPlatformNameError("");
    if (platformName.length < 1) {
      isError = true;
      setPlatformNameError("Please enter platform Name");
    }
    return isError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      if (buttonAction == "Update") {
        await dispatch(updatePlatformName(platformName, platformId));
        setButtonAction("Create");
      } else {
        await dispatch(createPlatformName(platformName, navigate));
      }
      setPlatformName("");
    }
  };

  return (
    <Page
      //className={classes.root}
      title="Platform Name"
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={(e) => onSubmit(e)}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Platform Name
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Add Platform :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(platformNameError)}
                        fullWidth
                        helperText={platformNameError}
                        label="Add Platform"
                        name="platformName"
                        onChange={(e) => {
                          catIdChange(e);
                        }}
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={platformName}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent display="flex" p={2}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={AddPlatformNavigate}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        // disabled={isSubmitting}
                      >
                        {buttonAction}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Grid>
          <Grid />
          <Grid item xs={12}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category Name</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {platformNameList.slice(0, limit).map((row) => (
                        <TableRow hover key={row.id}>
                          <TableCell>{row.platformName}</TableCell>

                          <TableCell width="5%">
                            <IconButton
                              size="small"
                              onClick={() => editDetails(row.id, row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>

                          <TableCell width="5%">
                            <IconButton
                              size="small"
                              onClick={() => deleteDetails(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>

              <TablePagination
                component="div"
                count={-1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

PlatformNameCreate.propTypes = {
  className: PropTypes.string,
};

export default PlatformNameCreate;
