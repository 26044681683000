import {
  ADD_MANUFACTURER_DETAILS,
  UPDATE_MANUFACTURER_DETAILS,
  FETCH_MANUFACTURER_DETAILS_LIST,
  EDIT_MANUFACTURER_DETAILS,
  DELETE_MANUFACTURER_DETAILS,
} from "./_manufacturer_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";
import {
  createNewDetailsPost,
  fetchDataMethod,
} from "../../utils/actionMethod";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const newfetchAllManufacturers = (result) => {
  return async (dispatch) => {
    let result = await fetchDataMethod("/api/get-manufacturer");

    dispatch({
      type: FETCH_MANUFACTURER_DETAILS_LIST,
      listview: result.data,
    });
  };
};

export const createNewDetails = (values, navigate) => {
  let formData = {
    manufacturername: values.mname,
    mobile: parseInt(values.mphone) || 0,
    address: values.maddress,
    email: values.memail,
    gstno: values.mgst,
    bankname: values.mbankname,
    ifsc: values.mifsc,
    accountno: values.maccountno,
    billNumberPrifix: values.prifix,
    billNumberStartFrom: values.billstratfrom || 0,
  };
  return async (dispatch) => {
    let result = await createNewDetailsPost(
      "/api/add-manufacturer-details",
      formData,
      navigate,
      "../manufacturer"
    );
    dispatch({
      type: ADD_MANUFACTURER_DETAILS,
      payload: result.data,
    });
    if (result.status) {
      navigate("../manufacturer", { replace: false });
    }
  };
};

export const createNewDetails222 = (values, navigate) => {
  let formData = {
    manufacturername: values.mname,
    mobile: parseInt(values.mphone) || 0,
    address: values.maddress,
    email: values.memail,
    gstno: values.mgst,
    bankname: values.mbankname,
    ifsc: values.mifsc,
    accountno: values.maccountno,
    billNumberPrifix: values.prifix,
    billNumberStartFrom: values.billstratfrom || 0,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-manufacturer-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_MANUFACTURER_DETAILS,
            payload: result,
          });
          navigate("../manufacturer", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchAllManufacturers = (page, limit) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-manufacturer",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_MANUFACTURER_DETAILS_LIST,
            listview: result,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MANUFACTURER_DETAILS_LIST,
          listview: [],
        });
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_MANUFACTURER_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-manufacturer-details",
      method: "POST",
      data: {
        manufacturerid: r_id,
        manufacturername: updateData.mname,
        address: updateData.maddress,
        mobile: updateData.mphone || 0,
        email: updateData.memail,
        gstno: updateData.mgst,
        bankname: updateData.mbankname,
        ifsc: updateData.mifsc,
        accountno: updateData.maccountno,
        billNumberPrifix: updateData.prifix,
        billNumberStartFrom: updateData.billstratfrom || 0,
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_MANUFACTURER_DETAILS,
            payload: result[0],
          });
          navigate("../manufacturer", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-manufacturer-details",
      method: "POST",
      data: { manufacturerid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          //let message = res.data.message
          dispatch({
            type: DELETE_MANUFACTURER_DETAILS,
            payload: id,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
};
