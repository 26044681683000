import React, { useEffect } from 'react'
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllDetails } from './_order_Action';
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  TableHead,
  Table,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ReturnList = ({ className, platId, dateRange, tab, filterFn, ...rest }) => {

  const dispatch = useDispatch();
  let allFetchData = useSelector((state) => state.orderProfit._returnDetails) || [];
  useEffect(() => {
    dispatch(fetchAllDetails(platId, dateRange, tab));
  }, [platId, dateRange, tab]);

  const classes = useStyles();
  const {
    TblPagination,
  } = commanTable(allFetchData, '', filterFn);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Order Date
                </TableCell>
                <TableCell align="center">
                  Customer Name
                </TableCell>
                <TableCell align="center">
                  Selling NetPrice
                </TableCell>
                <TableCell align="center">
                  Return Money
                </TableCell>
                <TableCell align="center">
                  Revise Paypal Fees
                </TableCell>
                <TableCell align="center">
                  Revise Party Fees
                </TableCell>
                <TableCell align="center">
                  Revise Ads Fees
                </TableCell>
                <TableCell align="center">
                  Revise Tax
                </TableCell>
              </TableRow>
            </TableHead>
            {allFetchData.length > 0 ? (
              <TableBody>
                {
                  allFetchData.map((row, i) => (
                    <TableRow
                      hover key={i}
                    >
                      <TableCell align="center">{row.orderDate}</TableCell>
                      <TableCell align="center">{row.customerName}</TableCell>
                      <TableCell align="center">{row.netPrice}</TableCell>
                      <TableCell align="center">{row.returnMoney}</TableCell>
                      <TableCell align="center">{row.revisePaypalFees}</TableCell>
                      <TableCell align="center">{row.revisePartyFees}</TableCell>
                      <TableCell align="center">{row.reviseAdsFees}</TableCell>
                      <TableCell align="center">{row.reviseTax}</TableCell>
                    </TableRow>
                  ))
                }

              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  )
}

ReturnList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};
export default ReturnList;