import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    datatypeName: "SingleSelection",
  },
  {
    id: uuid(),
    datatypeName: "MultiSelection",
  },
  {
    id: uuid(),
    datatypeName: "FreeText",
  },
];
