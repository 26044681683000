/*eslint eqeqeq: "off"*/
import React,{useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Checkbox,
  TableHead,
  Table,
} from "@material-ui/core";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const customerIds = []

const CustomerList = ({ className, TableData, filterFn,setCustoIds, ...rest }) => {
  
const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

const handleSelectAll = (event) => { 
  
  let newSelectedCustomerIds;
  if (event.target.checked) {
    newSelectedCustomerIds = recordsAfterPagingAndSorting().map((customer) => customer.id);
  } else {
    newSelectedCustomerIds = [];
  }

  setSelectedCustomerIds(newSelectedCustomerIds);
  setCustoIds(newSelectedCustomerIds)

  customerIds.push(...newSelectedCustomerIds)

}
const handleSelectOne = (event,id) => { 

  const selectedIndex = selectedCustomerIds.indexOf(id);
  let newSelectedCustomerIds = [];

  if (selectedIndex === -1) {
    newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
  } else if (selectedIndex === 0) {
    newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
  } else if (selectedIndex === selectedCustomerIds.length - 1) {
    newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelectedCustomerIds = newSelectedCustomerIds.concat(
      selectedCustomerIds.slice(0, selectedIndex),
      selectedCustomerIds.slice(selectedIndex + 1)
    );
  }
  setSelectedCustomerIds(newSelectedCustomerIds);
  setCustoIds(newSelectedCustomerIds)

  customerIds.push(id)

 }

  const classes = useStyles();
  const {
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData,'', filterFn);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === recordsAfterPagingAndSorting().length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < recordsAfterPagingAndSorting().length
                    }
                    onChange={handleSelectAll}
                  />
                  Select All
                </TableCell>
                <TableCell>
                Customer Name
                </TableCell>
                <TableCell>
                Mobile Number
                </TableCell>
                <TableCell>
                Email Address
                </TableCell>
                <TableCell>
                Customer Address
                </TableCell>
              </TableRow>
            </TableHead>
            {TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row,i) => (
                  <TableRow 
                  hover key={i}
                  selected={selectedCustomerIds.indexOf(row.id) !== -1}
                  >
                    <TableCell>
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(row.id) !== -1}
                      onChange={(event) => handleSelectOne(event, row.id)}
                      value="true"
                    />
                    </TableCell>
                    <TableCell>{row.customerName}</TableCell>
                    <TableCell>{row.customerMobileNo}</TableCell>
                    <TableCell>{row.customerEmail}</TableCell>
                    <TableCell>{row.customerAddress}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

CustomerList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};
export {customerIds};
export default CustomerList;
