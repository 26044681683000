import {
  ADD_LINK_ATT_CAT_DETAILS,
  FETCH_LINK_ATT_CAT_LIST,
  EDIT_LINK_ATT_CAT_DETAILS,
  DELETE_LINK_ATT_CAT_DETAILS,
  RESET_LINK_CATE_ATT,
} from "./_link_Cat_Att_Type";
import { config } from "../../Constants";
const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const createNewDetails = (values, navigate, isPopup) => {
  let formData = {
    attributeids: values.attributeids,
    categoryid: parseInt(values.categoryId),
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-link-category-attribute",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let message = res.data.message;
          dispatch({
            type: ADD_LINK_ATT_CAT_DETAILS,
            message: message,
          });
          if (!isPopup) navigate("../linkcat_att", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const fetchAllCategoryAttribute = (catId) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL + "/api/get-link-category-attribute?categoryid=" + catId,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch(getAllDetails(result));
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
      });
  };
};

export const resetLinkState = () => {
  return {
    type: RESET_LINK_CATE_ATT,
  };
};

export const getAllDetails = (data) => {
  return {
    type: FETCH_LINK_ATT_CAT_LIST,
    listview: data,
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_LINK_ATT_CAT_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-link-category-attribute",
      method: "POST",
      data: {
        linkid: r_id,
        attributeids: updateData.attributeids,
        categoryid: parseInt(updateData.categoryId),
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("../linkcat_att", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id, catid) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-link-category-attribute",
      method: "POST",
      data: { linkid: id, categoryid: catid, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          // let id = res.data.data[0].id
          // let message = res.data.message
          //dispatch(fetchAllCategoryAttribute())
          dispatch({
            type: DELETE_LINK_ATT_CAT_DETAILS,
            payload: id,
          });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
};
