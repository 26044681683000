/*eslint eqeqeq: "off"*/
import * as Action from "./_stock_Details_Type";

const initialState = {
  _editedDetails: "",
  _id: "",
  _allDetails: [],
};

const stockDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.FIND_ALL_PRODUCTS:
      return {
        ...state,
        _allDetails: action.payload,
        _editedDetails: "",
      };
    case Action.DETAIL_SINGLE_PRODUCT:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    default:
      return state;
  }
};

export default stockDetailsReducer;
