/*eslint eqeqeq: "off"*/
import {
  ADD_SUPPLIER_DETAILS,
  UPDATE_SUPPLIER_DETAILS,
  FETCH_SUPPLIER_DETAILS_LIST,
  EDIT_SUPPLIER_DETAILS,
  CLEAR_SUPPLIER_EDITED_DATA,
  DELETE_SUPPLIER_DETAILS,
} from "./_supplier_Type";

const initialState = {
  _editedDetails: "",
  _allDetails: [],
  _id: "",
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUPPLIER_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case EDIT_SUPPLIER_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case UPDATE_SUPPLIER_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case FETCH_SUPPLIER_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
      };
    case DELETE_SUPPLIER_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
        _id: action.payload,
      };

    case CLEAR_SUPPLIER_EDITED_DATA:
      return {
        ...state,
        _editedDetails: "",
      };
    default:
      return state;
  }
};

export default supplierReducer;
