/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon, Trash2 as DeleteIcon } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteSingleDetails, editSingleDetails } from "./_category_Action";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import DeleteDialog from "src/components/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const headCells = [
  { id: "categoryName", label: "Product Category" },
  { id: "isRowMaterial", label: "Row Material?" },
  { id: "edit", label: "Edit", disableSorting: true },
  { id: "delete", label: "Delete", disableSorting: true },
];

const CategoryList = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState("");

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData, headCells, filterFn);

  const editDetails = (id, data) => {
    dispatch(editSingleDetails(id, data));
    navigate("./edit", { replace: false });
  };
  const openDialog = (id) => {
    setDeleteRecord(id);
    setDialogOpen(!dialogopen);
  };
  const confirmDialog = () => {
    dispatch(deleteSingleDetails(deleteRecord));
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />
            {TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell>{row.categoryName}</TableCell>
                    <TableCell>{row.isRowMaterial ? "Yes" : "No"}</TableCell>

                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => editDetails(row.id, row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => openDialog(row.id)}
                      >
                        <DeleteIcon />
                        {deleteRecord == row.id ? (
                          <DeleteDialog
                            openDialog={openDialog}
                            confirmDialog={confirmDialog}
                            dialogopen={dialogopen}
                          />
                        ) : (
                          ""
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

CategoryList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};

export default CategoryList;
