import {
  ADD_SUPPLIER_DETAILS,
  UPDATE_SUPPLIER_DETAILS,
  FETCH_SUPPLIER_DETAILS_LIST,
  EDIT_SUPPLIER_DETAILS,
  DELETE_SUPPLIER_DETAILS,
  CLEAR_SUPPLIER_EDITED_DATA,
} from "./_supplier_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");

export const createNewDetails = (values, navigate) => {
  let formData = {
    suppliername: values.sname,
    mobile: parseInt(values.sphone) || 0,
    address: values.saddress,
    email: values.semail,
    gstno: values.sgstno,
    bankname: values.sbankname,
    ifsc: values.sifsc,
    accountno: values.saccountno,
    prifix: values.sprifix,
    startfrom: parseInt(values.sbillstratfrom) || 0,
  };
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-supplier-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;
          dispatch({
            type: ADD_SUPPLIER_DETAILS,
            payload: result,
          });
          navigate("../supplier", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const fetchAllSupplierDetails = (supplierid) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-suppliers?supplierid=" + supplierid,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_SUPPLIER_DETAILS_LIST,
            listview: result,
          });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SUPPLIER_DETAILS_LIST,
          listview: [],
        });
        toast.error(err.response.data.message);
      });
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_SUPPLIER_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const clearEditedDetails = () => {
  return {
    type: CLEAR_SUPPLIER_EDITED_DATA,
  };
};

export const updateDetails = (updateData, r_id, navigate) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-supplier-details",
      method: "POST",
      data: {
        supplierid: r_id,
        suppliername: updateData.sname,
        address: updateData.saddress,
        mobile: parseInt(updateData.sphone) || 0,
        email: updateData.semail,
        gstno: updateData.sgstno,
        bankname: updateData.sbankname,
        ifsc: updateData.sifsc,
        accountno: updateData.saccountno,
        prifix: updateData.sprifix,
        startfrom: parseInt(updateData.sbillstratfrom) || 0,
        isenable: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: UPDATE_SUPPLIER_DETAILS,
            payload: result[0],
          });
          navigate("../supplier", { replace: false });
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-supplier-details",
      method: "POST",
      data: { supplierid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let message = res.data.message;

          dispatch({
            type: DELETE_SUPPLIER_DETAILS,
            payload: id,
            msg: message,
          });
          //toast.error(message);
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
