// import React, { useEffect, useState } from "react";
// import { Container, makeStyles, Box } from "@material-ui/core";
// import Page from "src/components/Page";
// import Toolbar from "./Toolbar";
// import OrderList from "./OrderList";
// import { useSelector, useDispatch } from "react-redux";
// import { issueStoneMakerDispatcher } from "./_order_Action";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: "100%",
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3),
//   },
// }));

// const Iss_stone = () => {
//   const classes = useStyles();

//   const [filterFn, setParentFilterFn] = useState({
//     fn: (items) => {
//       return items;
//     },
//   });

//   const dispatch = useDispatch();
//   let listData = useSelector((state) => state.issueStoneReturn._allDetails.listData) || [];
//   let productData = useSelector((state) => state.issueStoneReturn._allDetails.productStockInfo) || [];

//   useEffect(() => {
//     dispatch(issueStoneMakerDispatcher([], false));
//   }, []);

//   let viewComponent;
//   viewComponent = <OrderList TableData={listData} ProductData={productData} filterFn={filterFn}/> 

//   return (
//     <Page className={classes.root} title="Customer">
//       <Container maxWidth={false}>
//         <Toolbar setParentFilterFn={setParentFilterFn}/>
//         <Box mt={3}>{viewComponent}</Box>
//       </Container>
//     </Page>
//   );
// };

// export default Iss_stone;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* eslint no-unused-vars : "off" */
import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import { issueStoneMakerDispatcher } from "./_order_Action";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrderList from './OrderList'
import IssueStoneDetails from "./IssueStoneDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function IssueRerurns() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  let viewComponent;
  let viewIssueStoneDetails;
  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  let listData = useSelector((state) => state.issueStoneReturn._allDetails.listData) || [];
  let productData = useSelector((state) => state.issueStoneReturn._allDetails.productStockInfo) || [];
  let _allDetails = useSelector((state) => state.issueStoneReturn._allDetails)

  useEffect(() => {
    dispatch(issueStoneMakerDispatcher([], navigate, false));
  }, []);

  viewComponent = (
    <OrderList TableData={listData} ProductData={productData} filterFn={filterFn} />
  );

  viewIssueStoneDetails = (
    <IssueStoneDetails TableData={_allDetails} filterFn={filterFn} />
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabName = (tab) => {
    dispatch(issueStoneMakerDispatcher([], navigate, false, tab));
  };
  return (
    <Page className={classes.root} title="Issue Stone Details">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} />
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="order tabs">
            <Tab
              label="IN STONE ISSUE"
              {...a11yProps(0)}
              onClick={() => getTabName("INSTONEISSUEMAKER")}
            />
            <Tab
              label="Stone Issue History"
              {...a11yProps(1)}
              onClick={() => getTabName("STONEISSUEHISTORY")}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {productData && listData !== "" ? <Box mt={3}>{viewComponent}</Box> : ""}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {_allDetails !== "" ?  <Box mt={3}>{viewIssueStoneDetails}</Box> : ""}
        </TabPanel>
      </Container>
    </Page>
  );
}

