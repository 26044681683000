import {
  FETCH_OVER_DUE_ORDERS
} from "./_overDue_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";

var REACT_APP_URL = config.url.API_URL;
const axios = require("axios");
const token = localStorage.getItem("jwtToken");
export const fetchAllDetails = () => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-due-orders",
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data;
          dispatch(getAllDetails(result));
        } else {
          toast("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch(getAllDetails([]));
        toast.error(err.response.data.message);
      });
  };
};

export const getAllDetails = (data) => {
  return {
    type: FETCH_OVER_DUE_ORDERS,
    listview: data,
  };
};