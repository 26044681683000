/*eslint eqeqeq: "off"*/
import {
  ADD_CAT_DETAILS,
  FETCH_ALL_CATEGORY_DETAILS_LIST,
  FETCH_ONLY_PRODUCT_CATEGORY_LIST,
  EDIT_CATEGORY_DETAILS,
  UPDATE_CATEGORY_DETAILS,
  DELETE_CATEGORY_DETAILS,
} from "./_category_Type";
const initialState = {
  _editedDetails: "",
  _msg: "",
  _allDetails: [],
  _onlyProductCategory: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CAT_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };
    case EDIT_CATEGORY_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
      };
    case UPDATE_CATEGORY_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case FETCH_ALL_CATEGORY_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
        _msg: "",
      };
    case FETCH_ONLY_PRODUCT_CATEGORY_LIST:
      return {
        ...state,
        _editedDetails: "",
        _onlyProductCategory: action.listview,
        _msg: "",
      };
    case DELETE_CATEGORY_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
      };

    default:
      return state;
  }
};

export default categoryReducer;
