import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { createNewDetails, updateDetails } from "./_manufacturer_Action";
import { useDispatch, useSelector } from "react-redux";
import ToastNotify from "../../components/ToastNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
})); /*  */

const ManufacturerCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _editData = useSelector((state) => state.manufacturer._editedDetails);

  // useEffect(() => {
  //     dispatch(editSingleDetails(1, ''));
  // }, [])

  var initialdata = {
    mname: "",
    mphone: "",
    memail: "",
    mgst: "",
    maddress: "",
    mbankname: "",
    mifsc: "",
    maccountno: "",
    billstratfrom: "",
    prifix: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      mname: _editData.manufacturerName,
      mphone: _editData.manufacturerMobileNo,
      maddress: _editData.manufacturerAddress,
      memail: _editData.manufacturerEmail,
      mgst: _editData.manufacturerGstno,
      mbankname: _editData.manufacturerBankName,
      mifsc: _editData.manufacturerIfscNo,
      maccountno: _editData.manufacturerAccountNo,
      prifix: _editData.billNumberPrifix,
      billstratfrom: _editData.billNumberStartFrom,
    };
    buttonText = "Update";
  } else {
    initialdata = {
      mname: "",
      mphone: "",
      memail: "",
      mgst: "",
      maddress: "",
      mbankname: "",
      mifsc: "",
      maccountno: "",
      billstratfrom: "",
      prifix: "",
    };
    buttonText = "Create";
  }
  const backBtnClick = () => {
    navigate("/app/manufacturer", { replace: false });
  };
  return (
    <Page title="Manufacturer">
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            mname: Yup.string().required("Name is required!"),
            mphone: Yup.number("Must be a valid phone no.").max(999999999999),
            memail: Yup.string()
              .email("Must be a valid email")
              .max(255),
          })}
          onSubmit={(values, { resetForm }) => {
            if (buttonText === "Update" && _editData) {
              dispatch(updateDetails(values, _editData.id, navigate));
            } else {
              dispatch(createNewDetails(values, navigate));
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form //autoComplete="off"
              //noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  Manufacturer Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Manufacturer Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.mname && errors.mname)}
                        fullWidth
                        helperText={touched.mname && errors.mname}
                        label="Manufacturer Name"
                        name="mname"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.mname}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.mphone && errors.mphone)}
                        fullWidth
                        helperText={touched.mphone && errors.mphone}
                        label="Phone No"
                        name="mphone"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mphone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Email ID :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.memail && errors.memail)}
                        fullWidth
                        helperText={touched.memail && errors.memail}
                        label="Email Id"
                        name="memail"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.memail}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        GST No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.mgst && errors.mgst)}
                        fullWidth
                        helperText={touched.mgst && errors.mgst}
                        label="GST No"
                        name="mgst"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mgst}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Address :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        //helperText="Please specify the manufacturer address"
                        label="Address"
                        name="maddress"
                        rows={5}
                        onChange={handleChange}
                        value={values.maddress}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bank Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        // error={Boolean(touched.mbankname && errors.mbankname)}
                        fullWidth
                        // helperText={touched.mbankname && errors.mbankname}
                        label="Bank Name"
                        name="mbankname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mbankname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        // error={Boolean(touched.mifsc && errors.mifsc)}
                        fullWidth
                        // helperText={touched.mifsc && errors.mifsc}
                        label="IFSC Code"
                        name="mifsc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mifsc}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        // error={Boolean(touched.maccountno && errors.maccountno)}
                        fullWidth
                        // helperText={touched.maccountno && errors.maccountno}
                        label="Account No"
                        name="maccountno"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.maccountno}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Bill Details :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Bill Prefix Character"
                        // helperText="Please specify the supplier Bank Details"
                        name="prifix"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.prifix}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.billstratfrom && errors.billstratfrom
                        )}
                        fullWidth
                        helperText={
                          touched.billstratfrom && errors.billstratfrom
                        }
                        type="number"
                        label="Bill Number Start From"
                        name="billstratfrom"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.billstratfrom}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

ManufacturerCreate.propTypes = {
  className: PropTypes.string,
};

export default ManufacturerCreate;
