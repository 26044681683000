import React,{useEffect} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from '@iconify/react';
import { ReturnRatioOfCustFinance } from "./_dashboard_action";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56,
  },
}));

const ReturnRatio = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let allFetchData = useSelector((state) => state.dashboard._returnRatio) || [];

  useEffect(() => {
    dispatch(ReturnRatioOfCustFinance());
  },[])

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL RETURN RATIO
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {allFetchData.returnRatio}%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
            <Icon icon="ic:outline-assignment-return" style={{fontSize:'30px'}}/>
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
        we have total {allFetchData.returnRatio}% pending stone orders
        </Box>
      </CardContent>
    </Card>
  );
};

ReturnRatio.propTypes = {
  className: PropTypes.string,
};

export default ReturnRatio;
