import {
  CHANGE_ACTION,
  FETCH_ALL_USER,
  EDIT_USER_DETAILS,
  DELETE_USER_DETAILS,
  ADD_DETAIL_TO_TABLE,
} from "./userAccountType";
import { config } from "../../Constants";
import { toast } from "react-toastify";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const fetchAllUser = (page, limit) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/user/get-userdetails?page=" +
        page +
        "&limit=" +
        limit,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let userData = res.data.data;

          dispatch({
            type: FETCH_ALL_USER,
            usersdata: userData,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_USER,
          usersdata: [],
        });
      });
  };
};

export const changeUserView = () => {
  return {
    type: CHANGE_ACTION,
  };
};

export const editUserDetails = (id, userDetails) => {
  return {
    type: EDIT_USER_DETAILS,
    payload: userDetails,
    id: id,
  };
};

export const updateUserDetails = (updateData, u_id, navigate) => {
  var OPTIONS = {
    url: REACT_APP_URL + "/api/user/update-userdetails",
    method: "POST",
    data: {
      userid: u_id,
      email: updateData.email,
      fullName: updateData.fullName,
      phoneno: updateData.phone,
      rollTypeId: updateData.roll,
      salary: updateData.salary,
      isactive: true,
    },
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  axios(OPTIONS)
    .then((res) => {
      if (res.data.status === 200) {
        navigate("../users", { replace: false });
      } else {
        console.log("Soming Wrong.....!");
      }
    })
    .catch((err) => {
      console.log("err.response", err.response.data.message);
    });
};

export const deleteUserDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/user/update-userdetails",
      method: "POST",
      data: { userid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_USER_DETAILS,
            payload: id,
          });
        }
      })
      .catch((err) => console.log(err));
  };
};

///////////////////////////////////// USER ROLL TYPE //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const createNewRollName = (values, navigate, isPopup) => {
  let formData = {
    rollname: values.rolltype,
    desc: values.description,
  };

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/userroll/add-user-rollname",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let data = res.data.data;
          dispatch(addToTable(data));

          if (!isPopup) navigate("../users", { replace: false });
          //toast(message);
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const addToTable = (singleDetails) => {
  return {
    type: ADD_DETAIL_TO_TABLE,
    payload: singleDetails,
  };
};

export const clearState = (singleDetails) => {
  // return {
  //   type: ADD_DETAIL_TO_TABLE,
  //   payload: singleDetails,
  // }
};
