/*eslint eqeqeq: "off"*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Container,
} from "@material-ui/core";
import Page from "src/components/Page";
import Popup from "src/components/Popup";
import AddIcon from "@material-ui/icons/Add";
import UserRoll from "./UserRoll";
import { updateUserDetails } from "../../redux/index";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../Constants";
import ToastNotify from "../../components/ToastNotify";
var REACT_APP_URL = config.url.API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
}));

// Main Function
const ProfileDetails = ({ className, ...rest }) => {
  let userEditData = useSelector((state) => state.userAccount._edituserDetails);
  let singleDetails =
    useSelector((state) => state.userAccount._singleDetails) || "";

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [rollTypes, setRollType] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    fetchUserRollType();
  }, [singleDetails]);

  const backBtnClick = () => {
    navigate("/app/users", { replace: false });
  };

  async function fetchUserRollType() {
    const token = localStorage.getItem("jwtToken");
    var response = await axios.get(
      REACT_APP_URL + "/api/userroll/get-all-rollname",
      {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let getRollTypes = [];
    getRollTypes = response.data.data;
    setRollType(getRollTypes);
  }

  const updateUser = (values, id) => {
    dispatch(updateUserDetails(values, id, navigate));
  };

  const createUser = (values, navigate) => {
    //e.preventDefault();
    const token = localStorage.getItem("jwtToken");
    let formData = {
      email: values.email,
      password: values.password,
      userRollTypeId: parseInt(values.roll),
      fullName: values.fullName,
      phoneNo: values.phone || 0,
      salary: values.salary || 0,
    };
    //////Create-User
    axios
      .post(REACT_APP_URL + "/api/user/register", formData, {
        headers: {
          Accept: "application/json, text/plain, */*",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if ((res.data.status = 200)) {
          navigate("../users", { replace: false });
        } else {
          alert("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  let defultOption = (
    <option key="" value="">
      Select Roll
    </option>
  );
  let rollToRender = [];
  if (rollTypes) {
    rollToRender = rollTypes.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.rollname}
        </option>
      );
    });
  } else {
    //rollToRender = <option key="0" value="">Roll Not Available</option>
  }
  var initialdata,
    buttonText,
    dropdownlable = "Select Roll",
    isDisabled = false;
  if (userEditData) {
    initialdata = {
      email: userEditData.email,
      fullName: userEditData.fullName,
      password: "password@123",
      roll: "" + userEditData.us_id,
      phone: userEditData.phoneNo,
      salary: userEditData.salary,
    };
    buttonText = "Update User";
    dropdownlable = "";
    isDisabled = true;
  } else {
    initialdata = {
      email: "",
      fullName: "",
      password: "",
      roll: "",
      phone: "",
      salary: "",
    };
    buttonText = "Create User";
  }
  return (
    <Page title="Add User">
      <ToastNotify />
      <Container maxWidth="xl">
        <Formik
          initialValues={initialdata}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            fullName: Yup.string()
              .max(255)
              .required("fullName name is required"),
            phone: Yup.number("Must be a valid phone no.").max(999999999999),
            password: Yup.string()
              .min(4)
              .max(24)
              .required("Password should be a minimum of 4 characters"),
            roll: Yup.string().required("Roll is required!"),
          })}
          onSubmit={(values, { resetForm }) => {
            if (buttonText == "Create User") {
              createUser(values, navigate);
            } else {
              updateUser(values, userEditData.id, navigate);
            }
            resetForm({ values: "" });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              className={classes.root}
              //autoComplete="off"
              // className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <Typography variant="h4" className={classes.cardHeader}>
                  User Details
                </Typography>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        User Name :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.fullName && errors.fullName)}
                        fullWidth
                        helperText={touched.fullName && errors.fullName}
                        label="Full name"
                        name="fullName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fullName}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Email ID :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Phone No :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label="Phone Number"
                        name="phone"
                        onChange={handleChange}
                        value={values.phone}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Salary :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Salary"
                        name="salary"
                        onChange={handleChange}
                        value={values.salary}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        Password :
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={isDisabled}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        fullWidth
                        required
                        label="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} sm={3}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                      >
                        User Roll :
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        error={Boolean(touched.roll && errors.roll)}
                        fullWidth
                        helperText={touched.roll && errors.roll}
                        label={dropdownlable}
                        name="roll"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        value={values.roll}
                      >
                        {defultOption}
                        {rollToRender}
                      </TextField>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => setOpenPopup(true)}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={backBtnClick}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
      <Popup
        title="Add roll type"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UserRoll isPopup={openPopup} />
      </Popup>
    </Page>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
