/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon, Trash2 as DeleteIcon } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteSingleDetails,
  editSingleDetails,
  getInvoiceProductEntry,
  getAttributeSaveValue,
} from "./_purchase_Action";
import { getProductStoneDetails } from "../product/_product_Action";

import commanTable from "../../components/commanTable";
import moment from "moment";
import { fetchAllCategoryAttribute } from "../link_CategoryAttribute/_link_Cat_Att_Action";
import DeleteDialog from "src/components/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const headCells = [
  { id: "invoiceDate", label: "Invoice Date" },
  { id: "supplierName", label: "Supplier Name" },
  { id: "paymentMode", label: "Payment Mode" },
  { id: "payemntDueDay", label: "Due Days" },
  { id: "totalInvoiceAmount", label: "Total Price" },
  { id: "edit", label: "Edit", disableSorting: true },
  { id: "delete", label: "Delete", disableSorting: true },
];

const PurchaseList = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState("");

  let _linkCat_Att =
    useSelector((state) => state.linkCat_Att._allDetails) || [];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = commanTable(TableData, headCells, filterFn, 1);

  const editDetails = (id, data) => {
    if (_linkCat_Att.length <= 0) dispatch(fetchAllCategoryAttribute());

    dispatch(editSingleDetails(id, data));
    dispatch(getInvoiceProductEntry(id));
    dispatch(getProductStoneDetails("", "", id));
    dispatch(getAttributeSaveValue(id, navigate));

    //   navigate('./edit', { replace: false });
  };
  const openDialog = (id) => {
    setDeleteRecord(id);
    setDialogOpen(!dialogopen);
  };
  const confirmDialog = () => {
    dispatch(deleteSingleDetails(deleteRecord));
  };

  let toDayDate = new Date();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />
            {TableData.length > 0 ? (
              <TableBody>
                {/* {TableData.slice(0, limit).map((row) => ( */}
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      {moment(row.invoiceDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row.supplierName}</TableCell>
                    <TableCell>{row.paymentMode || "Not Available"}</TableCell>
                    <TableCell>
                      {row.payemntDueDay > 0 || row.payemntDueDay !== ""
                        ? row.payemntDueDay -
                          moment(toDayDate).diff(
                            moment(row.invoiceDate),
                            "days"
                          )
                        : "Not Available"}
                    </TableCell>
                    {/* <TableCell >
                    {row.payemntDueDay}
                  </TableCell> */}
                    <TableCell>{row.totalInvoiceAmount}</TableCell>
                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => editDetails(row.id, row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell width="5%">
                      <IconButton
                        size="small"
                        onClick={() => openDialog(row.id)}
                      >
                        <DeleteIcon />
                        {deleteRecord == row.id ? (
                          <DeleteDialog
                            openDialog={openDialog}
                            confirmDialog={confirmDialog}
                            dialogopen={dialogopen}
                          />
                        ) : (
                          ""
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found...</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      <TblPagination />
    </Card>
  );
};

PurchaseList.propTypes = {
  className: PropTypes.string,
  //TableData: PropTypes.array.isRequired
};

export default PurchaseList;
