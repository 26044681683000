import React, { useState } from "react";
import {
  Container,
  // Grid,
  makeStyles,
  Box,
} from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import OrderList from "./OrderList";

import data from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const OrderView = () => {
  const classes = useStyles();
  const [customers] = useState(data);
  return (
    <Page className={classes.root} title="Platform">
      <Container maxWidth={false}>
        {/* maxWidth="lg"> */}

        <Toolbar />

        {/* <Grid
                    container
                    spacing={3}
                    
                    // justify="center"
                >
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        
                    >
                        <PlatformList customers={customers} />
                    </Grid>

                </Grid> */}
        <Box mt={3}>
          <OrderList customers={customers} />
        </Box>
      </Container>
    </Page>
  );
};

export default OrderView;
