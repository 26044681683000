export const ADD_PLATFORM_ACC = "ADD_PLATFORM_ACC";
export const FETCH_PLATFORM_ACC_LIST = "FETCH_PLATFORM_ACC_LIST";
export const EDIT_PLATFORM_ACC = "EDIT_PLATFORM_ACC";
export const UPDATE_PLATFORM_ACC = "UPDATE_PLATFORM_ACC";
export const DELETE_PLATFORM_ACC = "DELETE_PLATFORM_ACC";

export const FETCH_PLATFORM_NAME_LIST = "FETCH_PLATFORM_NAME_LIST";
export const EDIT_PLATFORM_NAME = "EDIT_PLATFORM_NAME";
export const DELETE_PLATFORM_NAME_ACC = "DELETE_PLATFORM_NAME_ACC";
export const UPDATE_PLATFORM_NAME = "UPDATE_PLATFORM_NAME";
