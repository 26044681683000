import React from "react";
import { Container, Grid, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import TotalTurnOver from "./TotalTurnOver";
import LatestOrders from "./LatestOrders";
import LatestProducts from "./LatestProducts";
import Sales from "./Sales";
import ReturnRatio from "./ReturnRatio";
import PendingIssueStoneOrder from "./PendingIssueStoneOrders";
import TotalProfit from "./TotalProfit";
import TrafficByDevice from "./TrafficByDevice";
import ToastNotify from "../../../components/ToastNotify";
import OverDueOrder from "./OverDueOrder";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <ToastNotify />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalTurnOver />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <PendingIssueStoneOrder />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ReturnRatio />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales/>
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Box>
                <OverDueOrder />
            </Box>
            <Box sx={{marginTop: 30}}>
                <TrafficByDevice />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestProducts />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <LatestOrders />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
