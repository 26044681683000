/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Input,
  InputLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  fetchAllAttributeValues,
  fetchAllProducts,
  getProductStoneDetails,
} from "../product/_product_Action";
import { createNewDetails, updateDetails } from "./_purchase_Action";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../category/_category_Action";
import { fetchAllCategoryAttribute } from "../link_CategoryAttribute/_link_Cat_Att_Action";
import { fetchAllSupplierDetails } from "../supplier/_supplier_Action";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ToastNotify from "../../components/ToastNotify";
import { isNull } from "lodash";
import { fetchAllStoneType } from "../stoneDetails/_stoneType_Action";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  buttonMargin: {
    marginLeft: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "rgb(84 110 122)",
  },
  nomargin: {
    margin: "0",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
})); /*  */

const ProductCreate = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let _category = useSelector((state) => state.category._allDetails) || [];
  let _linkCat_Att =
    useSelector((state) => state.linkCat_Att._allDetails) || [];
  let _allSupplierData =
    useSelector((state) => state.supplier._allDetails) || [];
  let _editData = useSelector((state) => state.purchase._editedDetails) || "";
  let _productEntry =
    useSelector((state) => state.purchase._productEntry) || "";
  let _attributeValues =
    useSelector((state) => state.product._attributeValues) || [];
  let _savePurchaseAttributeValues =
    useSelector((state) => state.purchase._savePurchaseEntryValues) || [];
  let _allProductList = useSelector((state) => state.product._allDetails) || [];
  let _allStoneTypes =
    useSelector((state) => state.stoneType._allDetails) || [];
  let _productStoneDetails = useSelector(
    (state) => state.product._editProductStoneDetails
  ) || [{ _mainStoneList: [], _sideStoneList: [] }];
  /* eslint no-unused-vars : "off" */
  let productdetails = { id: "", productName: "", productDesignNo: "" };
  let editSupplier = "";
  if (_editData.productdetails) {
    productdetails = JSON.parse(_editData.productdetails);
  }
  if (_editData.supplierName) {
    editSupplier = {
      id: _editData.suppid,
      supplierName: _editData.supplierName,
    };
  }
  // const [openPopup, setOpenPopup] = useState(false)
  // const [attributeOpenPopup, setAttributeOpenPopup] = useState(false)

  const [supplierValueIDError, setSupplierValueIDError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    _editData.invoiceDate || new Date()
  );
  const [supplierValue, setSupplierValue] = React.useState(
    editSupplier || { id: "", supplierName: "" }
  );
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(
    _editData.totalInvoiceAmount || 0
  );
  const [renderIndex, setRenderIndex] = useState(-1);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (_linkCat_Att.length <= 0) dispatch(fetchAllCategoryAttribute()); // Get Attribute List category wise

    if (_attributeValues.length <= 0) dispatch(fetchAllAttributeValues()); // Get Attribute value

    if (_category.length <= 0) dispatch(fetchAllCategory());

    if (_editData.productDesignId)
      dispatch(getProductStoneDetails(_editData.productDesignId));

    if (_allSupplierData.length <= 0) dispatch(fetchAllSupplierDetails());
    if (_allStoneTypes.length <= 0) dispatch(fetchAllStoneType());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (renderIndex >= 0) handleDesignChange(_productStoneDetails, renderIndex);
    // eslint-disable-next-line
  }, [_productStoneDetails]);

  let defultOption = (
    <option key="" value="000e0">
      Select Category
    </option>
  );
  let dropdownRender = [];

  if (_category) {
    dropdownRender = _category.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.categoryName}
        </option>
      );
    });
  }

  var singleProduct = {
    categoryId: "",
    designName: "",
    weight: "",
    price: "",
    qty: "",
    gstPer: "",
    totalPrice: "",
    isRowMaterial: false,
  };
  let productList = [singleProduct] || [],
    editAttributeList = [singleProduct] || [],
    attributeCompoArray = [],
    selectedSaveAttrValue = [],
    setStoneDetailsOrderWise = [{ stoneDetails: [] }];
  /*eslint array-callback-return: "off"*/
  if (_productEntry.length > 0) {
    _productEntry.map((obj, i) => {
      productList[i] = {
        categoryId: obj.categoryId,
        designName: {
          id: obj.productDesignId,
          productDesignNo: obj.productDesignNo,
        },
        weight: obj.productWeight,
        price: obj.perQtyPrice,
        qty: obj.productQty,
        gstPer: obj.purchaseGst,
        totalPrice: obj.totalPrice,
        stockId: obj.productStockStoreId,
        invoiceId: obj.purchaseInvoiceId,
        transactionId: obj.id,
      };

      let singleRecord = _linkCat_Att.filter(
        (linkObj) => linkObj.categoryId == obj.categoryId
      ) || [{ categoryAttId: [{}] }];

      ///
      let _mainStoneList =
        _productStoneDetails._mainStoneList.filter(
          (stonObj) => stonObj.productDefiId == obj.productDesignId
        ) || "";
      let _sideStoneList =
        _productStoneDetails._sideStoneList.filter(
          (stonObj) => stonObj.productDefiId == obj.productDesignId
        ) || "";

      //
      // let newJson = { stoneDetails: singleStoneDetails }

      let newJson = {
        stoneDetails: {
          _mainStoneList: _mainStoneList,
          _sideStoneList: _sideStoneList,
        },
      };
      setStoneDetailsOrderWise[i] = newJson;

      if (singleRecord.length > 0)
        attributeCompoArray = JSON.parse(singleRecord[0].categoryAttId) || [];

      editAttributeList[i] = { catgeoryAttributeCompo: attributeCompoArray };
      let singleJson = [];
      _savePurchaseAttributeValues.map((saveObj, count) => {
        if (obj.productStockStoreId == saveObj[1].sid) {
          let sss = saveObj[0];
          singleJson = { ...singleJson, ...sss };
        }
      });
      selectedSaveAttrValue[i] = singleJson;
    });
  }

  const [inputList, setInputList] = useState(productList);
  const [textboxValue, settextboxValue] = useState(selectedSaveAttrValue || []);
  const [attributeValueList, setAttributeValueList] = useState(
    editAttributeList || []
  );
  const [stoneDetailsArray, setStoneDetailsArray] = useState(
    setStoneDetailsOrderWise || [{ stoneDetails: [] }]
  );
  /////////////////////
  let stoneTypedefultOption = (
    <option key="" value="000e0">
      Select Stone
    </option>
  );
  let stoneTypeDropdownRender = [];
  if (_allStoneTypes) {
    stoneTypeDropdownRender = _allStoneTypes.map((option) => {
      return (
        <option key={option.id} value={option.stoneType}>
          {option.stoneType}
        </option>
      );
    });
  }
  ///////////

  const dynamicHandleChange = (value, index, id) => {
    const list = [...textboxValue];
    let singleCompo = list[index] || {};
    list[index] = { ...singleCompo, [id]: value || "" };
    settextboxValue(list);
  };

  const backBtnClick = () => {
    navigate("/app/purchase", { replace: false });
  };

  const onCategoryChange = (value, i) => {
    const textValueList = [...textboxValue];
    textValueList[i] = {};
    settextboxValue(textValueList);

    let categoryId = value || 0;
    const cList = [...inputList];
    cList[i]["categoryId"] = value || "";
    cList[i]["designName"] = { id: "", productDesignNo: "" };

    let isRowMaterial = false,
      selectedCategory;
    if (_category) {
      selectedCategory = _category.filter((record) => record.id == value);
    }
    if (selectedCategory.length > 0)
      isRowMaterial = Boolean(selectedCategory[0].isRowMaterial);
    cList[i]["isRowMaterial"] = isRowMaterial;
    setInputList(cList);

    dispatch(fetchAllProducts(categoryId));
    let singleRecord = _linkCat_Att.filter(
      (obj) => obj.categoryId == categoryId
    ) || [{ categoryAttId: [{}] }];

    if (singleRecord.length > 0)
      attributeCompoArray = JSON.parse(singleRecord[0].categoryAttId) || [];
    else attributeCompoArray = [];

    let newArray = [];
    newArray = { catgeoryAttributeCompo: attributeCompoArray };
    const list = [...attributeValueList];
    list.splice(i, 1, newArray);
    setAttributeValueList(list);

    // let stoneArray = []
    // stoneArray = { stoneDetails: [] }
    // const stonelist = [...stoneDetailsArray];
    // stonelist.splice(i, 1, stoneArray);
    // setStoneDetailsArray(stonelist);
  };

  const renderNewAttributeFunc = (rowIndex) => {
    let newAttributeRender = [],
      getArrayObject = [],
      renderTextBox = [],
      inputVal = "";

    if (_linkCat_Att.length > 0) {
      if (attributeValueList.length <= 0) {
        return;
      }
      getArrayObject = attributeValueList;
      renderTextBox = getArrayObject[rowIndex].catgeoryAttributeCompo || [];

      newAttributeRender = renderTextBox.map((row, renderIndex) => {
        let attValue = _attributeValues.filter((obj) => obj.id == row.id) || [];
        if (attValue.length)
          attValue = JSON.parse(attValue[0].attributeValue) || [];

        if (textboxValue.length > 0)
          inputVal = textboxValue[rowIndex][row.id.toString()];

        return (
          <Grid
            key={row.id.toString()}
            container
            spacing={3}
            alignItems="center"
            className={classes.nomargin}
          >
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {row.attributeName} :
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {row.datatype == "SingleSelection" && (
                <Autocomplete
                  required
                  name={row.id.toString()}
                  freeSolo
                  options={attValue}
                  value={inputVal || { id: "", value: "" }}
                  onChange={(event, value) => {
                    dynamicHandleChange(value, rowIndex, row.id.toString());
                  }}
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value == value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={row.attributeName}
                      variant="outlined"
                    />
                  )}
                />
              )}
              {row.datatype == "MultiSelection" && (
                <Autocomplete
                  required
                  multiple
                  name={row.id.toString()}
                  options={attValue}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option ? option.value : "")}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={inputVal || []}
                  input={<Input />}
                  onChange={(event, value) => {
                    dynamicHandleChange(value, rowIndex, row.id.toString());
                  }}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.value}
                      </React.Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      // required
                      {...params}
                      variant="outlined"
                      label={row.attributeName}
                      placeholder={row.attributeName}
                    />
                  )}
                />
              )}
              {row.datatype == "FreeText" && (
                <TextField
                  required
                  label={row.attributeName}
                  name={row.id.toString()}
                  fullWidth
                  value={inputVal || ""}
                  variant="outlined"
                  onChange={(e) => {
                    dynamicHandleChange(
                      e.target.value,
                      rowIndex,
                      row.id.toString()
                    );
                  }}
                />
              )}
            </Grid>
          </Grid>
        );
      });
    }
    return newAttributeRender;
  };

  /////////////////////////////////////////////
  const handleDesignChange = (_stoneArray, i) => {
    let newArray = [];
    newArray = { stoneDetails: _stoneArray }; // _stoneArray._mainStoneList
    const list = [...stoneDetailsArray];
    list.splice(i, 1, newArray);
    setStoneDetailsArray(list);
  };

  const handleAddClick = () => {
    setInputList([singleProduct, ...inputList]);
    setAttributeValueList([[], ...attributeValueList]);
    settextboxValue([{}, ...textboxValue]);
    setStoneDetailsArray([{ stoneDetails: [] }, ...stoneDetailsArray]);
  };

  const renderProductStoneDetailsFunc = (_passArray, stoneSide, rowIndex) => {
    let renderProductStones = [],
      renderArray = [];

    // if (stoneDetailsArray.length <= 0) {
    //     return
    // } else
    //     renderArray = stoneDetailsArray[rowIndex].stoneDetails || []

    if (_passArray && stoneSide == "MainStone:")
      renderArray = _passArray._mainStoneList || [];
    else if (_passArray && stoneSide == "SideStone:")
      renderArray = _passArray._sideStoneList || [];

    // renderArray = _passArray.stoneDetails || []

    renderProductStones = renderArray.map((row, i) => {
      let stonTypeValue;
      if (textboxValue.length > 0)
        stonTypeValue = textboxValue[rowIndex]["stype" + row.id.toString()];
      return (
        <div key={i}>
          <Grid container spacing={3} key={row.id.toString()}>
            <Grid item xs={6} sm={3}>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {stoneSide}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Shape"
                name={row.id.toString()}
                value={row.shape || ""}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="size"
                name={row.id.toString() + "sz"}
                value={row.size || ""}
                variant="outlined"
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              ></Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                label="Quantity"
                name={row.id.toString() + "qty"}
                value={row.quantity || ""}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                name={"stype" + row.id.toString()}
                label="Select type"
                onChange={(e) => {
                  dynamicHandleChange(
                    e.target.value,
                    rowIndex,
                    "stype" + row.id.toString()
                  );
                }}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={stonTypeValue}
              >
                {stoneTypedefultOption}
                {stoneTypeDropdownRender}
              </TextField>
            </Grid>
          </Grid>
        </div>
      );
    });
    return renderProductStones;
  };

  // const [inputList, setInputList] = useState(productList)

  const handleInputChangeDiffComponent = (name, value, index) => {
    const list = [...inputList];
    list[index][name] = value || "";
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value || "";
    setInputList(list);
  };

  const handlePriceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    let lastGst = Number(list[index]["gstPer"]);

    list[index][name] = value || "";

    if (name == "price") {
      // list[index]['totalPrice'] = value * list[index]['qty'] || '';
      list[index]["totalPrice"] =
        Number(list[index]["price"] * list[index]["weight"]) +
          (Number(list[index]["price"]) *
            Number(list[index]["weight"]) *
            Number(list[index]["gstPer"])) /
            100 || 0;
    }

    if (name == "totalPrice") {
      list[index]["price"] = value / list[index]["qty"] || "";
      list[index]["price"] =
        (Number(list[index]["totalPrice"]) -
          lastGst * (Number(list[index]["totalPrice"]) / (100 + lastGst))) /
          list[index]["weight"] || 0;
    }
    if (name == "gstPer") {
      list[index]["totalPrice"] =
        Number(list[index]["totalPrice"]) -
        lastGst * (Number(list[index]["totalPrice"]) / (100 + lastGst));
      list[index]["totalPrice"] =
        Number(list[index]["price"] * list[index]["weight"]) +
          (Number(list[index]["price"]) *
            Number(list[index]["weight"]) *
            Number(list[index]["gstPer"])) /
            100 || 0;
    }

    setInputList(list);

    let totalInvoiceAmt = 0;
    for (const obj of inputList) {
      totalInvoiceAmt = Number(totalInvoiceAmt) + Number(obj.totalPrice);
    }
    setTotalInvoiceAmount(totalInvoiceAmt);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const attList = [...attributeValueList];
    attList.splice(index, 1);
    setAttributeValueList(attList);

    const attValue = [...textboxValue];
    attValue.splice(index, 1);
    settextboxValue(attValue);

    const stoneDetail = [...stoneDetailsArray];
    stoneDetail.splice(index, 1);
    setStoneDetailsArray(stoneDetail);
  };

  ///////////////
  let addNewStockBoxRender = [];
  addNewStockBoxRender = inputList.map((x, i) => {
    return (
      <CardContent key={i.toString()}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Product Category :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="categoryId"
              fullWidth
              label="Product Category"
              onChange={(e) => onCategoryChange(e.target.value, i)}
              required
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={x.categoryId || 0}
            >
              {defultOption}
              {dropdownRender}
            </TextField>
          </Grid>
          {x.isRowMaterial ? (
            ""
          ) : (
            <Grid
              container
              spacing={3}
              alignItems="center"
              className={classes.nomargin}
            >
              <Grid item xs={6} sm={3}>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  Search by Design :
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  name="designName"
                  freeSolo
                  disabled={x.isRowMaterial}
                  value={x.designName || { id: "", productDesignNo: "" }}
                  onChange={async (event, value) => {
                    if (value == null) {
                      value = { id: "", productDesignNo: "" };
                    } else dispatch(getProductStoneDetails(value.id));
                    handleInputChangeDiffComponent("designName", value, i);
                    setRenderIndex(i);
                    // handleDesignChange(_productStoneDetails, i);  // _productStoneDetails not geting immediately before this func call
                  }}
                  options={_allProductList}
                  getOptionLabel={(option) =>
                    option ? option.productDesignNo : {}
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by design"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid alignItems="center" container spacing={3}>
          {renderNewAttributeFunc(i)}
        </Grid>
        {renderProductStoneDetailsFunc(
          stoneDetailsArray[i].stoneDetails,
          "MainStone:",
          i
        )}
        {renderProductStoneDetailsFunc(
          stoneDetailsArray[i].stoneDetails,
          "SideStone:",
          i
        )}
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Quantity :
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              label="Quantity"
              name="qty"
              fullWidth
              value={x.qty}
              type="number"
              onChange={(e) => handleInputChange(e, i)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Weight :
            </Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <TextField
              required
              label="Weight"
              name="weight"
              fullWidth
              type="number"
              value={x.weight}
              onChange={(e) => handleInputChange(e, i)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <TextField
              required
              label="Price Per Weight"
              name="price"
              fullWidth
              type="number"
              value={x.price}
              onChange={(e) => handlePriceChange(e, i)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} sm={3}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Price :
            </Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <TextField
              required
              label="GST %"
              name="gstPer"
              fullWidth
              type="number"
              value={x.gstPer}
              onChange={(e) => handlePriceChange(e, i)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <TextField
              required
              label="Total Price"
              name="totalPrice"
              fullWidth
              type="number"
              value={x.totalPrice}
              onChange={(e) => handlePriceChange(e, i)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="flex-end" p={2}>
              {0 === i && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleAddClick}
                >
                  Add More
                </Button>
              )}
              &ensp;&ensp;
              {inputList.length !== 1 && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        {inputList.length !== 1 ? <Divider className={classes.divider} /> : ""}
      </CardContent>
    );
  });
  ///////////////

  var initialdata = {
    dueDay: "",
    payby: "",
    gstPer: "",
  };
  var buttonText = "Create";
  if (_editData) {
    initialdata = {
      dueDay: _editData.payemntDueDay,
      payby: _editData.paymentMode,
      gstPer: "",
    };
    buttonText = "Update";
  } else {
    initialdata = {
      dueDay: "",
      payby: "",
      gstPer: "",
    };
    buttonText = "Create";
  }

  const validate = () => {
    let isError = false;
    if (
      isNull(supplierValue) ||
      supplierValue.id == "" ||
      supplierValue.id == 0
    ) {
      isError = true;
      setSupplierValueIDError("Please select supplier name");
    }
    return isError;
  };

  return (
    <>
      <Page title="Purchase Invoice">
        <ToastNotify />
        <Container maxWidth="xl">
          <Formik
            initialValues={initialdata}
            validationSchema={Yup.object().shape({
              // productName: Yup.string().required('Product name is required!'),
              //designDefinationId: Yup.string().required('Design Number is required!'),
            })}
            onSubmit={(values, { resetForm }) => {
              const err = validate();
              if (!err) {
                values.attributeValue = textboxValue;
                values.supplierId = supplierValue.id || "";
                values.payby = values.payby || "";
                values.dueDay = values.dueDay || 0;
                values.purchaseDate = Date.parse(selectedDate);
                values.productDetails = inputList;
                values.totalInvoiceAmount = totalInvoiceAmount;

                if (buttonText === "Update" && _editData) {
                  dispatch(updateDetails(values, _editData.id, navigate));
                } else {
                  dispatch(createNewDetails(values, navigate));
                }
                settextboxValue([]);
                resetForm({ values: "" });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form //autoComplete="off"
                //noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <Typography variant="h4" className={classes.cardHeader}>
                    Purchase Invoice
                  </Typography>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Stock Date :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              id="date-picker-inline"
                              label="Select stock in date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Supplier Name :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          name="supplierName"
                          freeSolo
                          value={supplierValue || { id: "", supplierName: "" }}
                          onChange={(event, value) => {
                            setSupplierValue(value);
                            setSupplierValueIDError("");
                          }}
                          options={_allSupplierData}
                          getOptionLabel={(option) =>
                            option ? option.supplierName : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Supplier"
                              variant="outlined"
                              error={Boolean(supplierValueIDError)}
                              helperText={supplierValueIDError}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Payby :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <RadioGroup
                          row
                          label="PayBy"
                          name="payby"
                          value={values.payby}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="cash"
                            control={<Radio />}
                            label="Cash"
                          />
                          <FormControlLabel
                            value="credit"
                            control={<Radio />}
                            label="Credit"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} sm={3}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Due Days :
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          label="Due Days"
                          name="dueDay"
                          fullWidth
                          type="number"
                          onBlur={handleBlur}
                          value={values.dueDay}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />

                  {addNewStockBoxRender}

                  <Divider />
                  <Box display="flex" justifyContent="space-between" p={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backBtnClick}
                    >
                      Back
                    </Button>
                    <InputLabel style={{ marginTop: "52x" }}>
                      Total Amount:
                      {totalInvoiceAmount}
                    </InputLabel>
                    &ensp;&ensp;
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.buttonMargin}
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
        {/* <Popup
                    title="Add Category"
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <CategoryCreate isPopup={openPopup} />
                </Popup>
                <Popup
                    title="Add Category Attribute"
                    openPopup={attributeOpenPopup}
                    setOpenPopup={setAttributeOpenPopup}
                >
                    <CategoryAttributeCreate isPopup={attributeOpenPopup} />
                </Popup> */}
      </Page>
    </>
  );
};

ProductCreate.propTypes = {
  className: PropTypes.string,
};

export default ProductCreate;
