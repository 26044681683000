/*eslint eqeqeq: "off"*/
import {
  ADD_PLATFORM_ACC,
  FETCH_PLATFORM_ACC_LIST,
  EDIT_PLATFORM_ACC,
  UPDATE_PLATFORM_ACC,
  DELETE_PLATFORM_ACC,
  UPDATE_PLATFORM_NAME,
  FETCH_PLATFORM_NAME_LIST,
  EDIT_PLATFORM_NAME,
  DELETE_PLATFORM_NAME_ACC,
} from "./_platformAccount_Type";
const initialState = {
  isLoggedIn: false,
  _editedDetails: "",
  _action: "View",
  _id: "",
  _msg: "",
  _allPlatfrom: [],
  _allPlatfromNames: [],
  _editedName: "",
};

const platfromAccReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLATFORM_ACC:
      return {
        ...state,
        _msg: action.message,
      };
    case EDIT_PLATFORM_ACC:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case UPDATE_PLATFORM_ACC:
      return {
        ...state,
        _editedDetails: "",
      };
    case FETCH_PLATFORM_ACC_LIST:
      return {
        ...state,
        _allPlatfrom: action.listview,
        _msg: "",
        _editedDetails: "",
      };
    case DELETE_PLATFORM_ACC:
      return {
        ...state,
        _allPlatfrom: state._allPlatfrom.filter(
          (record) => record.id != action.payload
        ),
      };

    ////////////////////////////////////////////
    case FETCH_PLATFORM_NAME_LIST:
      return {
        ...state,
        _allPlatfromNames: action.data,
        _msg: "",
      };
    case EDIT_PLATFORM_NAME:
      return {
        ...state,
        _editedName: action.payload,
        _id: action.id,
      };
    case UPDATE_PLATFORM_NAME:
      return {
        ...state,
        _editedName: "",

        _allPlatfromNames: state._allPlatfromNames.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case DELETE_PLATFORM_NAME_ACC:
      return {
        ...state,
        _allPlatfromNames: state._allPlatfromNames.filter(
          (record) => record.id != action.payload
        ),
      };

    default:
      return state;
  }
};

export default platfromAccReducer;
