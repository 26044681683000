////////////////// STONE Shape Name /////////////////////////////////////////

export const ADD_STONE_SHAPE_DETAILS = "ADD_STONE_SHAPE_DETAILS";
export const FETCH_ALL_STONE_SHAPE_DETAILS_LIST =
  "FETCH_ALL_STONE_SHAPE_DETAILS_LIST";
export const EDIT_STONE_SHAPE_DETAILS = "EDIT_STONE_SHAPE_DETAILS";
export const UPDATE_STONE_SHAPE_DETAILS = "UPDATE_STONE_SHAPE_DETAILS";
export const DELETE_STONE_SHAPE_DETAILS = "DELETE_STONE_SHAPE_DETAILS";

////////////////// STONE Type /////////////////////////////////////////
export const ADD_STONE_TYPE_DETAILS = "ADD_STONE_TYPE_DETAILS";
export const FETCH_ALL_STONE_TYPE_DETAILS_LIST =
  "FETCH_ALL_STONE_TYPE_DETAILS_LIST";
export const EDIT_STONE_TYPE_DETAILS = "EDIT_STONE_TYPE_DETAILS";
export const UPDATE_STONE_TYPE_DETAILS = "UPDATE_STONE_TYPE_DETAILS";
export const DELETE_STONE_TYPE_DETAILS = "DELETE_STONE_TYPE_DETAILS";
