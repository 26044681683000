import * as ACTION from "./_stock_Details_Type";
import { fetchDataMethod } from "../../utils/actionMethod";

export const findAllProduct = () => {
  return async (dispatch) => {
    let result = await fetchDataMethod("/api/find-all-product-stock");
    dispatch({
      type: ACTION.FIND_ALL_PRODUCTS,
      payload: result.data,
    });
  };
};
export const detailsOfProduct = (singleData) => {
  return {
    type: ACTION.DETAIL_SINGLE_PRODUCT,
    payload: singleData,
  };
};
