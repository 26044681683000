/* eslint no-unused-vars : "off" */
/*eslint eqeqeq: "off"*/
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  Divider,
} from "@material-ui/core";
/*eslint no-useless-rename: "off"*/
import { ChevronDown, ChevronUp } from "react-feather";
import commanTable from "../../components/commanTable";
import ToastNotify from "../../components/ToastNotify";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  issueButton: {
    margin: "auto",
    display: "flex",
    marginTop: "20px",
  },
}));

const headCells = [
  { id: "expand", label: "" },
  { id: "orderDate", label: "Order Date" },
  { id: "customerName", label: "Customer Name" },
  { id: "platFormName", label: "PlateForm Name" },
  { id: "platFormEmail", label: "PlatForm Email" },
];
function DetailsArrayloop(props) {
  const { row } = props;
  let x = 0;
  return (
    <>
      {
        row.map((ele, i) => {

          return <div key={i}>
            <Box sx={{ margin: 1 }} key={i}>
              <Typography
                component="h5"
                variant="h5"
                className="typography_margin"
              >
                {row.length > 1 ? `Product Details : ${++x}` : `Product Details`}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                maxWidth={1000}
                justifyContent="space-between"
              >
                <p>
                  <b>
                    Design Name :-
                    <b>
                      {ele.isNewDesign == 1 ? "New Design" : " " + ele.prod_Name}
                    </b>
                  </b>
                </p>
                <p>
                  <b>
                    makerSku :-<b>{" " + ele.makerSku}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Manufacturer Name :- <b>{" " + ele.manufacturerName}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Manufacturer Email :- <b>{" " + ele.manufacturerEmail}</b>
                  </b>
                </p>
                <p>
                  <b>
                    Product Qty :- <b>{" " + ele.productQty}</b>
                  </b>
                </p>
              </Box>
            </Box>
            <Box marginBottom="15px" key={i + 1}>
              <Typography component="h5" variant="h5" className="typography_margin">
                Requirements
              </Typography>
              {ele.diamondDetails.map((item, i) => {
                return item.dimondShape === undefined ? (
                  "No Diamond Require"
                ) : (
                  <div key={i}>
                    <h5>{item.dimondType}</h5>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      maxWidth={600}
                    >
                      <span>Available:- {item.availableOnStockQty}</span>
                      <span>Require:- {item.requireQty}</span>
                      <span>Shape:- {item.dimondShape}</span>
                      <span>Size:- {item.dimondSize}</span>
                    </Box>
                    <Divider className="divider_margin" />
                  </div>
                );
              })}
            </Box>
            <br />
          </div>

        })

      }

    </>
  );
}
function DetailsArray(props) {
  const { row } = props;
  let x = 0;
  return <>
    {
      row.map((ele, i) => {

        return <div key={i}>

          <Box sx={{ margin: 1 }}>
            <Typography
              component="h5"
              variant="h5"
              className="typography_margin"
            >
              {row.length > 1 ? `Product Details : ${++x}` : `Product Details`}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              maxWidth={1000}
              justifyContent="space-between"
            >
              <p>
                <b>
                  Design Name :-
                  <b>
                    {ele.isNewDesign == 1 ? " New Design" : " " + ele.prod_Name}
                  </b>
                </b>
              </p>
              <p>
                <b>
                  makerSku :-<b>{" " + ele.makerSku}</b>
                </b>
              </p>
              <p>
                <b>
                  Manufacturer Name :-<b>{" " + ele.manufacturerName}</b>
                </b>
              </p>
              <p>
                <b>
                  Manufacturer Email :-<b>{" " + ele.manufacturerEmail}</b>
                </b>
              </p>
              <p>
                <b>
                  Product Qty :-<b>{" " + ele.productQty}</b>
                </b>
              </p>
            </Box>
          </Box>
          <Box marginBottom="15px">
            <Typography component="h5" variant="h5" className="typography_margin">
              No Diamond Require
            </Typography>
          </Box>
          <br />
        </div>
      })
    }
  </>
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const array = [];
  /*eslint array-callback-return: "off"*/
  row.productList.map((ele) => {
    if (ele?.diamondDetails?.length >= 1) {
      ele.diamondDetails.map((e) => {
        if (row.order_uid == e.order_uid) {
          array.push(e);
        }
      })
    }
  })
  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell padding="checkbox">
          <div onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{moment(row.orderDate).format("DD-MM-YYYY")}</TableCell>
        <TableCell>{row.customerName}</TableCell>
        <TableCell>
          {row.platFormName}
        </TableCell>
        <TableCell>{row.platFormEmail}</TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {array.length >= 1 ? (
              <DetailsArrayloop row={row.productList} />
            ) : (
              <DetailsArray row={row.productList} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const InCompleted = ({ className, TableData, filterFn, ...rest }) => {
  const classes = useStyles();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    selectedAllIds,
  } = commanTable(TableData, headCells, filterFn);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastNotify />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <TblContainer>
            <TblHead />

            {Object.keys(TableData).length !== 0 &&
              TableData.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <Row
                    key={row.order_uid}
                    row={row}
                  />
                ))}
              </TableBody>
            ) : (
              <caption>No Record Found</caption>
            )}
          </TblContainer>
        </Box>
      </PerfectScrollbar>
      {TableData.length != undefined && TableData.length > 0 ? (
        <TblPagination />
      ) : (
        ""
      )}
    </Card>
  );
};

InCompleted.propTypes = {
  className: PropTypes.string,
};

export default InCompleted;
