/*eslint eqeqeq: "off"*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchPltformNameList } from "../platform/_platformAccount_Action";
import './style.css'


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, setParentFilterFn, setPlatId, setDateRange, ...rest }) => {
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      console.log("===", items);
      return items;
    },
  });

  const dispatch = useDispatch();

  let platformNameList = useSelector(
    (state) => state.platfromAccount._allPlatfromNames
  );

  useEffect(() => {
    dispatch(fetchPltformNameList());
  }, []);

  const onPlateFormChange = (e) => {
    setPlatId(e.target.value);
  };

  useEffect(() => { }, [filterFn]);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) => {
            return x.customerName.toLowerCase().includes(target.value.toLowerCase())
          })

      }
    });
    setParentFilterFn(filterFn);
  };



  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={5}>
              <Grid item md={3} xs={12}>
                <TextField
                  id="outlined-select-currency-native"
                  fullWidth
                  select
                  label="plateForm"
                  onChange={onPlateFormChange}
                  variant="outlined"
                  name="category"
                  SelectProps={{
                    native: true,
                  }}
                  required
                >
                  <option key="" value="000e0">
                    Select plateForm
                  </option>
                  {platformNameList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.platformName}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  placeholder="Search customer by name"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2}>
                <DateRangePickerComponent
                  onChange={(e) => {
                    setDateRange(e.target.value)
                  }}
                  placeholder="Enter Date Range"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
