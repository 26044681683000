import React, { useEffect, useState } from "react";
import { Container, makeStyles, Box } from "@material-ui/core";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import CustomerList from "./CustomerList";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllDetails } from "./_customer_Action";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const allPendingCustomers = [];


const Customer = () => {
  const [custoIds,setCustoIds] = useState([]);
  const [pendingCustomes,setPendingCustomers]=useState([])
  const classes = useStyles();

  const [filterFn, setParentFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const dispatch = useDispatch();
  let allFetchData = useSelector((state) => state.customer._allDetails) || [];

  allPendingCustomers.push(...allFetchData)

  useEffect(() => {
    dispatch(fetchAllDetails());
    setPendingCustomers(allFetchData)
  }, [custoIds]);

  let viewComponent;
  viewComponent = <CustomerList TableData={allFetchData} filterFn={filterFn} setCustoIds={setCustoIds} />; //setParentPage={setParentPage} setParentLimit={setParentLimit} setReloadTable={setReloadTable} tableCount={allFetchData.length} />

  return (
    <Page className={classes.root} title="Customer">
      <Container maxWidth={false}>
        <Toolbar setParentFilterFn={setParentFilterFn} custoIds={custoIds} pendingCustomes={pendingCustomes}/>
        <Box mt={3}>{viewComponent}</Box>
      </Container>
    </Page>
  );
};

export {allPendingCustomers};
export default Customer;
