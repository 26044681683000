import {
  ADD_PRODUCT_DETAILS,
  UPDATE_PRODUCT_DETAILS,
  RESET_PRODUCT_STONE_DETAILS,
  FETCH_PRODUCT_DETAILS_LIST,
  EDIT_PRODUCT_DETAILS,
  CLEAR_PRODUCT_EDIT_DETAILS,
  GET_PRODUCT_SAVE_STONE_DETAILS,
  GET_PRODUCT_ATTRIBUTE_VALUES,
  DELETE_PRODUCT_DETAILS,
  FETCH_SINGLE_PRODUCT_DETAILS,
} from "./_product_Type";
import { config } from "../../Constants";
import { toast } from "react-toastify";
import { isNull } from "lodash";

const axios = require("axios");
const token = localStorage.getItem("jwtToken");
var REACT_APP_URL = config.url.API_URL;

export const createNewDetails = (values, navigate) => {
  let filename = isNull(values.productImage) ? "" : values.productImage.name;

  const formData = new FormData();

  formData.append("categoryid", parseInt(values.categoryId) || 0);
  formData.append("designno", values.designno);
  formData.append("sideStoneList", JSON.stringify(values.sideStoneInputList));
  formData.append("mainStoneList", JSON.stringify(values.mainStoneInputList));
  formData.append("description", values.description);
  if (filename) formData.append("productImage", values.productImage, filename);

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/add-product-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.status === 201) {
          let result = res.data.data;

          dispatch({
            type: ADD_PRODUCT_DETAILS,
            payload: result,
          });
          navigate("../products", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        if (err) {
          let message = err.response.data.message;
          toast.error(message);
        }
      });
  };
};

export const fetchAllAttributeValues = (attId) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL + "/api/get-all-product-attribute-value?attid=" + attId,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: GET_PRODUCT_ATTRIBUTE_VALUES,
            payload: result,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PRODUCT_ATTRIBUTE_VALUES,
          payload: [],
        });
      });
  };
};

export const getProductStoneDetails = (
  id,
  navigate,
  invoiceid,
  saleorderid
) => {
  return function(dispatch) {
    var OPTIONS = {
      url:
        REACT_APP_URL +
        "/api/get-product-stone-details?productId=" +
        id +
        "&invoiceId=" +
        invoiceid +
        "&saleOrderId=" +
        saleorderid,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: GET_PRODUCT_SAVE_STONE_DETAILS,
            payload: result,
          });
          if (navigate) navigate("./edit", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PRODUCT_SAVE_STONE_DETAILS,
          payload: [],
        });
      });
  };
};

export const fetchAllProducts = (categoryId) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-product-details?categoryId=" + categoryId, //?page=" + page + "&limit=" + limit,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_PRODUCT_DETAILS_LIST,
            listview: result,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PRODUCT_DETAILS_LIST,
          listview: [],
        });
      });
  };
};

export const findSingleProduct = (productsku) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/get-single-product?productsku=" + productsku,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json, text/plain, */*",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          dispatch({
            type: FETCH_SINGLE_PRODUCT_DETAILS,
            data: result,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SINGLE_PRODUCT_DETAILS,
          data: [],
        });
      });
  };
};

export const clearEditDetails = () => {
  return {
    type: CLEAR_PRODUCT_EDIT_DETAILS,
  };
};

export const resetProductStoneDetails = () => {
  return {
    type: RESET_PRODUCT_STONE_DETAILS,
  };
};

export const editSingleDetails = (id, singleData) => {
  return {
    type: EDIT_PRODUCT_DETAILS,
    payload: singleData,
    id: id,
  };
};

export const updateDetails = (values, r_id, navigate) => {
  let filename = isNull(values.productImage) ? "" : values.productImage.name;

  const formData = new FormData();

  formData.append("productid", r_id);
  formData.append("categoryid", parseInt(values.categoryId) || 0);
  formData.append("designno", values.designno);
  formData.append("sideStoneList", JSON.stringify(values.sideStoneInputList));
  formData.append("mainStoneList", JSON.stringify(values.mainStoneInputList));
  formData.append("removeStoneList", JSON.stringify(values.removeStoneList));
  formData.append("description", values.description);
  if (filename) formData.append("productImage", values.productImage, filename);

  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-product-details",
      method: "POST",
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data || [];
          dispatch({
            type: UPDATE_PRODUCT_DETAILS,
            payload: result[0],
          });
          navigate("../products", { replace: false });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};

export const deleteSingleDetails = (id) => {
  return function(dispatch) {
    var OPTIONS = {
      url: REACT_APP_URL + "/api/update-product-details",
      method: "POST",
      data: { productid: id, isdelete: true },
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(OPTIONS)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_PRODUCT_DETAILS,
            payload: id,
          });
        } else {
          console.log("Soming Wrong.....!");
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        toast.error(message);
      });
  };
};
