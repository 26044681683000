import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllDetails } from './_dashboard_action'

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56,
  },
}));

const TotalProfit = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const [show,setShow] = useState("(Current Year)")
  let allFetchData = useSelector((state) => state.dashboard._allDetails) || [];

  useEffect(() => {
    dispatch(fetchAllDetails(dateRange));
    if(dateRange == null)
    setShow("(Current Year)")
    else if(Date.parse(dateRange[0] || dateRange[1]))
    setShow("")
  }, [dateRange])

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL PROFIT{show}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              ₹{allFetchData.totalProfit}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              ₹
            </Avatar>
          </Grid>
          <Grid item container>
            <DateRangePickerComponent
              onChange={(e) => {
                setDateRange(e.target.value)
              }}
              placeholder="Enter Date Range To Pick Total profit"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string,
};

export default TotalProfit;
