/*eslint eqeqeq: "off"*/
import { FETCH_ALL_USER, EDIT_USER_DETAILS, UPDATE_USER_DETAILS, DELETE_USER_DETAILS, ADD_DETAIL_TO_TABLE } from '../action/userAccountType';
const initialState = {
    _edituserDetails: '',
    _action: 'View',
    _id: '',
    _msg: '',
    _allUsers: [],
    _singleDetails: ''
}

const userAccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case EDIT_USER_DETAILS: return {
            ...state,
            _edituserDetails: action.payload,
            _id: action.id,
            _action: 'Edit'
        }
        case UPDATE_USER_DETAILS: return {
            ...state,
            _edituserDetails: '',
            _id: action.id,
            _action: 'View'
        }
        case FETCH_ALL_USER: return {
            ...state,
            _edituserDetails: '',
            _allUsers: action.usersdata,
            _action: 'View'
        }
        case DELETE_USER_DETAILS: return {
            ...state,
            _allUsers: state._allUsers.filter(
                (record) => record.id != action.payload
            ),
        }
        case ADD_DETAIL_TO_TABLE: return {
            ...state,
            // _edituserDetails: '',
            _singleDetails: action.payload,
        }

        // case CHANGE_ACTION: return {
        //     initialState
        // }

        default: return state;
    }
}

export default userAccountReducer;