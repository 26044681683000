import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, makeStyles, IconButton } from '@material-ui/core';
import { Edit as EditIcon} from 'react-feather';
import { getAttributeSaveValue } from '../purchase_Invoice/_purchase_Action'

import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));


const StockInList = ({ className, TableData, setOpenPopup, isPopup, fillAttributeValue, ...rest }) => {

  const classes = useStyles();
  const dispatch = useDispatch();


  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onSelectButtonClick = (stockId, qty, weight) => {
    dispatch(getAttributeSaveValue(0, '', stockId))
    fillAttributeValue(stockId, qty, weight)
    setOpenPopup(false)
  };

  return (

    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>
                  Total Weight
                </TableCell>
                <TableCell>
                  Total Qty
                </TableCell>
                <TableCell>
                  Product Details
                </TableCell>
                <TableCell>
                  Select Product
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TableData.map((row) => (
                <TableRow
                  hover
                  key={row.stockProductid}
                >
                  <TableCell>
                    {row.productWeight}
                  </TableCell>
                  <TableCell>
                    {row.productTotalQty}
                  </TableCell>
                  <TableCell>
                    {row.productDetails}
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onSelectButtonClick(row.stockProductid, row.productTotalQty, row.productWeight)} >
                      <EditIcon />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={-1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card >
  )

}


StockInList.propTypes = {
  className: PropTypes.string,
};

export default StockInList;

