/*eslint eqeqeq: "off"*/
import {
  ADD_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_LIST,
  UPDATE_CUSTOMER_DETAILS,
  EDIT_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
} from "./_customer_Type";

const initialState = {
  _editedDetails: "",
  _action: "View",
  _id: "",
  _msg: "",
  _allDetails: [],
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_DETAILS:
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails],
      };

    case UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: state._allDetails.map((obj) =>
          obj.id == action.payload.id ? action.payload : obj
        ),
      };
    case EDIT_CUSTOMER_DETAILS:
      return {
        ...state,
        _editedDetails: action.payload,
        _id: action.id,
      };
    case FETCH_CUSTOMER_DETAILS_LIST:
      return {
        ...state,
        _editedDetails: "",
        _allDetails: action.listview,
        _msg: "",
      };
    case DELETE_CUSTOMER_DETAILS:
      return {
        ...state,
        _allDetails: state._allDetails.filter(
          (record) => record.id != action.payload
        ),
        _id: action.payload,
      };

    default:
      return state;
  }
};

export default customerReducer;
