import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import { updateUserDetails, createNewRollName } from "../../redux/index";
import { useDispatch } from "react-redux";
//import PerfectScrollbar from 'react-perfect-scrollbar';
//import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';

// Main Function
const ProfileDetails = ({ isPopup, className, ...rest }) => {
  let _editRollName = "";

  //   let _userRollNameList = useSelector(state => state.userAccount._edituserDetails) || []

  // const [limit, setLimit] = useState(10);
  // const [page, setPage] = useState(0);

  // const handleLimitChange = (event) => {
  //     setLimit(event.target.value);
  // };

  // const handlePageChange = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const editDetails = async (id, data) => {
  // setPlatformNameError('')
  // //await dispatch(editPlatformName(id, data));
  // setPlatformId(id)
  // setPlatformName(data.platformName)
  // setButtonAction('Update')
  //  }

  // const deleteDetails = async (id) => {
  // dispatch(deletePlatformName(id));
  // }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateUser = (values, id) => {
    dispatch(updateUserDetails(values, id, navigate));
  };

  const createUser = (values, navigate, isPopup) => {
    dispatch(createNewRollName(values, navigate, isPopup));
  };

  var buttonText = "Create",
    initialdata = { rolltype: "", description: "" };

  if (_editRollName) {
    initialdata = { rolltype: "", description: "" };
    buttonText = "Update";
  } else {
    initialdata = { rolltype: "", description: "" };
    buttonText = "Create";
  }
  return (
    <Formik
      initialValues={initialdata}
      validationSchema={Yup.object().shape({
        rolltype: Yup.string().required("Roll is required!"),
      })}
      onSubmit={(values, { resetForm }) => {
        if (_editRollName && buttonText === "Update") {
          updateUser(values, _editRollName.id, navigate);
        } else {
          createUser(values, navigate, isPopup);
        }
        resetForm({ values: "" });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Card>
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom variant="h6">
                    Roll Type :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.rolltype && errors.rolltype)}
                    fullWidth
                    helperText={touched.rolltype && errors.rolltype}
                    label="Roll type"
                    name="rolltype"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.rolltype}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                  <Typography color="textSecondary" gutterBottom variant="h6">
                    Description :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {buttonText}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
